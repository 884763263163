<span class="p-input-icon-right container-input-search searchDimension">
    <form [formGroup]="searchForm">
        <input #search formControlName="search" (focus)="menu.show($event)" (focusout)="prueba(menu)" (dblclick)="menu.show($event)"
        (keyup.enter)="goToProduct(0)"
        type="text" pInputText style="width: 100%;"/>
    </form>
    <i class="pi pi-search" *ngIf="search.value.length === 0" style="color: var(--gris-primeng);"></i>
    <i class="pi pi-times-circle cursor-pointer" *ngIf="search.value.length > 0" (click)="search.value = ''"></i>
</span>

<p-menu  #menu [popup]="true" [model]="menus"></p-menu>
