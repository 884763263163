import { Type } from '@angular/compiler';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';

import { CategoriesService } from 'src/app/services/shop/categories.service';

@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.component.html',
  styleUrls: ['./footer-web.component.scss']
})
export class FooterWebComponent {


  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }

  isMobile = false;

  categories1: any;
  categories2: any;

  constructor(private router: Router, private categoryService: CategoriesService,
    private generalConfigurationService: GeneralConfigurationService,){
    this.isMobile = this.generalConfigurationService.isMobile;
  }

  async ngOnInit() {
    this.categoryService.getCatLevel(0).then((data: any) => {
      const myStorage = {
        name: "Almacén",
        idcategory: 0,
        src:this.isMobile ? "assets/img/shop/categories/Almacen_phone.png" : "assets/img/shop/categories/Almacen.png",
        selected: true
      }
      let categories = data.map((category: any) => {
        return {
          name: category.name,
          idcategory: category.idcategory,
          src:this.isMobile ? "assets/img/shop/categories/"+ category.idcategory+"_phone.png" : "assets/img/shop/categories/"+category.idcategory+".png",
          selected: false
        };
      });
      this.categories1 = categories.splice(0,7);
      this.categories2 = categories;
    })
  }
  
  go(cat: any){
    if(cat.idcategory === 0){
      this.router.navigate(['/shop'])  
    }else{
      this.router.navigate(['/shop'], {queryParams: {idCategory : cat.idcategory}});
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

}
