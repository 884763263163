import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommunicationMsg, ShippingObject } from 'src/app/interfaces/shipping';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  retreatCart:any;
  deliveryParcelCart:any;
  deliveryCommodityCart:any;
  deliveryLiquidCart:any;
  deliveryPackagesLiquidCart:any;
  dateCart:any;
  products:any;
  retreatPackages:any;
  deliveryPackagesParcelCart:any;
  deliveryPackagesCommodityCart:any;
  userAddress:any;
  userCityCodeDane:any;
  observaciones:any;
  locationId:any;
  couponDelivery: any;
  couponDiscount: any;

  productNoLogin: any;
  type = 1;



  private msg: BehaviorSubject<CommunicationMsg> = new BehaviorSubject<CommunicationMsg>({type: "", data: undefined});

  constructor() { }

  listenMsg(){
    return this.msg.asObservable()
  }

  sendMsg(type: string, data: any){
    const msg = {type, data};
    this.msg.next(msg);
  }

  async infoGeneral(infoUser: any, date: Date, userAddress: any, userCityCodeDane:any, ): Promise<Partial<ShippingObject>> {
    const dir = userAddress.address.indexOf(",");
    const fecha = date.toUTCString();
    // tslint:disable-next-line: variable-name
    const direccion_destinatario = userAddress.address.substring(0, dir).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    // tslint:disable-next-line: variable-name
    const ciudad_destinatario = userCityCodeDane;
    // tslint:disable-next-line: variable-name
    const telefono_destinatario = infoUser[0].celphone;
    let nombre = infoUser[0].auth_user.first_name + " " + infoUser[0].auth_user.last_name;
    nombre = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    const enviosObj = {fecha, direccion_destinatario, ciudad_destinatario, telefono_destinatario, estado: "IMPRESO", nombre_destinatario: nombre};
    return enviosObj;
  }
}
