import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PhoneValidationComponent } from './phone-validation/phone-validation.component';
import { DialogModule } from 'primeng/dialog';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { NgOtpInputModule } from 'ng-otp-input';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { FooterWebComponent } from './footer-web/footer-web.component';
import { RemixIconModule} from 'angular-remix-icon';
import { CarzComponent } from './carz/carz.component';
import { RecommendedsComponent } from './recommendeds/recommendeds.component';
import { ProductComponent } from './product/product.component';
import {DataViewModule} from 'primeng/dataview';
import {CarouselModule} from 'primeng/carousel';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';
import { CreateDirectionComponent } from './address/create-direction/create-direction.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SidebarModule} from 'primeng/sidebar';
import { BannerCookiesComponent } from './banner-cookies/banner-cookies.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProductViewComponent } from './product-view/product-view.component';
import { GalleriaModule } from 'primeng/galleria';
import { SkeletontextModule } from '../skeleton-text/skeletontext.module';
import {SkeletonModule} from 'primeng/skeleton';
import { SupportCarzComponent } from './support-carz/support-carz.component';
import { ChatCarzComponent } from './support-carz/chat-carz/chat-carz.component';
import { CartProductComponent } from './cart/cart-product/cart-product.component';
import { ModalCheckoutComponent } from './header/modal-checkout/modal-checkout.component';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { SearchBarComponent } from './header/search-bar/search-bar.component';
import {ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { AddressComponent } from './address/address.component';
import { CartComponent } from './cart/cart.component';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { SistecredInfoComponent } from './product-view/sistecred-info/sistecred-info.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PaymentInfoComponent } from './product-view/payment-info/payment-info.component';
import { InputTextModule } from 'primeng/inputtext';
import { ContraentregaComponent } from './contraentrega/contraentrega.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { CalendarModule } from 'primeng/calendar';
import { CalendarProductCardComponent } from './schedule/calendar-product-card/calendar-product-card.component';
import { MessageService } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [
    HeaderComponent,
    PdfViewerComponent,
    PhoneValidationComponent,
    FooterWebComponent,
    CarzComponent,
    RecommendedsComponent,
    ProductComponent,
    CreateDirectionComponent,
    BannerCookiesComponent,
    ProductViewComponent,
    SupportCarzComponent,
    CartProductComponent,
    ModalCheckoutComponent,
    ChatCarzComponent,
    CartProductComponent,
    SearchBarComponent,
    AddressComponent,
    CartComponent,
    ProductListItemComponent,
    SistecredInfoComponent,
    PaymentInfoComponent,
    ContraentregaComponent,
    ScheduleComponent,
    CalendarProductCardComponent,
  ],
  providers : [
    DialogService,
    MessageService
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    DialogModule,
    NgxIntlTelInputModule,
    NgOtpInputModule,
    ButtonModule,
    FormsModule,
    ToastModule,
    RemixIconModule,
    DataViewModule,
    CarouselModule,
    RadioButtonModule,
    DropdownModule,
    InputTextareaModule,
    OverlayPanelModule,
    SidebarModule,
    InputSwitchModule,
    GalleriaModule,
    SkeletontextModule,
    SkeletonModule,
    BadgeModule,
    TooltipModule,
    MenuModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    InputTextModule,
    CalendarModule,
    InputNumberModule
  ],
  exports: [
    HeaderComponent,
    PdfViewerComponent,
    PhoneValidationComponent,
    FooterWebComponent,
    CarzComponent,
    RecommendedsComponent,
    ProductComponent,
    CreateDirectionComponent,
    BannerCookiesComponent,
    ProductViewComponent,
    SupportCarzComponent,
    ModalCheckoutComponent,
    SearchBarComponent,
    ProductListItemComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule { }
