import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Product } from "src/app/interfaces/shop";

@Component({
  selector: "app-cart-product",
  templateUrl: "./cart-product.component.html",
  styleUrls: ["./cart-product.component.scss"],
})
export class CartProductComponent implements OnInit {

  @Input() product!: Product;
  @Input() sellType!: number;
  @Input() line!: {
    product: number;
    units: number;
    date?: Date;
    variant?: number;
  };
  @Input() loadingDeliveryInfo = false;
  @Output() modalShowed = new EventEmitter<boolean>();

  keyboardShowed = false;
  typeChanged = false;
  originalPrice = 0;
  discoundPrice = 0;
  currentVH = 18;
  initialPlatformHeight = 0;
  initialVh = 0;

  @Output() finalPrice = new EventEmitter<any[]>();
  @Output() available = new EventEmitter<boolean>();
  @Output() remove = new EventEmitter<{ product: number, type: number, price: number, install_price: number, is_liquid: boolean }>();
  @Output() updateValue = new EventEmitter<{ oldPrice: number, newPrice: number, sellType: number }>();

  adImgs = ["../../../assets/img/shop/TagProm2.svg",
            "../../../assets/img/shop/TagPercent2.svg",
            "../../../assets/img/shop/TagOff2.svg"];
  
  initialScreenHeight = 0;
  keyboardHeight = 0;
  initialContentAllieHeigth = 15;
  dinamicContentAllieHeigth = 15;

  selectedVariant: any = undefined

  nontype = "";

  constructor() { }

  ngOnInit() {
    this.calculatePrice();
    this.typeChanged = this.checkProductType();

    const isAvailable = this.typeChanged && this.product.is_available && this.isEnoughStock;
    this.available.emit(isAvailable);
    const id_variant = this.line.variant;
    if(id_variant){
      const variant = this.product?.variants?.filter((element: any) => {
        if(element.id === id_variant){
          return element
        }
      })
      if(variant){
        this.selectedVariant = variant[0].cloth_size;
      }
    }
  }


  removeProdct() {
    const installTax = this.product.install_tax === null ? 0 : this.product.install_tax;
    this.remove.emit({
      // @ts-ignore
      product: this.product.idproduct, type: this.sellType,
      price: this.discoundPrice ? this.discoundPrice * this.line.units : this.originalPrice * this.line.units,
      // @ts-ignore
      install_price: (this.product.install_prize * (1 + (installTax / 100))),
      // @ts-ignore
      is_liquid: this.product.is_liquid
    });
  }

  private calculatePrice(): void {
    const tax = this.product.tax === null ? 0 : this.product.tax;
    const installTax = this.product.install_tax === null ? 0 : this.product.install_tax;
    const discount = this.product.productAd.length === 0 ? 0 : this.product.productAd[0].discount;
    const offer = this.product.productAd.length === 0 ? 0 : this.product.productAd[0].offer;
    // @ts-ignore
    this.originalPrice = this.product.prize * (1 + tax / 100);
    if (this.product.productAd[0]?.type === 1) {
      this.discoundPrice = this.originalPrice - (this.originalPrice * (discount / 100));
    } else if (this.product.productAd[0]?.type === 2) {
    // @ts-ignore
      this.discoundPrice = this.originalPrice - offer;
    }
    if (this.sellType === 2) {
      this.originalPrice = this.originalPrice;
    }
    const aux_1 = this.discoundPrice ? this.discoundPrice * this.line.units : this.originalPrice * this.line.units;

    this.finalPrice.emit([aux_1, this.product.idproduct, offer, this.originalPrice * (discount / 100), this.originalPrice * this.line.units, this.line.variant]);
  }

  private checkProductType(): boolean {
    if(this.sellType == 0 && this.product.get_in_workship === false){
      this.nontype = "No disponible para retirar"
      return false;
    }else if (this.sellType == 1 && this.product.delivery === false){
      this.nontype = "No disponible para envíos"
      return false;
    }else if (this.sellType == 2 && this.product.need_date === false){
      this.nontype = "No disponible para citas"
      return false;
    }
    this.nontype = "";
    return true;
  }

  get hasStock(){
    if(this.product.variants?.length && this.product.variants.length > 0){
      let variant : any = undefined
      if(this.product.variants.length > 1){
        variant = this.product.variants.find((element) => {
          return element.id === this.line.variant
        })
      }else{
        variant = this.product.variants[0];
      }
      return variant && (this.line.units < variant.stock);
    }else{
      return this.line.units < this.product.stock
    }
  }

  get isEnoughStock(){
    if(this.product.variants?.length && this.product.variants.length > 0){
      let variant : any = undefined
      if(this.product.variants.length > 1){
        variant = this.product.variants.find((element) => {
          return element.id === this.line.variant
        })
      }else{
        variant = this.product.variants[0];
      }
      return variant && (this.line.units <= variant.stock);
    }else{
      return this.line.units <= this.product.stock
    }
  }

  changeUnits(mode: string = 'add' || 'subs') {
    let aux = 0;
    // @ts-ignore
    if(this.product.variants?.length && this.product.variants.length > 0){
      let variant : any = undefined
      if(this.product.variants.length > 1){
        variant = this.product.variants.find((element) => {
          return element.id === this.line.variant
        })
      }else{
        variant = this.product.variants[0];
      }
      if ( mode === 'add' && variant && (this.line.units < variant.stock)) {
        aux = this.line.units;
        this.line.units++;
      } else if ( mode === 'subs' && this.line.units > 1) {
        aux = this.line.units;
        this.line.units--;
      }
    }else{
      if ( mode === 'add' && this.line.units < this.product.stock) {
        aux = this.line.units;
        this.line.units++;
      } else if ( mode === 'subs' && this.line.units > 1) {
        aux = this.line.units;
        this.line.units--;
      }
    }
    const oldPrice = this.discoundPrice ? this.discoundPrice * aux : this.originalPrice * aux;
    // this.line.units = this.line;
    const newPrice = this.discoundPrice ? this.discoundPrice * this.line.units : this.originalPrice * this.line.units;
    this.updateValue.emit({ oldPrice, newPrice, sellType: this.sellType});
  }

}
