import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GarageService {

  baseurl = environment.server_url;
  token;
  httpHeaders;
  garageLoaded = new Subject<boolean>();

  searchString = "";
  bingSearchURL = "https://api.bing.microsoft.com/v7.0/images/search";
  $filterVehiclePrincipal = new BehaviorSubject<any>(null);
  $showSkeleton = new BehaviorSubject<boolean>(true);


  constructor(private http: HttpClient) {
    this.token = localStorage.getItem("token");
    this.httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });
  }

  private setToken(){
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }
  }

  getState(): Promise<any> {
    this.setToken();
    if(this.token === null){
      return new Promise((resolve) => {resolve(false)})
    }else {      
      return this.http.get(this.baseurl + "usersActions/carUser/", { headers: this.httpHeaders }).toPromise();
    }
  }

  getStateV2(): Observable<any> {
    this.setToken();
    if(this.token === null){
      return of(false)
    }else {      
      return this.http.get(this.baseurl + "usersActions/carUser/", { headers: this.httpHeaders });
    }
  }


  changePrincipalCar(id: number): Promise<any> {
    const body = {id};
    return this.http.post(this.baseurl + "usersActions/changePrincipalCar/", body, { headers: this.httpHeaders }).toPromise();
  }

  getMarks(id: number): Observable<any>{
    return this.http.get(this.baseurl + "core/car_make/?type_id=" + id , { headers: this.httpHeaders });
  }

  postModel(id: any): Observable<any>{
    return this.http.post(this.baseurl + "core/CarModel/", { make_id : id }, { headers: this.httpHeaders });
  }

  postSerieBike(id: any): Observable<any>{
    return this.http.post(this.baseurl + "core/BikeSeries/", { model_id : id }, { headers: this.httpHeaders });
  }

  postTrim(id: any): Observable<any>{
    return this.http.post(this.baseurl + "core/CarTrim/", { ids: id, type: 20 }, { headers: this.httpHeaders });
  }

  async getRelatedImages(transparent: boolean, color: string) {
    const httpHeaders2 = new HttpHeaders({ 'Ocp-Apim-Subscription-Key' : "1083c56b71f64ef9af8d97878893d7f0"});
    try {
      let params = new HttpParams;
      params = params.set("q",`${this.searchString}`);
      if (transparent) {
        params = params.set("imageType","Transparent");
      }
      if (color.length > 0) {
        params = params.set("color",`${color}`);
      }
      const data = await this.http.get(this.bingSearchURL, {headers: httpHeaders2, params}).toPromise();
      return data;
    } catch (error) {
      console.log(error);
      
      // this.toastService.toastErrorConnection("Error al buscar imagenes");
      return null;
    }
  }


  postId(make: any, model: any, trim: any, type: any, generation: any, equipment: any){
    return this.http.get(this.baseurl + "core/Cars/?make=" + make + "&model=" + model + "&trim=" + trim + "&type=" +
    type + "&generation=" + (generation ? generation : "") + "&equipment=" + (equipment ? equipment : "") + "", { headers: this.httpHeaders });
  }

  postCreateCar(car:any): Promise<any> {
    return this.http.post(this.baseurl + "usersActions/create_car_user/", car, { headers: this.httpHeaders }).toPromise();
  }

  patchCar(car: any, id: string): Promise<any>{
    return this.http.patch(this.baseurl + "usersActions/create_car_user/" + id + "/", car,{ headers: this.httpHeaders }).toPromise()
  }

  getUserId(): Promise<any>{
    return this.http.get(this.baseurl + "usersActions/userid/", { headers: this.httpHeaders }).toPromise();
  }

  getValueFilterVehiclePrincipal() {
    const f:any = localStorage.getItem("filterPrincipalVehicle");
    const filter = JSON.parse(f);
    return filter;
  }

  deleteCarOwner( plate: string ): Observable<any> {
    this.setToken();
    return this.http.delete(this.baseurl + "usersActions/car_specific/" + plate + "/", { headers: this.httpHeaders });
  }

}