import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddiService {

  token = ""


  httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });

  url = environment.shared_url;

  constructor(private http: HttpClient) { }

  checkPrices(price): Observable<any>{
    const addi_url = "https://channels-public-api.addi.com/allies/zaito-ecommerce/config?requestedAmount=" + price;
    const headers = new HttpHeaders({ "Content-Type": "application/json"})
    return this.http.get(addi_url, { headers: headers });
  }

  getLink(sell: string): Observable<any>{
    const body = {
      sell
    }
    const headers = new HttpHeaders({ "Content-Type": "application/json"})
    return this.http.post(this.url + "GetRedirectAddiLink?code=dg7VpeVDOvhyr1I7QI9arhBRHzwl63YV-CMVj-4AmAReAzFuIBmmGw%3D%3D", body, { headers: headers });
  }

  /*getToken(){
    let url_addi_auth = this.url + "/v1/online-applications";
    const headers = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik9FRXhPVVE1UWprMU1EUXpNMFl4UVRVek56UkdRVU14TURZeU5FRTRPRGMxTTBFd09UWkZRdyJ9.eyJpc3MiOiJodHRwczovL2F1dGguYWRkaS1zdGFnaW5nLmNvbS8iLCJzdWIiOiI0Qjg2SW40RlpHMVRaNkpIV2tJOHp6T2FpdnJPQzAwSUBjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9hcGkuc3RhZ2luZy5hZGRpLmNvbSIsImlhdCI6MTcyMzU1OTMxMiwiZXhwIjoxNzIzNjQ1NzEyLCJzY29wZSI6ImNsaWVudC1tYW5hZ2VtZW50OmNyZWF0ZS1vbmxpbmUtYXBwbGljYXRpb24iLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMiLCJhenAiOiI0Qjg2SW40RlpHMVRaNkpIV2tJOHp6T2FpdnJPQzAwSSJ9.d09vs1UYmpOtR-mqjrT73QEnnJDxn3MzR3pzkQ2VCXaRL05XIRPGEx36RyUOj73iI1zu71HyIvxZLi_j1DuhawjQ7kI-DDxYCbxSBEL2W2Gbixlr1KLB_2lBfncbxSb7zrc1rJfanbxQyeWKqOUhO0FdRv_kRZKLX8hEOmSRAY_Ef2o12Ug_Kx09G8pklxSeQoltniez7VqSCnFr82NC3jYOZjefNXoA5OhRePHq9fyd_c-n51tVquaCyhvSUAuTSpqm9MmeLOYI_53sCYDoB-R2mWJ5v4r7PvWktlo9mddYoJLhIqm_reLDOPz6rJge3CDadvcCn5bRvHiK7RQ1Tg"})
    const body = {
      "orderId": "8ujf387a5-ecc6-4324-8b78-ac20c952c742",
      "totalAmount": "255000.0",
      "shippingAmount": "50000.0",
      "totalTaxesAmount": "100000.0",
      "currency": "COP",
      "items": [
          {
              "sku": "PD-122354",
              "name": "product name",
              "quantity": "5",
              "unitPrice": 9950,
              "tax": 9950,
              "pictureUrl": "https://picture.example.com/?img=test",
              "category": "technology",
              "brand": "adidas"
          }
      ],
      "client": {
          "idType": "CC",
          "idNumber": "354125896",
          "firstName": "marcela griselda",
          "lastName": "Lopez Lopez",
          "email": "addi-client@online.com",
          "cellphone": "3211236584",
          "cellphoneCountryCode": "+57",
          "address": {
              "lineOne": "cr 48 156 25 25",
              "city": "Bogotá D.C",
              "country": "CO"
          }
      },
      "shippingAddress": {
          "lineOne": "cr 48 156 25 25",
          "city": "Bogotá D.C",
          "country": "CO"
      },
      "billingAddress": {
          "lineOne": "cr 48 156 25 25",
          "city": "Bogotá D.C",
          "country": "CO"
      },
      "pickUpAddress": {
          "lineOne": "cr 48 156 25 25",
          "city": "Bogotá D.C",
          "country": "CO"
      },
      "allyUrlRedirection": {
          "logoUrl": "https://picture.example.com/?img=test",
          "callbackUrl": "https://zaito.co/",
          "redirectionUrl": "https://zaito.co/"
      },
      "geoLocation": {
          "latitude": "4.624335",
          "longitude": "-74.063644"
      }
  }
    return this.http.post(url_addi_auth, body, { headers: headers })
  } */

}
