<div #scrollMe [scrollTop]="messages.length === 0 ? 0 : scrollMe.scrollHeight" class="contentModalCarz chat"
    style="overflow-y: auto; padding:0;">
    <div class="grid grid-nogutter">
        <div class="col-12" *ngFor="let message of messages, let i=index">
            <div *ngIf="message.text || message.img">
                <div *ngIf="firstTimeDay[i]?.firstDay" class="grid grid-nogutter"
                    style="margin-top: 12px; margin-bottom: 10px;">
                    <div class="col container-line">
                        <div class="line-date"></div>
                    </div>
                    <div class="col-3 dateSeparator">
                        {{ getLastTimeAsDate(message.date) | date:'dd/MM/yyyy' }}
                    </div>
                    <div class="col container-line">
                        <div class="line-date"></div>
                    </div>
                </div>
                <div *ngIf="!message.img else img" style="padding: 0.5rem 1rem">
                    <div class="msg1 msg" *ngIf="message.is_user; else allymsg">
                        <div class="text">
                            {{message.text}}
                        </div>
                        <div class="hour" *ngIf="message.is_user">
                            {{secondsToHms(message.date)}}
                        </div>
                    </div>
                    <ng-template #allymsg>
                        <div class="msg2 msg">
                            <div class="text" *ngIf="newMessages[i]?.hasLink; else normalText">
                                {{newMessages[i]?.text[0]}}
                                <a class="textLink" Target="_blank" [pTooltip]='newMessages[i]?.text[1]'
                                    tooltipPosition="left"
                                    [href]="newMessages[i]?.text[1]">{{newMessages[i]?.text[1]}}</a>
                                {{newMessages[i]?.text[2]}}
                            </div>
                            <ng-template #normalText>
                                <div class="text">
                                    {{message.text}}
                                </div>
                            </ng-template>
                            <div class="hour">
                                {{secondsToHms(message.date)}}
                            </div>
                        </div>
                    </ng-template>
                </div>
                <ng-template #img style="padding: 0.5rem 1rem">
                    <div class="msg img" [ngClass]="message.is_user ? 'imgIsUser' : '' " *ngIf="message.img"
                        (click)="emitEventToSupport(message.img)">
                        <img src="{{message.img}}" alt="Imagen mensaje"
                            style="max-height: 20vh; width: 100%; border-radius: 8px;cursor:pointer;"
                            onerror="this.src='assets/img/shared/imagenotavailable.svg'">
                    </div>
                </ng-template>

            </div>
            <div *ngIf="!message.text && !message.img" style="height: 130px;margin: 5px 0">
                <div class="message-product grid" *ngIf="!message.is_user" style="height: 100%;">
                    <div class="text z-shadow-principal col-10 col-offset-1"
                        style="align-items: center; justify-content: center; padding: 25px; height: 100%;"
                        *ngIf="!message.name">
                    </div>
                    <div class="text z-shadow-principal col-10 col-offset-1" *ngIf="message.name"
                        style="height: 100%; display: flex;padding:15px">
                        <div
                            style="width: 40%; height: 100%; display: flex; justify-content: center; align-items: flex-start;">
                            <img src="{{message.imageProduct}}" alt="{{message.name}}"
                                style="width: 8em; border-radius: 8px; height: 90%;">
                        </div>
                        <div style="width: 60%; height: 100%;display: flex;flex-direction: column;justify-content: space-between;padding-bottom: 20px;">
                            <div class="ellipsis" pTooltip="{{message.name}}" tooltipPosition = "left">{{message.name}}</div>
                            <div style="height: 60%;">
                                <p-button pRipple label="Ver" (click)="seeProduct(message.product)" *ngIf = "message.product"
                                    [style]="{'margin': '10px 5px','height': '100%','display': 'flex','width': '100%','flex-direction': 'column'}">
                                </p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
