import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, elementAt, lastValueFrom, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category, CatNode } from '../../interfaces/category';
import { GarageService } from '../garage/garage.service';
import { GeneralConfigurationService } from '../shared/configuration/general-configuration.service';
@Injectable({
  providedIn: 'root'
})

export class CategoriesService {
  baseurl = environment.server_url;
  myStorage = {
    name: "Almacén",
    idcategory: 0,
    src:"assets/img/shop/categories/Almacen.png",
    selected: true
  };
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});
  public categories = new BehaviorSubject<any[]>([]);
  private selectedCategory = new BehaviorSubject<any>(undefined);
  private selectedFatherCategory = new BehaviorSubject<any>(undefined);
  $loading = new BehaviorSubject<boolean>(true);
  allCategories: any[] = [];

  isMobile = false;


  constructor(private http: HttpClient, private garageService: GarageService, private generalConfigurationService: GeneralConfigurationService) {
    this.isMobile = this.generalConfigurationService.isMobile;
    this.myStorage.src = this.isMobile ? "assets/img/shop/categories/Almacen_phone.png" : "assets/img/shop/categories/Almacen.png"
    this.garageService.$filterVehiclePrincipal.subscribe(data => {
      if (data != null) {
        this.$loading.next(true);
        this.loadCategories();
      }
    });
  }

  loadCategories() {
    this.getCategories();
    this.getAllCat();
  }

  private setToken(){
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }
  }

  async getCategories(){
    this.setToken();
    const aux = await this.getCatLevel(0);
    // @ts-ignore
    let categoriesWithImage = aux.map(category => {
      return {
        name: category.name,
        idcategory: category.idcategory,
        src:this.isMobile ? "assets/img/shop/categories/"+ category.idcategory+"_phone.png" : "assets/img/shop/categories/"+category.idcategory+".png",
        selected: false
      };
    });
    categoriesWithImage.unshift(this.myStorage);
    this.categories.next(categoriesWithImage);
    this.selectedCategory.next(this.myStorage);
    this.selectedFatherCategory.next(this.myStorage);
  }

  setAsPrincipal(idcategory: number){
    if(idcategory !== 0){
      const element = this.searchInChildren(this.allCategories, idcategory);
      const father = element[element.length - 1];
      const selected = element[0];
      this.categories.value.forEach((cat: any) =>{
        if(cat.idcategory == father.data.idcategory){
          this.setPrincipal(selected, cat); 
        }
      })
    }else{
      this.setPrincipal(this.myStorage, this.myStorage);
    }
  }

  private searchInChildren (array: any[], targetData: number): any {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (obj.data.idcategory == targetData) {
        return [obj];
      }
      if (obj.children && obj.children.length) {
        const result = this.searchInChildren(obj.children, targetData);
        if (result) {
          result.push(obj);
          return result;
        }
      }
    }
    return null;
  }

  returnCategories(){
    if(this.categories.value.length > 0){
      return this.categories.asObservable();
    }else{
      this.loadCategories();
      return this.categories.asObservable();
    }
  }

  get principalCategory(){
    return this.selectedCategory.asObservable();
  }

  async PrincipalCategoryValue(){
    return await lastValueFrom(this.selectedCategory);
  }

  get principalCategoryFather(){
    return this.selectedFatherCategory.asObservable();
  }

  async principalCategoryFatherValue(){
    return await lastValueFrom(this.selectedFatherCategory);
  }

  setPrincipal(cat: any, father: any){
    this.selectedCategory.next(cat);
    this.selectedFatherCategory.next(father);
    /* let cont = 0;
    this.categories.value.forEach((element: any) =>{
      if(element === cat || element.idcategory === cat){
        let aux_array = [...this.categories.value];
        aux_array[0] = this.categories.value[cont];
        aux_array[cont] = this.categories.value[0];
        this.categories.next(aux_array);
      }
      cont++;
    }) */
  }

  async getCatLevel(level : number) {
    const filter = await this.garageService.getValueFilterVehiclePrincipal();
    if (filter && level == 0) {
      this.setToken();
      return this.http.get<Category []>(this.baseurl + "shop/user_categories/", { headers: this.httpHeaders }).toPromise();
    } else {
      const $response = this.http.get<Category []>('assets/categorys.json');
      let categories: Category [] = await lastValueFrom($response)
      categories = categories.filter((data) => data.level === level);
      return categories;
    }
  }

  async getAllCat(){
    const level0 = await this.getCatLevel(0);
    const level1 = await this.getCatLevel(1);
    const level2 = await this.getCatLevel(2);
    const level3 = await this.getCatLevel(3);
    const dictio3 = new Map();
    const dictio2 = new Map();
    const dictio1 = new Map();
    let categories: CatNode[] = []
    // @ts-ignore
    const dictio3Keys = level3.map((data) => {
      return data.category_idcategory
    })
    // @ts-ignore
    dictio3Keys.forEach((data) => {
      dictio3.set(data, [])
    })
    // @ts-ignore
    for (const category of level3) {
      const node = {
        label: category.name,
        data: {
          name: category.name,
          idcategory: category.idcategory,
          selected: false,
          father: category.category_idcategory
        }
      };
      dictio3.get(category.category_idcategory).push(node);
    }
    // @ts-ignore
    const dictio2Keys = level2.map((data) => {
      return data.category_idcategory
    });
    // @ts-ignore
    dictio2Keys.forEach((data) => {
      dictio2.set(data, [])
    });
    // @ts-ignore
    for (const category of level2) {
      const aux = dictio3.get(category.idcategory);
      let node: CatNode = {
        label: category.name,
        data: {
          name: category.name,
          idcategory: category.idcategory,
          selected: false,
          father: category.category_idcategory
        }
      }
      if (aux){
        node = {...node, children: aux}
      }
      dictio2.get(category.category_idcategory).push(node);
    }
    // @ts-ignore
    const dictio1Keys = level1.map((data) => {
      return data.category_idcategory
    });
    // @ts-ignore
    dictio1Keys.forEach((data) => {
      dictio1.set(data, [])
    });
    // @ts-ignore
    for (const category of level1) {
      const aux = dictio2.get(category.idcategory);
      let node: CatNode = {
        label: category.name,
        data: {
          name: category.name,
          idcategory: category.idcategory,
          selected: false,
          father: category.category_idcategory
        }
      }
      if (aux){
        node = {...node, children: aux}
      }
      dictio1.get(category.category_idcategory).push(node);
    }
    // @ts-ignore
    for (const category of level0) {
      const aux = dictio1.get(category.idcategory);
      let node: CatNode = {
        label: category.name,
        data: {
          name: category.name,
          idcategory: category.idcategory,
          selected: false
        }
      }
      if (aux){
        node = {...node, children: aux}
      }
      categories.push(node);
    }
    let node: CatNode = {
      label: this.myStorage.name,
      data: {
        name: this.myStorage.name,
        idcategory: this.myStorage.idcategory,
        selected: false,
      }
    }
    /* categories.unshift(node) */
    this.allCategories = categories;
    return categories;
  }

}
