import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GarageService } from 'src/app/services/garage/garage.service';
import { CategoriesService } from 'src/app/services/shop/categories.service';
import { ShopService } from '../../services/shop/shop.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';

@Component({
  selector: 'app-recommendeds',
  templateUrl: './recommendeds.component.html',
  styleUrls: ['./recommendeds.component.scss']
})
export class RecommendedsComponent implements OnInit {

  products: any;
  loading = true;
  loadingArray = [[1,2,3,4,5,6,7,8,9,10,11,12]];

  isMobile = false;

  constructor(private shop_service: ShopService, 
              private garageService: GarageService, 
              private categoryService: CategoriesService,
              private generalConfigurationService: GeneralConfigurationService,
              ) {
  }

  async ngOnInit() {
    this.shop_service.$loading.subscribe( data=> {
      this.loading = data;
    });
    this.garageService.$filterVehiclePrincipal.subscribe(data => {
      if (data != null) {
        this.shop_service.$loading.next(true);
        this.loadRecomendeds();
      }else{
        this.loadRecomendeds();
        this.loading = false;
      }
    });
    this.isMobile = this.generalConfigurationService.isMobile;
  }

  async loadRecomendeds() {
    const response$ = this.shop_service.getRecommendations();
    const aux = await lastValueFrom(response$);
    if(this.isMobile){
      const result: any[][] = [];
      let arr: any = [];
      for (let i = 0; i < aux.length; i++) {
        arr.push(aux[i]);
        if (arr.length === 4) {
          result.push(arr);
          arr = [];
          if( i >= 4 * 5){
            break;
          }
        }
      }
      if (result.length > 4 ){
        result.pop()
      }
      this.products = result;
    }else{
      const result: any[][] = [];
      let arr: any = [];
      let cont = 0;
      for (let i = 0; i < aux.length; i++) {
        arr.push(aux[i]);
        cont++;
        if (arr.length === 12 || ((aux.length - cont) === 0)) {
          result.push(arr);
          arr = [];
        }
      }
      this.products = result;
    }
    this.categoryService.$loading.next(false);
    this.garageService.$showSkeleton.next(false);
    this.shop_service.$loading.next(false);
  }



}
