import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  
  mapsLoaded: boolean = false;
  script: any;
  public updateLoadMap: BehaviorSubject<string> = new BehaviorSubject("");

  constructor() { }

  loadGoogleMapAPI(): Promise<boolean> {
    return new Promise( (resolve, rejects) => {
      if(this.mapsLoaded) {
        resolve(true);
        return
      }

      // Create the script tag, set the appropriate attributes
      this.script = document.createElement('script');
      // this.script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyApEueMSekDHoF_wjznoYdSvORiSyIrTFA&callback=initMap';
      this.script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyApEueMSekDHoF_wjznoYdSvORiSyIrTFA&callback=initMap';
      this.script.async = true;
      this.script.id = "mapScript"

      // Attach your callback function to the `window` object
      // @ts-ignore
      window['initMap'] = () => {
        this.mapsLoaded = true;
        resolve(true)
        return
        // JS API is loaded and available
      };
      // Append the 'script' element to 'body'
      document.body.appendChild(this.script);
    });
  }

}
