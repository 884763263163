import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthUserService } from 'src/app/services/auth/login-register/auth-user.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from '@capgo/ngx-intl-tel-input';
import { Router } from '@angular/router';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { Subscription } from "rxjs";
import { UserInformation } from 'src/app/interfaces/user';
import { FavoriteService } from 'src/app/services/favorite/favorite.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from '../../../services/pixel/pixel.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  formRegister!: FormGroup;
  user!: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  displayModal!: boolean;
  trySendCodeAgain = false;
  phoneNumber!: any;
  verifyngOtp = false;
  reCaptchaVerifier: any;
  otpInputFilled!: boolean;
  activeCode$!: number;
  count!: number;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  otp!: string;
  subscriptionOtpVerified!: Subscription;
  otpVerified = false;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };

  constructor(private formBuilder: FormBuilder,
              private authUser: AuthUserService,
              private router: Router,
              private gtmService: GoogleTagManagerService,
              private pixel: PixelService
              ) { }

  ngOnInit(): void {
    const auth = getAuth();
    this.user = this.authUser.user;
    this.buildForm();
    this.reCaptchaVerifier = new RecaptchaVerifier('sign-in-button-register', {
      'size': 'invisible',
      'expired-callback': () => {
      }
    }, auth);
    this.subscriptionOtpVerified = this.authUser.ObservOtpVerified.subscribe((res) => {
      this.otpVerified = res;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionOtpVerified) {
      this.subscriptionOtpVerified.unsubscribe();
    }
  }

  private buildForm(): void {
    this.formRegister = this.formBuilder.group({
      numberid: null,
      type_id_idtype: null,
      celphone: [this.user.phoneNumber, ],
      verifiedCelphone: [false],
      apple_id: [null],
      facebook: [ null],
      google: [this.user.id || null],
      checkTermandCond: ["", [Validators.required]],
      checkPolDat: ["", [Validators.required]],
      auth_user: this.formBuilder.group({
        first_name: [this.user.firstName, [Validators.minLength(1), Validators.required]],
        last_name: [this.user.lastName, [Validators.minLength(1), Validators.required]],
        username: ["",],
        email: [this.user.email || "",],
      }) as UserInformation as UserInformation
    });
  }

  signUpTrigg() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'sign_up'
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('CompleteRegistration', {
      content_category: 'page',
      content_name : 'register'
    });
  }

  async onSubmit(): Promise<void> {
    if (this.formRegister.invalid) { return; }
    const data = this.formRegister.get("auth_user.username")?.value;
    this.formRegister.patchValue({celphone: this.authUser.formDigitedPhone});
    this.formRegister.patchValue({verifiedCelphone: this.otpVerified});
    if (this.user.email) {
      this.formRegister.patchValue({auth_user:{username: this.user.email}});
      this.formRegister.patchValue({auth_user:{email: this.user.email}});
    } else {
      const email = this.formRegister.get("auth_user.email")?.value;
      this.formRegister.patchValue({auth_user: {username: email}});
    }
    const valido = await this.authUser.registerUserInBack(this.formRegister.value);
    if (valido) {
      this.signUpTrigg();
      const isNavigateToProduct:any = localStorage.getItem("navigateToProduct");
      if (JSON.parse(isNavigateToProduct) == null) {
        this.router.navigate(['home']);
      } else {
        const redirectToProduct = JSON.parse(isNavigateToProduct);
        this.router.navigate(['home']);
        setTimeout(() => {
          this.router.navigate(['/product'], {queryParams: {id : redirectToProduct}});
          localStorage.removeItem("navigateToProduct");
        }, 300);
      }
    }
  }

  navigateLogin() {
    this.router.navigate(["/login"]);
  }

  goToTermsAndConditions(){
    /* this.router.navigate(['/termsAndConditions'], {queryParams: {type : 'tyc'}}); */
    window.open("/termsAndConditions?type=tyc", '_blank');
  }

  goToPolicyInfo(){
    window.open("/termsAndConditions?type=data", '_blank');
  }

}
