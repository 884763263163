<div id="sign-in-button-register"></div>
<form [formGroup]="formRegister" (ngSubmit)="onSubmit()">
    <div class="container">

        <div class="flex align-items-center justify-content-start header">
            <div class="font-container">
                <span class="material-symbols-rounded icon fill ml-3 mr-2" (click)="navigateLogin()" style="cursor:pointer!important;">arrow_circle_left</span>
            </div>
            <p style="font-weight: 500;">Regresar</p>
            <img src="assets/img/shared/HorizontalLogo.png" alt="zaito">
        </div>
        
        <div class="gridRegister" style="margin: 0;">
            <div class="columRegister">
                <div class="title-form">
                    <p>Confirmar Datos para Continuar</p>
                </div>
                <div class="p-inputgroup group">
                    <div class="col-12" formGroupName="auth_user">
                        <p class="title-input">Nombre</p>
                        <span class="p-float-label">
                            <input formControlName="first_name" id="float-input" type="text" pInputText>
                        </span>
                    </div>
                    <div class="col-12" formGroupName="auth_user">
                        <p class="title-input">Apellidos</p>
                        <span class="p-float-label">
                            <input formControlName="last_name" id="float-input" type="text" pInputText>
                        </span>
                    </div>
                    <div class="col-12" formGroupName="auth_user">
                        <p class="title-input">Correo Electrónico</p>
                        <span class="p-float-label">
                            <input *ngIf="user.email" class="input-disabled" formControlName="email" id="float-input" type="text" pInputText readonly>
                            <input *ngIf="!user.email" formControlName="email" id="float-input" type="text" pInputText>
                        </span>
                    </div>

                    <div class="separated-gray-line"></div>

                    <div class="title-form sub">
                        <p>Confirmación de celular</p>
                    </div>

                    <app-phone-validation [isRegister]=true [isProfile]="false" 
                    style="display: flex; justify-content: center; margin-bottom: 4%; flex-direction: column;"
                    [recaptchaVerifier]="reCaptchaVerifier"
                    ></app-phone-validation>

                    <div>
                        <div class="col-12">
                            <p-checkbox name="groupname" value="val1" class="checkbox-300"
                                formControlName="checkTermandCond"></p-checkbox>
                            <span class="text-checkbox">
                                Acepto y he leído los <span (click)="goToTermsAndConditions()" >
                                    Términos y Condiciones.
                                </span>
                            </span>
                        </div>
                        <div class="col-12">
                            <p-checkbox name="groupname" value="val2" class="checkbox-300"
                                formControlName="checkPolDat"></p-checkbox>
                            <span class="text-checkbox">Acepto, Autorizo y he leído la 
                                <span (click)="goToPolicyInfo()">
                                    Política de <br>Tratamiento de Datos Personales.
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <p-button pRipple label="¡Comenzar!" class="button-comenzar" [disabled]="formRegister.invalid || !otpVerified"
                        (onClick)="formRegister.invalid || !otpVerified ? null : onSubmit()">
                            <ng-template pTemplate="content">
                                <img alt="logo" src="assets/img/shared/ZaiWhiteLogo.png" style="width: 1.5rem" />
                                <span class="p-button-label font-bold">¡ Comenzar !</span>
                            </ng-template>
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>