import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { GoogleMapsService } from 'src/app/services/googlemaps/google-maps.service';
import { UserprofileService } from 'src/app/services/userProfile/userprofile.service'
import { Geolocation } from '@capacitor/geolocation';
import { MessageService } from 'primeng/api';

declare var google: any;

@Component({
  selector: 'app-create-direction',
  templateUrl: './create-direction.component.html',
  styleUrls: ['./create-direction.component.scss'],
})
export class CreateDirectionComponent implements OnInit {

  @Input() isAndroidPlatform = false;
  @ViewChild('map', { static: false }) mapElement!: ElementRef;
  googleAutocomplete!: google.maps.places.AutocompleteService;
  placeService!: google.maps.places.PlacesService;
  autocompleteItems!: google.maps.places.AutocompletePrediction[];
  autocompleteFromCoords!: google.maps.GeocoderResult[];

  map: any;
  viewport: any = null;
  departments: any[] = [];
  citys: any[] = [];
  
  lat = 4.710988599999999;
  lng = -74.072092;
  zoom = 8;
  cityId = 0;
  departmentInputValue = "Departamento";
  cityInputValue = "Ciudad";
  autocompleteInputValue = "";

  showAutocompletItems = false;
  canSelectDep = false;
  canSelectCity = false;
  canSelectStreet = false;
  showAutocompletItemsFromCoords = false;
  infoLoaded = false;
  modalShowed = false;
  buttonTouched = false;


  showDialogFormCreateAddress = false;

  address2: string = "";
  lat2: number = 4.710988599999999;
  lng2: number = -74.072092;
  initZoom2: number = 8;
  department2: string = "";
  city2: string = "";
  cityId2: number = 0;
  departments2: any[] = [];
  citys2: any[] = [];
  departmentInputValue2: string = "";
  cityInputValue2: string = "";
  withGeolocation2: boolean = false;
  @ViewChild('map2', { static: false }) mapElement2!: ElementRef;
  marker2!: google.maps.Marker;
  geocoder2!: google.maps.Geocoder;
  googleAutocomplete2!: google.maps.places.AutocompleteService;
  placeService2!: google.maps.places.PlacesService;
  autocompleteFromCoords2!: google.maps.GeocoderResult[];

  map2: any;
  viewport2: any = null;

  description = "";
  streetAddress = "";
  locationAddress = "";
  
  needChangeDep = false;
  needChangeCity = false;
  canConfirm = true;
  hasAddress = false;
  mapLoaded = false;
  buttonTouched2 = false;
  confirmSuccess = true;
  @Output() createAddress = new EventEmitter<any>(false);

  constructor(private ngZone: NgZone,
              private googleMapsService: GoogleMapsService,
              private settingsService: UserprofileService,
              private messageService: MessageService
              ) {}

  async ngOnInit() {
    this.googleMapsService.loadGoogleMapAPI().then( async () => {
      this.autocompleteItems = [];
      this.autocompleteFromCoords = [];
      setTimeout(() => this.loadMap(), 200);
    }).catch((error) => {
      console.error(error);
      // this.toastService.toastErrorConnection("Error de conexión");
    });
  }

  private async loadMap(): Promise<void> {
    this.infoLoaded = false;
    const latLng = await new google.maps.LatLng(this.lat, this.lng);
    const mapOptions = {
      center: latLng,
      zoom: 5,
      scaleControl: false,
      disableDefaultUI: true,
      mapId: "631f1890778aa7fc",
      clickableIcons: false,
      draggable: false,
      disableDoubleClickZoom: true,
      keyboardShortcuts: false,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    this.dataDepTp();
    /* this.map = await new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    google.maps.event.addListenerOnce(this.map, 'tilesloaded', async () => {
      this.googleAutocomplete = await new google.maps.places.AutocompleteService();
      this.placeService = await new google.maps.places.PlacesService(document.getElementById('map'));
      
    }); */
  }

  private async dataDepTp(): Promise<void> {
    this.departmentInputValue = "Departamento";
    this.cityInputValue = "Ciudad";
    this.autocompleteInputValue = "";
    this.canSelectDep = false;
    this.settingsService.getDepartament().then(async (resp) => {
      this.departments = resp;
      function compare(a:any, b:any) {
        const A = a.name.toUpperCase();
        const B = b.name.toUpperCase();
        let comparison = 0;
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      this.departments.sort(compare)
      function TypeDocuments(element:any) {
        return element.id === 11 || element.id === 5;
      }
      const data = resp.filter(TypeDocuments);
      this.departments.unshift(...data);
      this.departments.splice(6, 1);
      this.departments.splice(2, 1);
      this.canSelectDep = true;
      this.infoLoaded = true;
    }).catch(error => {
      console.error(error);
    });
  }

  async selectDepartament(initDepartment: any): Promise<boolean> {
    const departmentID = initDepartment.value.id;
    const departmetValue = initDepartment.value.name;
    this.departmentInputValue = departmetValue;
    this.canSelectCity = false;
    this.canSelectStreet = false;
    this.autocompleteInputValue = "";
    this.cityInputValue = "Ciudad";
    this.viewport = null;
    this.zoom = 8;
    return await this.settingsService.getCity(departmentID).then( async (resp) => {
      this.citys = resp;
      this.showAutocompletItems = false;
      this.showAutocompletItemsFromCoords = false;
      this.canSelectCity = true;
      /* await this.UpdateSearchResults(this.departmentInputValue, true); */
      return true
    });
  }

  async selectCity(initCity: any): Promise<void> {
    const cityValue = initCity.value.name;
    this.cityId2 = initCity.value.id;
    this.cityInputValue = cityValue;
    this.showAutocompletItems = false;
    this.showAutocompletItemsFromCoords = false;
    this.autocompleteInputValue = "";
    this.canSelectStreet = true;
    this.showAutocompletItemsFromCoords = false;
    const index = this.citys.findIndex(city => city.id === this.cityId);
    this.zoom = index === 0 ? 12 : 15;
  }

  async UpdateSearchResults(data: string, isRegionOrCity: boolean): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      let valueTypes: any = [""];
      let valueInput: string = "";
      if (data == '') {
        this.showAutocompletItems = false;
        this.showAutocompletItemsFromCoords = false;
        this.autocompleteItems = [];
        this.autocompleteFromCoords = [];
        resolve(true);
      }
      if (isRegionOrCity) {
        valueTypes = this.cityInputValue === "Ciudad" ? ['(regions)'] : ['(cities)'];
        this.autocompleteItems = [];
        this.autocompleteFromCoords = [];
      } else {
        this.showAutocompletItems = true;
        valueTypes = ['address'];
        if (this.autocompleteInputValue == '') {
          this.autocompleteItems = [];
          this.autocompleteFromCoords = [];
        }
      }
      if (data == "Bogotá D.C.") {
        valueInput = "Bogotá";
        this.zoom = 10;
      } else {
        valueInput = data;
      }
      const request: google.maps.places.AutocompletionRequest = {
        input: valueInput,
        bounds: await this.setAutoCompletBounds(),
        componentRestrictions: { country: 'co' },
        types: valueTypes,
      };
      const callBackPredictions = async  (
        predictions: google.maps.places.AutocompletePrediction[],
        status :google.maps.places.PlacesServiceStatus) => {
          this.ngZone.run( () => {
            if (status === "OK") {
              const needRefresh = (this.departmentInputValue !== "Departamento" || this.cityInputValue !== "Ciudad" ) && this.autocompleteInputValue === "";
              if (needRefresh && isRegionOrCity) {
                this.autocompleteItems = predictions;
                let index: number = 0;
                if (this.cityInputValue === "Ciudad") {
                  index = this.autocompleteItems.findIndex( (item) => {
                    const array = item.description.split(",")
                    const L = array.length - 1;
                    if (array.length === 2) {
                      return array[L] === " Colombia" && array[L-1] == this.departmentInputValue
                    } else {
                      return array[L] === " Colombia" && array[L-1] == " " + this.departmentInputValue
                    }
                  });
                }
                index = index === -1 ? 0 : index;
                const [place]: any = this.autocompleteItems.slice(index, index + 1);
                this.selectSearchResult(place, false, false);
              } else {
                this.autocompleteItems = predictions.filter( pred => {
                  const array = pred.description.split(",")
                  const L = array.length;
                  const niceCountry = array[L-1] == " Colombia";
                  let niceDep = array[L-2] == " " + this.departmentInputValue;
                  if (this.cityInputValue == "Bogotá D.C.") {
                    niceDep = array[L-2] == " Bogotá";
                  } else if (this.departmentInputValue == "Archipiélago de San Andrés") {
                    niceDep = array[L-2] == " San Andrés y Providencia";
                  }
                  return niceCountry && niceDep
                });
              }
              resolve(true)
            }
          });
        }
      this.googleAutocomplete.getPlacePredictions(request, callBackPredictions);
    });
  }

  private async setAutoCompletBounds(): Promise<any> {
    if (!this.viewport) {
      return null;
    } else {
      var defaultBounds = await new google.maps.LatLngBounds(
        await new google.maps.LatLng(this.viewport.getSouthWest()),
        await new google.maps.LatLng(this.viewport.getNorthEast())
      );
      return defaultBounds;
    }
  }

  async selectSearchResult(place: any, isFromScreen: boolean, isFromCoords: boolean): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      if (isFromScreen && !isFromCoords) {
        this.autocompleteInputValue = place.description;
        this.showAutocompletItems = false;
      } else if (isFromCoords) {
        this.autocompleteInputValue = place.formatted_address;
        this.showAutocompletItemsFromCoords = false;
      } else {
        this.autocompleteInputValue = "";
      }
      const request = {
        placeId: place.place_id
      }
      const callBackDetails = async (place:any, status:any) => {
        this.viewport = place.geometry.viewport;
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        if (isFromScreen || isFromCoords) {
          this.autocompleteInputValue = place.formatted_address;
          this.zoom = 17;
          this.openGoogleMapsModal(false);
        }
        resolve(true)
      };
      this.placeService.getDetails(request, callBackDetails);
    })
  }

  async openGoogleMapsModal(withGeolocation: boolean) {
    this.showDialogFormCreateAddress = true;
    // let componentProps = {};
    if (withGeolocation) {
        this.departments2 = this.departments
        this.initZoom2 = 17
        this.withGeolocation2 = withGeolocation
    } else {
        this.address2 = this.autocompleteInputValue
        this.lat2 = this.lat
        this.lng2 = this.lng
        this.initZoom2 = this.zoom
        this.department2 = this.departmentInputValue
        this.city2 = this.cityInputValue
        this.cityId2 = this.cityId
        this.departments2 = this.departments
        this.citys2 = this.citys
        this.departmentInputValue2 = this.departmentInputValue
        this.cityInputValue2 = this.cityInputValue
    }
    this.initForm()
    // let modal: any;
    // if (this.isAndroidPlatform) {
    //   modal = await this.modalController.create({
    //     component: ModalGoogleMapsComponent,
    //     cssClass: "modal-google-maps",
    //     backdropDismiss: true,
    //     swipeToClose: false,
    //     presentingElement: await this.modalController.getTop(),
    //     mode: 'md',
    //     id: "schedule-modal",
    //     componentProps
    //   });      
    // } else {
    //   modal = await this.modalController.create({
    //     component: ModalGoogleMapsComponent,
    //     cssClass: "modal-schedule",
    //     backdropDismiss: false,
    //     swipeToClose: true,
    //     mode: 'ios',
    //     id: "schedule-modal",
    //     componentProps
    //   });
    // }
    // await this.removeListeners();
    // await modal.present();
    // // this.modalShowed = true;
    // modal.onWillDismiss().then( async data => {
    //   // this.modalShowed = false;
    //   await this.checkKeyBoard();
    //   // await this.addBackButtonListener();
    //   this.onWillDismissGoogleModal(data.data);
    // });
  }

  onWillDismissGoogleModal(data: any) {
    if (data) {
      const info = data;
      const L = info.autocompleteFromCoords
      if (info.needChangeDep && info.needChangeCity) {
        this.departmentInputValue = info.departmentInputValue;
        this.citys = info.citys;
      }
      this.cityInputValue = info.cityInputValue;
      this.cityId = info.cityId;
      this.autocompleteInputValue = info.address;
      this.autocompleteFromCoords = info.autocompleteFromCoords;
      this.viewport = info.viewport;
      this.lat = info.lat;
      this.lng = info.lng;
      this.zoom = info.zoom;
      this.canSelectCity = true;
      this.canSelectStreet = true;
      this.showAutocompletItemsFromCoords = true;
    } else {
      this.showAutocompletItems = false;
      this.showAutocompletItemsFromCoords = false;
    }
  }

  closeCreateModal(): void {
    // this.removeListeners();
    this.actionButton();
    // setTimeout(() => this.modalController.dismiss(), 200)
  }

  async selectUbication(): Promise<void> {
    if (this.canSelectCity && this.cityInputValue === "Ciudad") {
      // this.toastService.toastErrorConnection("Por favor seleccione una Ciudad");
    } else if (this.canSelectCity && this.cityInputValue !== "Ciudad") {
      this.openGoogleMapsModal(false);
    } else if (!this.canSelectCity && this.departmentInputValue === "Departamento") {
      this.openGoogleMapsModal(true);
    }
  }

  ClearAutocomplete(): void {
    this.citys = [];
    this.autocompleteItems = [];
    this.autocompleteFromCoords = [];
    this.departmentInputValue = "Departamento";
    this.cityInputValue = "Ciudad";
    this.autocompleteInputValue = "";
    this.zoom = 8;
    this.cityId = 0;
    this.lat = 4.710988599999999;
    this.lng = -74.072092;
    this.canSelectCity = false;
    this.canSelectStreet = false;
    this.viewport = null;
  }

  private actionButton(): void {
    this.buttonTouched = true;
    setTimeout(() => this.buttonTouched = false, 500);
  }

  initFormCreateAddress() {
  }

  destroyFormCreateAddress() {
  }












  async initForm() {
    this.setAddress();
    if (this.withGeolocation2) {
      this.loadMapWithGeolocation();
    } else {
      setTimeout(() => this.loadMap2(), 200);
    }
  }

  ngOnDestroy(): void {
  }

  private setAddress(): void {
    this.address2 = this.address2 === undefined ? "" : this.address2;
    this.hasAddress = false;
    if (this.address2 !== "") {
      this.hasAddress = true;
      this.locationAddress = "";
      this.streetAddress = "";
      const array = this.address2.split(",");
      this.streetAddress = array[0];
      for (let i = 1; i < array.length ; i++) {
        if (array.length >= 5) {
          if (i <= 2) {
            this.locationAddress = this.locationAddress + array[i].replace(" ", "");
            this.canConfirm = true;
          } else {
            this.locationAddress = this.locationAddress + "," + array[i];
            this.canConfirm = false;
          }
        } else {
          if (i === 1) {
            this.locationAddress = this.locationAddress + array[i].replace(" ", "");
            this.canConfirm = true;
          } else {
            this.locationAddress = this.locationAddress + "," + array[i];
            this.canConfirm = false;
          }
        }
      }
    }
  }

  private async loadMap2(): Promise<void> {
    const latLng = await new google.maps.LatLng(this.lat, this.lng);
    const mapOptions = {
      center: latLng,
      zoom: this.initZoom2,
      scaleControl: false,
      disableDefaultUI: true,
      mapId: "631f1890778aa7fc",
      clickableIcons: false,
      draggable: false,
      disableDoubleClickZoom: true,
      keyboardShortcuts: false,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    this.map = await new google.maps.Map(this.mapElement2.nativeElement, mapOptions);
    google.maps.event.addListenerOnce(this.map, 'tilesloaded', async () => {
      this.googleAutocomplete = await new google.maps.places.AutocompleteService();
      this.placeService = await new google.maps.places.PlacesService(document.getElementById('map'));
      this.geocoder2 = await new google.maps.Geocoder();
      this.marker2 = await  new google.maps.Marker({
        position: latLng,
        map: this.map,
        animation: google.maps.Animation.DROP,
        draggable: true,
      });
      this.marker2.setMap(this.map);
      this.changeMapOptions(true)
      this.marker2.addListener("dragend", () => {
        this.map.panTo(this.marker2.getPosition() as google.maps.LatLng);
        // @ts-ignore
        const lat = this.marker2.getPosition().lat();
        // @ts-ignore
        const lng = this.marker2.getPosition().lng();
        this.getAddressFromCoords(lat, lng, true);
      });
      this.mapLoaded = true;
    });
  }

  private async loadMapWithGeolocation(): Promise<void> {
    Geolocation.getCurrentPosition().then( async (resp:any) => {
      this.lat = resp.coords.latitude;
      this.lng = resp.coords.longitude;
      const latLng = await new google.maps.LatLng(this.lat, this.lng);
      const mapOptions = {
        center: latLng,
        zoom: this.initZoom2,
        scaleControl: false,
        disableDefaultUI: true,
        mapId: "631f1890778aa7fc",
        clickableIcons: false,
        draggable: false,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        scrollwheel: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      this.map = await new google.maps.Map(this.mapElement2.nativeElement, mapOptions);
      google.maps.event.addListenerOnce(this.map,'tilesloaded', async () => {
        this.googleAutocomplete = await new google.maps.places.AutocompleteService();
        this.placeService = await new google.maps.places.PlacesService(document.getElementById('map'));
        this.geocoder2 = await new google.maps.Geocoder();
        await this.getAddressFromCoords(this.lat, this.lng, false);
        this.marker2 = await new google.maps.Marker({
          position: latLng,
          map: this.map,
          animation: google.maps.Animation.DROP,
          draggable: true,
        });
        this.marker2.setMap(this.map);
        this.changeMapOptions(true)
        this.marker2.addListener("dragend", () => {
          this.map.panTo(this.marker2.getPosition() as google.maps.LatLng);
          // @ts-ignore
          const lat = this.marker2.getPosition().lat();
          // @ts-ignore
          const lng = this.marker2.getPosition().lng();
          this.getAddressFromCoords(lat, lng, true);
        });
        this.mapLoaded = true;
      });
    }).catch((error:any) => {
      console.error(error);
      // this.toastService.toastErrorConnection("Error en Geolocalización")
    });
  }

  private changeMapOptions(enableInterface: boolean): void {
    if (enableInterface) {
      const mapOptions = {
        scaleControl: false,
        disableDefaultUI: true,
        clickableIcons: false,
        draggable: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        scrollwheel: true,
      }
      this.map.setOptions(mapOptions)
      this.map.addListener("dblclick", (event: google.maps.MouseEvent) => {
        this.map.setZoom(16);
        this.map.setCenter(event.latLng);
        this.marker2.setPosition(event.latLng);
        this.marker2.setMap(this.map)
        this.getAddressFromCoords(event.latLng.lat(),event.latLng.lng(), true)
      });
    } else {
      const mapOptions = {
        scaleControl: false,
        disableDefaultUI: true,
        clickableIcons: false,
        draggable: false,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        scrollwheel: false,
      }
      this.map.setOptions(mapOptions)
      google.maps.event.clearListeners(this.map, "dblclick");
    }
  }

  private async getAddressFromCoords(lattitude: number, longitude: number, fromMarker: boolean): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      const latLng = {
        lat: lattitude,
        lng: longitude
      }
      const request: google.maps.GeocoderRequest = { location: latLng }
      const callBackGeocode = (
        result: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus) => {
        if (status == "OK") {
          this.autocompleteFromCoords = result.slice(0,5);
          this.address2 = result[0].formatted_address;
          this.setAddress();
          this.chekcCityAndDep(result[0]);
          if (fromMarker) {
            this.selectSearchResult2(result[0]);
            this.withGeolocation2 = false;
          }
          resolve(true)
        }
      };
      this.geocoder2.geocode(request, callBackGeocode);
    })
  }
  
  async selectSearchResult2(place: any): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      const request = {
        placeId: place.place_id
      }
      const callBackDetails = async (place:any, status:any) => {
        this.viewport = place.geometry.viewport;
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        this.address2 = place.formatted_address;
        resolve(true)
      };
      this.placeService.getDetails(request, callBackDetails);
    })
  }

  private chekcCityAndDep(addressFromCoords: google.maps.GeocoderResult): void {
    const inputDept = this.departmentInputValue;
    const inputCity = this.cityInputValue;
    const newAddress = addressFromCoords.formatted_address.split(",")
    const N = newAddress.length;
    let depIndex = N - 2;
    let cityIndex = N - 3;
    const newDep = newAddress[depIndex];
    if (newDep == " Bogotá") {
      cityIndex = depIndex;
    }
    let newCity = newAddress[cityIndex];
    const auxArray = newCity.split(" ");
    const isCartagena = newCity == " Provincia de Cartagena" || auxArray[auxArray.length - 1] == "Cartagena" ;
    if (isCartagena) {
      newCity = " Cartagena";
    } else if (N === 3) {
      newCity = " " + auxArray[auxArray.length - 1];
    }
    const sameDep = newDep == " " + inputDept;
    let sameCity = newCity == " " + inputCity;
    let auxDep: any = [];
    let auxCity: any = [];
    if (!sameDep) {
      const isSanAndres = newDep.split(" y")[0] == " San Andrés";
      if (isSanAndres) {
        auxDep = this.departments.filter(dep => {
          return dep.name == "Archipiélago de San Andrés"
        });
      } else {
        auxDep = this.departments.filter(dep => {
          return " " + dep.name == newDep || " " + dep.name == newDep + " D.C."
        });
      }
      this.needChangeDep = true;
      this.selectDepartament2(auxDep[0]).then(() => {
        auxCity = this.citys.filter( city => { return " " + city.name == newCity || " " + city.name == newCity + " D.C."});
        this.cityInputValue = auxCity[0].name;
        this.cityId = auxCity[0].id;
        this.needChangeCity = true;
      }).catch(() => {});
    } else if (!sameCity) {
      auxCity = this.citys.filter( city => { return " " + city.name == newCity || " " + city.name == newCity + " D.C."});
      this.cityInputValue = auxCity[0].name;
      this.cityId = auxCity[0].id;
      this.needChangeCity = true;
    }
  }

  async selectDepartament2(initDepartment: any): Promise<boolean> {
    const departmentID = initDepartment.id;
    this.departmentInputValue = initDepartment.name;
    return this.settingsService.getCity(departmentID).then( async (resp) => {
      this.citys = resp;
      return true
    });
  }

  // async selectCity(initCity: any): Promise<void> {
  //   const cityValue = initCity.text;
  //   this.cityId = initCity.value;
  //   this.cityInputValue = cityValue;
  // }
    
  async goBackToCreationAddress(): Promise<void> {
    let data: any = null;
    data = {
      viewport: this.viewport,
      citys: this.citys,
      departmentInputValue: this.departmentInputValue,
      cityInputValue: this.cityInputValue,
      cityId: this.cityId,
      address: this.address2,
      autocompleteFromCoords: this.autocompleteFromCoords,
      needChangeDep: this.needChangeDep,
      needChangeCity: this.needChangeCity,
      lat: this.lat,
      lng: this.lng,
      zoom: this.initZoom2
    }
  }

  confirm(): void {
    const adrr = this.address2 + ", " + this.cityInputValue + ", " + this.departmentInputValue + ", Colombia"
    this.confirmSuccess = false;
    this.settingsService.updateLocation(adrr, this.cityId2, undefined, undefined, this.description)
    .then((resp:any) => {
      this.confirmSuccess = true;
      this.showDialogFormCreateAddress = false;
      this.createAddress.emit(resp);
      this.messageService.add({severity:'success', summary: 'Los cambios han sido aplicados con éxito!', life: 3000});
      // this.closeModalGoogleMaps();
      // this.modalController.dismiss(null,null,"main-googleMaps-modal");
      // this.toastService.toastSuccess("¡Los cambios han sido aplicados con éxito!");
    }).catch(() => {});
  }

  async closeModalGoogleMaps(): Promise<void> {
    this.actionButton();
  }

}
