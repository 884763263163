import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserprofileService } from 'src/app/services/userProfile/userprofile.service';
import { FavoriteService } from 'src/app/services/favorite/favorite.service';
import { Subscription } from "rxjs";
import { MessageService } from "primeng/api";
import { AccountService } from 'src/app/services/shared/account/account.service';
import { ShopCartService } from 'src/app/services/shop/shop-cart.service';
import { CarzService } from 'src/app/services/carz/carz.service';
import { CategoriesService } from 'src/app/services/shop/categories.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  menuOverlay = [
    {'text': 'Datos de Perfil', 'icon': 'manage_accounts', 'last': false, 'click': 'profile'},
    {'text': 'Mis Compras', 'icon': 'local_mall', 'last': false, 'click': 'buys'},
    {'text': 'Cerrar Sesión', 'icon': 'logout', 'last': true, 'click': 'close'}
  ];

  displaySideBarFavorites:boolean = false;
  Favorites: any = null;
  showSkeleton = true;
  noFavorites = false;
  continueResults = true;
  productsValues: {installPrice: number, productPrice: number, totalPrice: number}[] = [];
  page = 1;
  displaySideBarCart:boolean = false;
  subsUserProfile!:Subscription;
  userName:string = "";
  productsCart:any = null;
  islogged:boolean = false;
  isMobile = false;

  adImgs = ["../../../assets/img/shop/TagProm2.svg",
            "../../../assets/img/shop/TagPercent2.svg",
            "../../../assets/img/shop/TagOff2.svg"];

  constructor(private userProfile: UserprofileService, 
              public router: Router, 
              private serviceFavorites: FavoriteService,
              private messageService: MessageService,
              private accountInfo: AccountService,
              private shopCartService: ShopCartService,
              private carz: CarzService,
              private categoriesService: CategoriesService,
              private deviceService: DeviceDetectorService) {}
 
  async ngOnInit() {
    this.islogged = await this.accountInfo.isLogged();
    if (this.islogged) {
      await this.getUserInfo();
    }
    this.isMobile = this.deviceService.isMobile();
  }

  getUserInfo() {
    this.subsUserProfile = this.userProfile.getInfoUser().subscribe( data => {
      this.userName = data[0].auth_user.first_name? data[0].auth_user.first_name: "";
      const firstName =  data[0].auth_user.first_name;
      const lastName = data[0].auth_user.last_name;
      const userName = firstName + " " + lastName;
    }, error => console.log(error));
  }

  selectOptionMenu(option:string = "profile" || "buys" || "close") {
    if (option === 'close') {
      localStorage.clear();
      this.shopCartService.clearCart();
      this.carz.removeListenMsgs.next(true);
      this.categoriesService.loadCategories();
      this.router.navigate(["/login"]);
    } else if(option === 'profile'){
      this.router.navigate(["/profile"])
    } else if (option === 'buys') {
      this.router.navigate(["/history"])
    }
  }

  async openSidebarFavorite() {
    this.displaySideBarFavorites = true;
    this.Favorites = null;
    if (this.islogged) {
      await this.getFavorites(false, true);
    } else {
      this.router.navigate(['login']);
    }
  }

  getFavorites(extraLoad?:any, filtered?: boolean) {
    this.serviceFavorites.getFavorites(this.page).then(data => {
      if (filtered) {
        this.Favorites = data.results;
      } else {
        this.Favorites = this.Favorites.concat(data.results);
      }
      if (data.next) {
        this.page++;
        this.continueResults = true;
      } else {
        this.continueResults = false;
      }
      if (extraLoad) {
        extraLoad.target.complete();
      }
      this.setTotalProducts();
      if (this.Favorites.length === 0) {
        this.noFavorites = true;
      } else {
        this.noFavorites = false;
      }
      this.showSkeleton = false;
    }, error => console.log("error de conexion"));
  }

  private setTotalProducts() {
    this.productsValues = []
    this.Favorites.forEach( (favorite:any) => {
      const product = favorite.product;
      const tax = product.tax === null ? 0 : product.tax;
      const installTax = product.install_tax === null ? 0 : product.install_tax;
      const discount = product.productAd[0]?.discount ? (product.productAd[0]?.discount) / 100 : 0;
      const offer = product.productAd[0]?.offer ? (product.productAd[0]?.offer) : 0;
      const installPrice = product.install_prize * (1 + (installTax / 100));
      const productPrice = product.prize * (1 + tax / 100);
      let totalPrice = productPrice;
      if (product.productAd[0]?.type === 1) {
        totalPrice = productPrice * (1 - discount);
      }else if (product.productAd[0]?.type === 2){
        totalPrice = productPrice - offer;
      }
      const values: {installPrice: number, productPrice: number, totalPrice: number} = {
        installPrice: installPrice,
        productPrice: productPrice,
        totalPrice: totalPrice
      }
      this.productsValues.push(values);
    });
  }

  async deleteFavorite( item: any ){
    await this.serviceFavorites.deleteFavorite(item.id_wish).toPromise()
    .then(resp => {
      this.setMsgSuccess("Eliminado con exito");
      this.page = 1;
      this.Favorites = null;
      this.getFavorites(false, true);
    });
  }

  setMsgSuccess(msg: string) {
    this.messageService.add({
      severity: "success",
      summary: "Éxito",
      detail: msg,
      life: 4000
    });
  }

   goToHome(){
    this.router.navigate(['/home'])
   }

  displaySideBarCartFuntions(e:any) {
    this.displaySideBarCart = e;
  }


  putInCar(product: any): void {
    if (this.islogged) {
      if (product.stock <= 0) {
        /* this.toastService.toastErrorConnection(this.product.name + " no se encuentra disponible."); */
      } else {
        const workshopId = product.workship_idworkship.idworkship;
        const weight = product.dimensions.weight;
        const success = this.shopCartService.adToCar(1, product.idproduct, workshopId, weight, product.is_liquid);
        if (success) {
          this.shopCartService.saveCar();
          this.setMsgSuccess(product.name + " ha sido agregado al carrito!");
          
        } else {
          // MENSAJE DE NO SE PUEDE AGREGAR AL CARRITO
          // this.openModalInfo();
        }
      }
    }
  }

}