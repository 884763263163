import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { PackageDimensions } from 'src/app/interfaces/shopCart';
import { lastValueFrom } from 'rxjs';
// import { PackageDimensions } from "src/interfaces/Store";

@Injectable({
  providedIn: 'root'
})
export class ShippingsService {

  url = environment.shipping_url;
  shipping_code = environment.shipping_code;
  shipping_code_liquid = environment.shipping_code_liquid;
  headers = new HttpHeaders({ "apikey": "a9185d3a-59cd-11ec-bf63-0242ac130002", "clave": "gS4iD2yX4gP0iI1s"});
  test = !environment.production;


  constructor(private http: HttpClient) {}

  getShippingQuotation(origDaneCode: string, destDaneCode: string, worth: string, 
    height: number, width: number, length: number, weight: number, units:any, is_liquid: boolean, isParcel: boolean): Promise<any> {
      if(is_liquid){
        const dimensions: PackageDimensions = {
          "alto" : height * units, 
          "ancho": width * units, 
          "largo" : length * units, 
          "peso": weight * units, 
          "unidades" : 1
        }
        const body = {
          "origen": origDaneCode,
          // "destino": "05001000",
          "destino": destDaneCode,
          "valoracion": worth.toString(),
          "detalles": [dimensions],
          test : this.test
        }
        /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}) */
        return  lastValueFrom(this.http.post(this.url + 'cotizacionesLiquid?code=' + this.shipping_code_liquid, body, {headers: this.headers}));
      }else if (isParcel){
        const dimensions_aux: PackageDimensions[] = []
        for (let i = 0; i < units; i++) {
          const dimensions: PackageDimensions = {
            "alto" : height, 
            "ancho": width, 
            "largo" : length, 
            "peso": weight, 
            "unidades" : 1
          }
          dimensions_aux.push({ ...dimensions});
        }
        const body = {
          "origen": origDaneCode,
          // "destino": "05001000",
          "destino": destDaneCode,
          "valoracion": worth,
          "detalles": dimensions_aux,
          test : this.test
        }
        return  lastValueFrom(this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.headers}));
      }
      else{
        const dimensions: PackageDimensions = {
          "alto" : height * units, 
          "ancho": width * units, 
          "largo" : length * units, 
          "peso": weight * units, 
          "unidades" : 1
        }
        const body = {
          "origen": origDaneCode,
          // "destino": "05001000",
          "destino": destDaneCode,
          "valoracion": worth.toString(),
          "detalles": [dimensions],
          test : this.test
        }
        /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}) */
        return  lastValueFrom(this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.headers}));
      }
  }

  promisedParseJSON(json: any) {
    return new Promise((resolve, reject) => {
        try {
            resolve(json)
        } catch (e) {
            reject(e)
        }
    })
  }


  getShippingPackageQuotation(origDaneCode: string, destDaneCode: string, worth: string, dimensions: PackageDimensions[], isLiquid:boolean, isParcel: boolean): Promise<any> {
    if(isLiquid){
      let sumaDimension = {
        alto: 0,
        ancho: 0,
        largo: 0,
        peso: 0,
        unidades: 1
      }
      dimensions.forEach((dimension) => {
        sumaDimension.alto += dimension.alto;
        sumaDimension.ancho += dimension.ancho;
        sumaDimension.largo += dimension.largo;
        sumaDimension.peso += dimension.peso;
      });
      const body = {
        "origen": origDaneCode,
        "destino": destDaneCode,
        // "destino": "05001000",
        "valoracion": worth,
        "detalles": [sumaDimension],
        test : this.test
      }
      /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}); */
      return  this.http.post(this.url + 'cotizacionesLiquid?code=' + this.shipping_code_liquid, body, {headers: this.headers}).toPromise();
    } else if(isParcel){
      let dimensions_aux: any[] = []
      dimensions.forEach((dimension) => {
        dimensions_aux.push({ ...dimension, unidades: 1 });
      });
      const body = {
        "origen": origDaneCode,
        "destino": destDaneCode,
        // "destino": "05001000",
        "valoracion": worth,
        "detalles": dimensions_aux,
        test : this.test
      }
      return this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.headers}).toPromise(); 
    } else {
      const body = {
        "origen": origDaneCode,
        "destino": destDaneCode,
        // "destino": "05001000",
        "valoracion": worth,
        "detalles": dimensions,
        test : this.test
      }
      /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}); */
      return this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.headers}).toPromise();
    }
  }

  getRemisionTrace(remisionCode: string) {
    const body = {
      codigo_remision: remisionCode,
      test : this.test
    }
    return  this.http.post(this.url + "follow?code=aRL7vjpJKDnF8dhkaqYTKs/IQOL9lG3Zz4uq94wAdF58e0D8hr8MBg==", body, {headers: this.headers}).toPromise();
  }

}
