<div class="col-12 flex p-0 mb-3 card">
    <div class="col-4" style="height: 100%;">
        <div class="z-shadow-principal card-image-product flex justify-content-center align-items-center">
            <img *ngIf="product.productAd.length > 0" src="{{adImgs[product.productAd[0].type]}}" alt="anuncio" class="ad-tag">
            <img [src]="product.id_image[0].image_idimage" class="image-product" alt="{{product.name}}"
                  onerror="this.src='assets/img/shared/imagenotavailable.svg'" style="width: 90px; height: 90px; border-radius: 8px;">
        </div>
        <div class="card-no-Available" *ngIf="!product.is_available || !this.isEnoughStock">
            <p style ="margin-top: 0;">No disponible</p>
        </div>
    </div>
    <div class="col-6">
        <div class="card-content">
            <label class="ellipsis" tooltipPosition="left" pTooltip="{{ product.name + (selectedVariant ? '-' + selectedVariant : '') }}" > 
                {{ product.name }} {{selectedVariant ? '-' + selectedVariant : ''}}
            </label>
            <div>
                <div *ngIf="product.productAd?.length == 0">
                    <p style="margin: 0;">
                        <label *ngIf="product.prize !== -1" class="item-number">$ {{ originalPrice | number }}</label>
                        <label *ngIf="product.prize === -1" class="item-number">Cotizar</label>
                    </p>
                </div>
                <div *ngIf="product.productAd[0]?.type == 0" style="margin-top: -7px;">
                    <p style="margin: 0;">
                        <label style="color: var(--verde-zaito-2);" class="text-ad">Promocionado </label>
                    </p>
                    <label *ngIf="product.prize !== -1" class="item-number">$ {{ originalPrice | number }}</label>
                    <label *ngIf="product.prize === -1" class="item-number">Cotizar</label>
                </div>
                <div *ngIf="product.productAd[0]?.type == 2">
                    <p style="margin: 0;">
                        <label style="color: var(--verde-zaito-2);" class="text-ad">Antes </label>
                        <label class="text-ad text-antes">$ {{originalPrice | number}}</label>
                    </p>
                    <div class="item-number">$ {{ discoundPrice | number }}</div>
                </div>
                <div *ngIf="product.productAd[0]?.type == 1">
                    <p style="margin: 0;">
                        <label style="color: var(--verde-zaito-2);" class="text-ad">-{{ product.productAd[0].discount }}%</label>
                        <label class="text-ad text-antes">$ {{originalPrice | number}}</label>
                    </p>
                    <div class="item-number">$ {{ discoundPrice | number }}</div>
                </div>
                <div *ngIf = "nontype.length > 0">
                    <p style="margin: 0;">
                        <label style="color: var( --rojo-zaito-1);font-size: 0.9em;" >{{ nontype }}</label>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-2 flex" style="flex-wrap: wrap;">
        <div>
            <p class="m-0 text-delete" (click)="removeProdct()">Eliminar</p>
        </div>
        <div class="flex" style="margin: 0 0 0 -2rem; align-items: center;">
            <span class="material-symbols-rounded fill cursor-pointer" style="color: var(--gris-zaito-3); font-size: 18px;" *ngIf="line.units > 1" (click)="changeUnits('subs')">do_not_disturb_on</span>
            <div style="height: fit-content; padding: 2px 8px; margin: 0 5px; border: 1px solid var(--gris-zaito-2); border-radius: 4px; font-size: 90%;">{{ line.units }}</div>
            <span class="material-symbols-rounded fill cursor-pointer" style="color: var(--gris-zaito-3); font-size: 18px;" *ngIf="hasStock" (click)="changeUnits('add')">add_circle</span>
        </div>
    </div>
</div>