import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AlliesService {

  baseurl = environment.server_url;
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });

  constructor(private http: HttpClient) { 
  }

  private setToken() {
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }


  getInfoMarker(id:number): Promise<any> {
    this.setToken();
    return this.http.get(this.baseurl +  "market/workshops/" + id +"/", { headers: this.httpHeaders }).toPromise();
  }

}
