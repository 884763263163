import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';
import { doc, getDoc, Firestore, collection, query, getCountFromServer, setDoc, addDoc, updateDoc } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { GarageService } from '../garage/garage.service';
import { orderBy } from 'firebase/firestore';
import { AccountService } from 'src/app/services/shared/account/account.service';
import { UserprofileService } from '../userProfile/userprofile.service';

export interface ChatCARZ {
  car_user_id: number,
  id_user: number,
  date_create: any,
  noreaded_user: number,
  noreaded_support: number,
  newmessage: boolean,
  received: boolean,
  receptionist: number,
  user_name: string,
  dateAvailable: boolean,
  lastTime: any;
}

export interface MessageCarz {
  text?: string;
  date: Date;
  is_user: boolean;
  image?: string;
  img: string;
  dateAvailable: boolean;
  product: number
}

@Injectable({
  providedIn: 'root'
})
export class CarzService {

  ObserModalOpen = new Subject<{modal:boolean, modalChat:{active:boolean, text:string, isProduct: boolean}}>();
  removeListenMsgs = new Subject<boolean>();
  badgeNewMsg = new Subject<boolean>();
  collection!:any;
  item$!: Observable<any[]>;
  garage = [];
  principalCar:any;
  chats!:any;
  chatExist = false;
  islogged:boolean = false;
  amountVehicles:number;
  newUserCreateFirstVehicle: boolean = false;

  constructor(private fs: Firestore,
              private garageService: GarageService,
              private accountInfo: AccountService,
              private profile: UserprofileService) {
                
  }

  async checkIfChatsExist():Promise<number>{
    try{
      this.islogged = await this.accountInfo.isLogged();
      await this.takePrincipalCar();
      
      if (this.garage[0] == false && !this.islogged){
        try{
          // No está logueado
          const id = localStorage.getItem('carz-id')?.toString();
          const chatDocsRef = collection(this.fs, "Chat-CARZ", id + "/msg");
          const getChats = await getCountFromServer(chatDocsRef);
          this.chatExist = (getChats.data().count > 0 ? true : false);
          return getChats.data().count;
        }catch {
          return 0;
        }
      }else {
        try{
          // Está logueado, tiene 0 o más vehículos
          const chatDocsRef = collection(this.fs, "Chat-CARZ", this.principalCar.car_user_id.toString() + "/msg");
          const getChats = await getCountFromServer(chatDocsRef);
          this.chatExist = (getChats.data().count > 0 ? true : false);
          return getChats.data().count;
        } catch (error){
          console.error(error);
          return 0;
        }
      }
    }catch (error) {
      console.error(error);
      return 0;
    }
  }

  async getChatsCarz(): Promise<any> {
    try{
      if(this.garage[0] == false && !this.islogged){
        const id = localStorage.getItem('carz-id')?.toString();
        const chatDocsRef = collection(this.fs, "Chat-CARZ", id + "/msg");
        const collectionMsgs = query(chatDocsRef, orderBy("date") );
        
        return collectionMsgs
      }else{
        
        const id = this.principalCar?.car_user_id ? this.principalCar?.car_user_id.toString() : this.principalCar.auth_user;
        const chatDocsRef = collection(this.fs, "Chat-CARZ", id + "/msg");
        const collectionMsgs = query(chatDocsRef, orderBy("date") );
    
        return collectionMsgs
      }
    }catch{
      return 0;
    }
  }

  async ThereIsNewMsg(){
    try{
      if(!this.chatExist) return 0;
      if(this.garage[0] == false && !this.islogged){
        const id = localStorage.getItem('carz-id')?.toString();
        const path = 'Chat-CARZ' + "/" + id;
        const userDocRef = doc(this.fs, path);
        
        return userDocRef
      }else{
        
        // await this.takePrincipalCar();      
        const id = this.principalCar?.car_user_id ? this.principalCar?.car_user_id.toString() : this.principalCar.auth_user;
        const path = 'Chat-CARZ' + "/" + id;
        const userDocRef = doc(this.fs, path);
        
        return userDocRef
      }
    } catch (error){
      console.log(error);
      
      return 0;
    }

  }

  async userSawMessages () {
    if(this.garage[0] == false && !this.islogged){
      try{
      const id = localStorage.getItem('carz-id')?.toString();
      const path = 'Chat-CARZ' + "/" + id;
      const userDocRef = doc(this.fs, path);
      const updateData: Partial<ChatCARZ> = {
        noreaded_user: 0,
      }
        await updateDoc(userDocRef, updateData);
      }catch{}
    }else{
      try{
      const id = this.principalCar?.car_user_id ? this.principalCar?.car_user_id.toString() : this.principalCar.auth_user;      
      const path = 'Chat-CARZ' + "/" + id;
      const userDocRef = doc(this.fs, path);

      const updateData: Partial<ChatCARZ> = {
        noreaded_user: 0,
      }
        await updateDoc(userDocRef, updateData);
      }catch{}
    }
  }

  async sendMessage(inputMsgCarz: string, image:any): Promise<void> {
    const fireColle = 'Chat-CARZ';
    const msgUser: MessageCarz = {
        text: inputMsgCarz,
        is_user: true,
        date: new Date(Date.now()),
        img: image ?? "",
        dateAvailable: true,
        product: 0
    };

    if(this.garage[0] == false && !this.islogged){
      try{
        const id = localStorage.getItem('carz-id')?.toString();        
        const userDocRef = collection(this.fs, fireColle, id + "/msg")
        await addDoc(userDocRef, msgUser);
        this.updateLastmsg(fireColle, id);
      } catch(error){
        console.log(error);
      }

    }else{
      try{
        const userDocRef = collection(this.fs, fireColle, this.principalCar.car_user_id.toString() + "/msg")
        await addDoc(userDocRef, msgUser);
        await this.updateLastmsg(fireColle, this.principalCar.car_user_id)
      } catch(error){
        console.log(error);
      }
    }

  }

  private async updateLastmsg(fireColle: string, Id: any) {
    const path = fireColle + "/" + Id;
    const userDocRef = doc(this.fs, path);
    const docSnap = await getDoc(userDocRef);    

    const id_user = (this.garage[0] == false && !this.islogged) ? docSnap.data()!['id_user'] : this.principalCar.auth_user;
    const noreaded = docSnap.exists() ? await docSnap.data()!['noreaded_support'] + 1 : 1;
    const updateData: Partial<ChatCARZ> = {
        id_user: id_user,
        noreaded_support: noreaded,
        noreaded_user: 0,
        newmessage: true,
        lastTime: new Date()
    }
    await updateDoc(userDocRef, updateData);

  }


  async createChatRoom() {
    if(this.garage[0] == false && !this.islogged){
      //Este es un usuario invitado que iniciará un chat en CARZ.
      try {
        //Lleva el conteo de chats de usuarios invitados.
        let chatNumber;
        const count = doc(this.fs, 'Chat-CARZ', "Counting-Invited-Users");
        const countDoc = await getDoc(count);
        if (countDoc.exists()) {
          chatNumber = countDoc.data()['count'] + 1;
          await updateDoc(count, {
            count: chatNumber
          });
        } else {
          await setDoc(doc(this.fs, 'Chat-CARZ', "Counting-Invited-Users"), {
            count: 1
          });
          chatNumber = 1;
        }

        //Crea el chat de usuarios invitados
        const docRef = await addDoc(collection(this.fs, 'Chat-CARZ'), {
          id_user: 'Invitado ' + chatNumber,
          date_create: new Date(),
          noreaded_user: 0,
          noreaded_support: 0,
          car_user_id: 'Invitado',
          received: false,
          receptionist: 0,
          user_name: 'Invitado ' + chatNumber,
          dateAvailable: true,
          newmessage: true,
          lastTime: new Date(),
        });
        await updateDoc(docRef, {
          car_user_id: docRef.id
        });

        localStorage.setItem("carz-id", docRef.id);
      } catch (error) {
          console.error(error)
          return error;
      }
    }else{

      const data = await this.garageService.getUserId();
      const userId = data[0].id;
      const userName = data[0].first_name;
      const car_user_id = this.principalCar.car_user_id;                  
      try {
          const id = "" + car_user_id;
          const chatUser: ChatCARZ = {
              id_user: userId,
              date_create: new Date(),
              noreaded_user: 0,
              noreaded_support: 0,
              car_user_id: car_user_id,
              received: false,
              receptionist: 0,
              user_name: userName ?? "",
              dateAvailable: true,
              newmessage: true,
              lastTime: new Date(),
          };
          const path = 'Chat-CARZ';
          const userDocRef = doc(this.fs, path, id);
          await setDoc(userDocRef, chatUser);
      } catch (error) {
          console.error(error)
          return error;
      }

    }
    this.chatExist = true;
  }


  private async takePrincipalCar(): Promise<void> {
    try {
      // Tres posibilidades. 
      // [false] = no está logueado, 
      // 0     = array vacío, o sea sin carros pero logueado,
      // > 0   = cantidad de vehículos y logueado. 
      this.garage = [];
      const data = await this.garageService.getState();      
      this.garage = data === false ? [false] : data;      
      //logueado pero con al menos un vehículo
      if(this.islogged){
        if (data.length > 0) {
          this.garage.forEach((element, i) => {
            if (element['is_principal']) {
              this.principalCar = element;
            }
          });
        }else if(data.length == 0){          
          await this.profile.getInfoUserCarz().then( data => {
            this.principalCar =  {
              car_user_id: "N"+data[0].auth_user.id,
              auth_user: "N"+data[0].auth_user.id
            }
          });
        } 
      }
    } catch (error) {
      console.error(error);
    }
  }

}
