import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {


  httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(private http: HttpClient,) { }


  sendSmsConfirmation(phone: any){
    const body = {phone}
    const url = "https://sharedzaitofunctions.azurewebsites.net/api/SendSmsConfirmation?code=ft0C0wSb0UlsdyDVsTPrGv4eMXZlYQCMBjBW6YLl1CsXAzFupoKAZw%3D%3D";
    return this.http.post(url, body,  { headers: this.httpHeaders }).toPromise();
  }

  sendSmsVerification(phone: any, code: any){
    const body = {
      phone,
      code
    }
    const url = "https://sharedzaitofunctions.azurewebsites.net/api/VerifySmsCode?code=2w92EL7HiD92ReAGa4H2OLEUfqtQoIFslv-nMu_wddQxAzFudy6nWQ%3D%3D";
    return this.http.post(url, body,  { headers: this.httpHeaders }).toPromise();
  }
}
