// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyAn6_hV3uAu3TSUvXTVNHzrTeoBPf1AWzc",
    authDomain: "zaito-f3ed0.firebaseapp.com",
    databaseURL: "https://zaito-f3ed0.firebaseio.com",
    projectId: "zaito-f3ed0",
    storageBucket: "zaito-f3ed0.appspot.com",
    messagingSenderId: "954712433451",
    appId: "1:954712433451:web:3bf9bea402767e20829b6e",
    measurementId: "G-HZWCR3JR96"
  },
  production: true,
  // server_url: "https://usersdevs.azurewebsites.net/",
  shipping_url: "https://zaitofunctions.azurewebsites.net/api/",
  server_url: "https://userback3.azurewebsites.net/",
  shared_url: "https://sharedzaitofunctions.azurewebsites.net/api/",
  // shipping_url: " http://localhost:7071/api/", 
  shipping_code: "aRL7vjpJKDnF8dhkaqYTKs/IQOL9lG3Zz4uq94wAdF58e0D8hr8MBg==",
  shipping_code_liquid: "hhN-Z7_iH7bSeAyZuqLs4NyMyRor23JGS5-C8tyHFMqCAzFuFgPlfw==",
  checkout_url: "https://zaitocheckout.azurewebsites.net",
  GTM_ID: "GTM-WXSSNNQ9",
  Ocp: "42fee88913d34e3b8c2c5ae45847bc32",
  vendorId: "651b2ee9cb2ada6999b3efb7",
  storeId: "651dd55e6365d07de4657a4a"
};
/* export const environment = {
  firebase: {
    apiKey: "AIzaSyDbClxKIoFNzGsMh5X28u6JyS1kw80u030",
    authDomain: "zaitodevs.firebaseapp.com",
    projectId: "zaitodevs",
    storageBucket: "zaitodevs.appspot.com",
    messagingSenderId: "300738772846",
    appId: "1:300738772846:web:5e8cc92c41a82dfed88b7f",
    measurementId: "G-YJQY97WSSZ"
  },
  production: false,
  server_url: "https://usersdevs.azurewebsites.net/",
  shipping_url: "https://zaitofunctions.azurewebsites.net/api/",
  /* server_url: "http://127.0.0.1:8000/",
  // shipping_url: " http://localhost:7071/api/",
  shipping_code: "aRL7vjpJKDnF8dhkaqYTKs/IQOL9lG3Zz4uq94wAdF58e0D8hr8MBg==",
  shipping_code_liquid: "hhN-Z7_iH7bSeAyZuqLs4NyMyRor23JGS5-C8tyHFMqCAzFuFgPlfw==",
  checkout_url: "https://zaitodevscheckout.azurewebsites.net",
  GTM_ID: "GTM-WXSSNNQ9"
};
 */
/* export const environment = {
  firebase: {
    apiKey: "AIzaSyAn6_hV3uAu3TSUvXTVNHzrTeoBPf1AWzc",
    authDomain: "zaito-f3ed0.firebaseapp.com",
    databaseURL: "https://zaito-f3ed0.firebaseio.com",
    projectId: "zaito-f3ed0",
    storageBucket: "zaito-f3ed0.appspot.com",
    messagingSenderId: "954712433451",
    appId: "1:954712433451:web:3bf9bea402767e20829b6e",
    measurementId: "G-HZWCR3JR96"
  },
  production: true,
  // server_url: "https://usersdevs.azurewebsites.net/",
  shipping_url: "https://zaitofunctions.azurewebsites.net/api/",
  server_url: "https://userback3.azurewebsites.net/",
  // shipping_url: " http://localhost:7071/api/", 
  shipping_code: "aRL7vjpJKDnF8dhkaqYTKs/IQOL9lG3Zz4uq94wAdF58e0D8hr8MBg==",
  shipping_code_liquid: "hhN-Z7_iH7bSeAyZuqLs4NyMyRor23JGS5-C8tyHFMqCAzFuFgPlfw==",
  checkout_url: "https://zaitocheckout.azurewebsites.net"
}; */


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
