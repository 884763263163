import { Component } from '@angular/core';

@Component({
  selector: 'app-cellphones',
  templateUrl: './cellphones.component.html',
  styleUrls: ['./cellphones.component.scss']
})
export class CellphonesComponent {

  appStoreUrl = "https://apps.apple.com/co/app/zaito/id1569881672";
  googlePlayUrl = "https://play.google.com/store/apps/details?id=com.zaito.management&hl=es-419" 

  goToAppStore(){
    window.open(this.appStoreUrl, '_blank')
  }

  goToGooglePlay(){
    window.open(this.googlePlayUrl, '_blank')
  }
}
