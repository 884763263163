import { Injectable } from '@angular/core';
import { Auth, signOut} from '@angular/fire/auth';
import { doc, Firestore, setDoc, deleteDoc, getDoc } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { FavoriteService } from '../../favorite/favorite.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from '../../../services/pixel/pixel.service';


@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  user!: any;
  accountExist!: boolean;
  provider!: string;
  baseurl = environment.server_url;
  httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
  statusTextError = "";
  token = "";
  loggedWithFacebook = false;
  loggedWithGoogle = false;
  ObservOtpVerified =  new Subject<boolean>();
  formDigitedPhone!: string;

  constructor(private auth: Auth, 
    private firestore: Firestore, 
    private http: HttpClient, 
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private pixel: PixelService,
    ) { 
    this.auth.onAuthStateChanged((userState) =>{
      this.user = userState;
    })
  }

  logout() {
    return signOut(this.auth);
  }

  loginTrigg() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'login'
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.trackCustom('login');
  }


  async checkAccountInBack(): Promise<void> {        
    try {
      if (this.user !== null) {
        const userLogged = await this.checkIfUserExist(this.user);
        if (this.statusTextError == "Unauthorized") {
        } else {
          if (userLogged === true) {
            const isNavigateToProduct:any = localStorage.getItem("navigateToProduct");
            if (JSON.parse(isNavigateToProduct) == null) {
              this.router.navigate(['home']);
            } else {
              const redirectToProduct = JSON.parse(isNavigateToProduct);
              this.router.navigate(['home']);
              setTimeout(() => {
                this.router.navigate(['/product'], {queryParams: {id : redirectToProduct}});
                localStorage.removeItem("navigateToProduct");
              }, 300);
            }
            this.loginTrigg();
          } else {
            this.router.navigate(["/register"])
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async checkIfUserExist(user:any): Promise<boolean> {
    try {
      const id = user.id ? user.id : user.user;
      const userLogged = await this.requestBackToCheckUser(user.email, id);
      return userLogged;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async requestBackToCheckUser(email: string, id: string): Promise<boolean> {
    const body = { username: email, apple_id: id, google: id, facebook: id };
    const loginType = "loginSocial"
    return await this.postToLoginSocial(body, loginType);
  }

  loginWithPhone(otpCode: any, celphone: any): Promise<boolean> {
    const body = { celphone: celphone, code: otpCode};
    const loginType = "loginCelphone"
    return this.postToLoginSocial(body, loginType);
  }

  async postToLoginSocial(body: any, loginType: string): Promise<boolean>{
    return new Promise(async (resolve) => {
      this.http.post(this.baseurl + `usersActions/${loginType}/`, body, { headers: this.httpHeaders })
        .subscribe( {
          next: async (resp:any) => {
            this.statusTextError = "Authorized";            
            if (resp) {
              await localStorage.setItem("token", resp["token"]);
              localStorage.removeItem("carz-id");
              resolve(true);
            } else {
              this.statusTextError = "Unauthorized"
              resolve(false);
            }
          }, error: (err:any) => {
            this.statusTextError = err.statusText;
            console.log(err);
            resolve(false);
          }
      });
    });
  }

  registerUserInBack(userInformation: string) {    
    return new Promise(resolve => {
      this.http.post(this.baseurl +  "usersActions/registerSocial/", userInformation)
        .subscribe(async (resp:any) => {
          if (resp) {
            localStorage.setItem("token", resp["token"]);
            localStorage.removeItem("carz-id")
            resolve(true);
          } else {
            localStorage.removeItem("token")
            resolve(false);
          }
        });
    });
  }

  async savePhoneAndCodeFirebase(otpCode: any, phoneNumber: any){
    const userDocRef = doc(this.firestore, `UserPhoneWebPage/${phoneNumber}`)
    let data = {
      otpCode: otpCode,
      phoneNumber: phoneNumber,
    }
    await setDoc(userDocRef, data, {merge: true});
  }

  async deletePhoneAndCodeFirebase(phoneNumber: any){    
    const userDocRef = doc(this.firestore, `UserPhoneWebPage/${phoneNumber}`)
    await deleteDoc(userDocRef);
  }

  async checkFirebaseCustomCodes(code: any): Promise<any>{
    const userDocRef = doc(this.firestore, `codes/${code}`);
    return getDoc(userDocRef)
    /* const data = snapshot.data();
    return data; */
  }


}