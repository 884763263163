<ng-container *ngIf="infoLoaded; else skeletonChanged">
    <div class="col-12 flex">
        <div class="col-6">
            <p-dropdown [options]="departments" optionLabel="name" placeholder="Departamento" class="dropdown-select" [disabled]="!canSelectDep" 
                        (onChange)="selectDepartament($event)">
            </p-dropdown>
        </div>
        <div class="col-6">
            <p-dropdown [options]="citys" optionLabel="name" placeholder="Ciudad" class="dropdown-select" [disabled]="!canSelectCity" 
                        (onChange)="selectCity($event)">
            </p-dropdown>
        </div>
    </div>
    <div class="col-12 p-0 flex" style="background-color: white; align-items: center; justify-content: center;">
        <span class="p-input-icon-right container-input-search" style="width: 90%;">
            <input #search type="text" pInputText style="width: 100%;" [(ngModel)]="this.address2" 
            [ngStyle]="{'background':(this.cityId2 === 0) ?'var(--gris-zaito-2);':'white'}"
            placeholder="Dirección" [disabled] = "this.cityId2 === 0"/>
            <!-- <i class="pi pi-search" *ngIf="autocompleteInputValue.length == 0"></i>
            <i class="pi pi-times-circle cursor-pointer" *ngIf="autocompleteInputValue.length > 0" (click)="this.autocompleteInputValue = ''; this.autocompleteItems = []"></i> -->
        </span>
    </div>
    <div class ="col-12 p-0 flex" style="align-items: center; justify-content: center;margin-top: 20px;">
        <textarea pInputTextarea [(ngModel)]="description" placeholder="Escribe indicaciones de ser necesario..." 
        [style.background]="address2.length === 0 ?'var(--gris-zaito-2)':'white'"
        maxlength="100"
        style="height: 15vh;width: 90%;" [disabled] = "address2.length <= 0">
        </textarea>
    </div>
    <div class="col-10 mb-2 col-offset-1" style = "margin-top: 3vh;">
        <p-button pRipple label="Crear Dirección" 
                (onClick)="confirm()" 
                class="button-continuar" 
                [disabled]="address2.length <= 0 || description.length <= 0 " 
        >
        </p-button>
    </div> 
    <!-- <div *ngIf="showAutocompletItems" class="autocomplete-info-container">
        <div class="autocomplete-item cursor-pointer" *ngFor="let item of autocompleteItems; last as isLast" (click)="selectSearchResult(item, true, false)">
            <div class="autocomplete-value">
                <p>{{ item.description }} </p>
            </div>
            <div *ngIf="!isLast" class="line"></div>
        </div>
    </div>
    <div *ngIf="showAutocompletItemsFromCoords" class="autocomplete-info-container">
        <div class="autocomplete-item cursor-pointer" *ngFor="let item of autocompleteFromCoords; last as isLast" (click)="selectSearchResult(item, false, true)">
            <div class="autocomplete-value">
                <p>{{ item.formatted_address }} </p>
            </div>
            <div *ngIf="!isLast" class="line"></div>
        </div>
    </div> -->
</ng-container>
<ng-template #skeletonChanged>
    <app-skeleton-text [value]="6"></app-skeleton-text>
</ng-template>
<div class="map-wrapper">
    <div>
        <div #map id="map" [class.map-loaded]="false"></div>
    </div>
</div>

<p-dialog [(visible)]="showDialogFormCreateAddress" class="dialog-create-address-information" [blockScroll]="true" [modal]="false" [closable]="false" [draggable]="false"  
            (onShow)="initFormCreateAddress()" (onHide)="destroyFormCreateAddress()">
    <ng-template pTemplate="header">
        <div class="col-12 flex">
            <span class="material-symbols-rounded fill cursor-pointer font-container" (click)="showDialogFormCreateAddress = false" style="color: var(--gris-zaito-4); font-size: 1.6rem;">
                arrow_circle_left</span>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container *ngIf="true; else skeletonChanged">
            <div class="col-12 p-0">
                <div class="map-wrapper smooth-resizing" style="width: 100%; height: 33vh;">
                    <div style="width: 100%;">
                    <div #map2 id="map2" [class.map-loaded]="mapLoaded" style="height: 100%; width: 100%;"></div>
                    </div>
                </div>
            </div>
            <div class="col-12 flex">
                <div class="col-6 p-0">
                    <div>
                        <p class="m-0" style="color: black; font-family: var(--z-primary-font-family); font-weight: 600;">Ubicación</p>
                    </div>
                    <div>
                        <p class="m-2">{{locationAddress}}</p>
                    </div>
                    <div>
                        <p class="m-0" style="color: black; font-family: var(--z-primary-font-family); font-weight: 600;">Dirección</p>
                    </div>
                    <div>
                        <p class="m-2">{{streetAddress}}</p>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div>
                        <p class="m-0" style="color: black; font-family: var(--z-primary-font-family); font-weight: 600;">Información Adicional</p>
                    </div>
                    
                </div>
            </div>
        </ng-container>
    </ng-template>
    <ng-template pTemplate="footer">
        
    </ng-template>
</p-dialog>
