import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { decode } from "base64-arraybuffer";
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {

  // Cambiarlo a variables de ambiente en producción, solo para testing
  url: string;
  elementToUpload: any;
  AzFuncUrlImg = "https://sharedzaitofunctions.azurewebsites.net/api/uploadImages";
  AzFuncUrlDoc = "https://sharedzaitofunctions.azurewebsites.net/api/uploadDocs?code=SUr8Gtfjd8KFTbx7Ntb3grLDmaXdbuDG6snz4wkoPVjrAzFu7P8Knw==";

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  async HandleInput(image:any): Promise<any> {

    const extIndex = this.FindExtension(image.name);
    const ext = (image.name.substring(extIndex, image.name.length));
    const name = image.name.substring(0, extIndex);
    const blobName = new Date().getTime() + ext;

    const blob = new Blob([image], {
      type: image.type,
    });
    const fileName = `zaito.${blobName}`;
    const file = new File( [blob], fileName, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
    
    return this.index(file, true);
  }

  private FindExtension(name: string) {
    let currentIndex = 0;
    for (let i = 0; i < name.length; i++) {
      if (name[i] === ".") {
        currentIndex = i;
      }
    }
    return currentIndex;
  }

  async HandleInputFile(file: File):Promise<any> {
    return this.index(file, false);
  }

  async index (file: any, isImage:boolean) {
      return this.upload(file, isImage);
  }

  async upload(file:any, isImage:any){      
      if (file) {
        await this.AzFuncUploadFile(file, isImage).then((data:any) => {
          this.url = data.url;
        }).catch(error => {
            console.log(error);
        })     
      }
      this.elementToUpload = null;
      return this.url;
  }

  async AzFuncUploadFile(file:any, isImg:boolean) {
    
    const formData: FormData = new FormData();
    formData.append('file', file);

    if(isImg){
      return this.http.post(this.AzFuncUrlImg, formData ).toPromise()     
    }else{
      return this.http.post(this.AzFuncUrlDoc, formData ).toPromise()        
    }
  }


  previewExtractBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
      return null;
    } catch (e) {
      return null;
    }
  });

}
