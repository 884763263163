<div class = "container">
    <div class = "grid ">
        <div class = "col-12 p-3">
            <img src = "https://zaitostorage.blob.core.windows.net/logos-accesories/Sistecredito logo-svg.svg" style = "height: 50%;" >
        </div>
    </div>
    <div class = "grid">
        <div class = "col-12" 
        style = "font-family: var(--z-primary-font-family);color:black;text-align: justify;">
            Con Sistecrédito puedes comprar a cuotas con crédito fácil y seguro,
            hasta a en 4 cuotas. Las aprobaciones son en menos de un minuto.
        </div>
    </div>
    <div clasS = "grid">
        <div class = "col-12 mt-3">
            <a (click) = "sendToInfo()" class = "underlined" 
            style = "color:var(--azul-zaito-5);font-weight: 600;font-size: 1.1rem;cursor: pointer;">
                Click Aquí para más información o solicitar tu cupo Sistecrédito.
            </a>
        </div>
    </div>
    <div clasS = "grid">
        <div class = "col-12 mt-3">
            <a  style = "color:black;font-weight: 600;font-size: 1rem">
                Estos son los pasos para comprar en ZAITO con Sistecrédito:
            </a>
        </div>
    </div>
    <div clasS = "grid">
        <div class = "col-12">
            <ul class="hyphen-list" style = "color:black">
                <li>Solicita tu cupo en Sistecrédito si aún no lo tienes.</li><br>
                <li>Agrega tus productos al carrito o usa el comprar Ahora.</li><br>
                <li>Sigue los pasos del checkout.</li><br>
                <li>Al momento de ir a pagar elige Pagar con Sistecrédito</li><br>
                <li>Luego de que termines el proceso de pago con Sistecrédito se retornará a la página de confirmación de la compra con tu Id de seguimiento.</li><br>
            </ul>
        </div>
    </div>
    <div clasS = "grid">
        <div class = "col-12" style = "text-align: justify;">
            <a  style = "color:black;font-weight: 600;font-size: 1rem;">
                ¡Compra de forma segura y garantizada con Zaito para luego pagar.
                Recuerda que puedes usar la all de Sistecrédito para ver tu cupo y pagar fácilmente.!
            </a>
        </div>
    </div>
    <div clasS = "grid mt-3">
        <div class = "col-12">
            <a  style = "color:black;font-weight: 600;font-size: 1rem">
                ¿No tiene Sistecrédito?, 
                <a class = "underlined" style = "color:var(--azul-zaito-5);cursor: pointer;" (click) = "sendToInfo()" >
                    Solicítalo aqui...
                </a>
            </a>
        </div>
    </div>
</div>
