<div #bannerCookieID class="grid grid-nogutter bannerCookieGeneral">
    <div class="col-12  margin sm:col-11 md:col-11 lg:col-11 xl:col-11">
        <div class="grid grid-nogutter bannerCookieBox">
            <div class="col-10 firstColumnBanner">
                <p>Usamos cookies en nuestra página web para ver cómo interactuas con ella. Al aceptarlas, estás
                    de acuerdo con nuestro uso de dichas cookies.
                    <span class="termsAndConditions" (click)="goToTermsAndConditions()">Política de Privacidad.</span>
                    <span class="textOpenModal" (click)="showModalDialog()"> Configuración de Cookies</span>
                </p>
            </div>
            <div class="col-2 secondColumnBanner">
                <p-button class="button-cookie" label="Aceptar" (click)="aceptCookies()" *ngIf = "!isMobile"></p-button>
                <span class="material-symbols-rounded iconCookie fill font-container" (click)="closeCookies()">cancel</span>
            </div>
            <div class = "col-12 flex align-items-center" style = "flex-direction: column;" *ngIf = "isMobile">
                <p-button pRipple label="Aceptar!" class="button-modal-cookie" (click)="aceptCookies()"  >
                    <ng-template pTemplate="content">
                        <span class="p-button-label font-bold" style="width: 1.5rem">Aceptar</span>
                    </ng-template>
                </p-button>
            </div>
        </div>
    </div>
</div>


<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [dismissableMask]="true" [closable]="false" class="cookieDialog">

    <ng-template pTemplate="header">
        <div class="col-2">
        </div>
        <div class="col-8" style="display: flex; justify-content: center; align-items: center;">
            <span class="titleModal">Configuración de Cookies</span>
        </div>
        <div class="col-2">
            <span class="material-symbols-rounded iconDialogCookie fill font-container" (click)="dissmissModal()">cancel</span>
        </div>
    </ng-template>

    <div style="padding-left:20px; padding-right:20px;">
        <p style="text-align: justify;">
            Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros
            usuarios y mejorar nuestros servicios. Nos servimos de las tecnologías, incluidas las cookies, para
            recopilar información sobre usted con diversas finalidades, entre otras:
        </p>
        <p style="font-weight: 600;">Necesarias, de preferencia, estadísticas y de Marketing.</p>
        <p style="margin-bottom: 50px; text-align: justify;">        
            Al hacer clic en Confirmar, nos da su consentimiento para que recopilemos sus datos con todas estas finalidades.
            También puede especificar las finalidades para las que desea dar su consentimiento haciendo clic en la casilla
            situada junto a cada una de ellas y luego en Confirmar.
        </p>
    </div>




    <div class="grid grid-nogutter">
        <div class="col-10 col-offset-1">
            <div class="grid grid-nogutter">
                <div class="col-6">
                    <p-inputSwitch [(ngModel)]="necesaryCookie" [disabled]=true></p-inputSwitch>
                    <p style="margin-top:0; margin-bottom:0;">Necesario</p>
                </div>
                <div class="col-6">
                    <p-inputSwitch [(ngModel)]="preferenceCookies"></p-inputSwitch>
                    <p style="margin-top:0; margin-bottom:0;">Preferencias</p>
                </div>
                <!-- <div class="col-3">
                    <p-inputSwitch [(ngModel)]="staticsCookies"></p-inputSwitch>
                    <p style="margin-top:0; margin-bottom:0;">Estadísticas</p>
                </div>
                <div class="col-3">
                    <p-inputSwitch [(ngModel)]="marketingCookies"></p-inputSwitch>
                    <p style="margin-top:0; margin-bottom:0;">Marketing</p>
                </div> -->
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="grid grid-nogutter" style="gap:20px; width: 100%; display: flex; justify-content: center; align-items: center;">
            <div>
                <p-button class="button-RejectCookie" label="Rechazar" (click)="closeAndDismiss()"></p-button>
            </div>
            <div>
                <p-button class="button-modal-cookie" label="Confirmar" (click)="aceptCookies()"></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>