import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  baseurl = environment.server_url;
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });

  constructor(private http: HttpClient) { }

  private setToken() {
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }

  checkCoupon(value:string): Observable<any> {
    this.setToken();
    const body = {value}
    return this.http.post(this.baseurl +  "shop/checkCoupons/", body, { headers: this.httpHeaders });
  }


  applyCoupon(coupon: string, sell: number): Observable<any> {
    const body = {coupon, sell};
    this.setToken()
    return this.http.post<any>(this.baseurl + 'payment/applyCoupon/', body, { headers: this.httpHeaders });
  }
}
