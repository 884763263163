import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Storage } from '@capacitor/storage';
// import { Platform } from '@ionic/angular';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShopCar } from 'src/app/interfaces/shopCart';
import { Product } from 'src/app/interfaces/shop';
import { CheckoutService } from '../buys/checkout.service';
// import { Product } from 'src/interfaces/Store';
// import { ShopCar } from "src/interfaces/Utils";
// import { TokenService } from '../shared/token.service';

@Injectable({
  providedIn: 'root'
})
export class ShopCartService {

  deliveryParcelCar: BehaviorSubject<ShopCar> = new BehaviorSubject({ type: 1, sells: <any>[] });
  deliveryCommodityCar: BehaviorSubject<ShopCar> = new BehaviorSubject({ type: 1, sells: <any>[] });
  deliveryLiquidCar: BehaviorSubject<ShopCar> = new BehaviorSubject({ type: 1, sells: <any>[] });
  retreatCar: BehaviorSubject<ShopCar> = new BehaviorSubject({ type: 0, sells: <any>[] });
  dateCar: BehaviorSubject<ShopCar> = new BehaviorSubject({ type: 2, sells: <any>[] });
  $numProductsInCart: BehaviorSubject<number> = new BehaviorSubject(0);
  // products: Product[] = [];
  // currentDateCart: ShopCar;
  subsCart1!:Subscription;
  subsCart2!:Subscription;
  subsCart3!:Subscription;

  baseurl = environment.server_url;
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});

  constructor(private http: HttpClient, private checkoutService: CheckoutService) {
    // this.token = localStorage.getItem("token");
    // this.httpHeaders = new HttpHeaders({ "Content-Type": "application/json", Authorization: "Token " + this.token });
   }

   private setToken(){
    // const filter = this.garageService.getValueFilterVehiclePrincipal();
    this.token = localStorage.getItem("token");
    
    if (this.token) {
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    } else if (this.token === null) {
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }

  private setSell(productId: number, workshop: number, shopCar: ShopCar, variant?: number): void {
    const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshop);
    if (workshopIndex > -1) {
      shopCar.sells[workshopIndex].sellLines.push({ product: productId, units: 1, date: new Date() , variant});
    } else {
      shopCar.sells.push({ workshop, sellLines: [{ product: productId, units: 1, date: new Date(), variant }] });
    }
  }

  getLineStock(productId: number, workshop: number, variant?: number, weight?: number): number {
    let shopCar: ShopCar;
    if (weight && weight > 5) {
      shopCar = this.deliveryCommodityCar.getValue();
    } else {
      shopCar = this.deliveryParcelCar.getValue();
    }
    if(!this.searchInShopCar(workshop, productId, shopCar, variant)){
      return 0
    }else{
      const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshop);
      const line = shopCar.sells[workshopIndex].sellLines.find((s) => s.product === productId && s.variant === variant);
      return (line?.units ? line?.units : 0);
    }
    
  }

  adToCar(type: number, productId: number, workshop: number, weight?: number, is_liquid?: boolean, variant?: number): boolean {
    let shopCar: ShopCar;
    const noDateInCar = this.dateCar.getValue().sells.length === 0;
    const noDelyveryInCar = this.deliveryParcelCar.getValue().sells.length === 0 && this.deliveryCommodityCar.getValue().sells.length === 0;
    const onlyDatesInCar = noDelyveryInCar && this.retreatCar.getValue().sells.length === 0;
    if (type < 2 && noDateInCar) {
      switch (type) {
        case 0:
          break;
        case 1:
          if(is_liquid){
            shopCar = this.deliveryLiquidCar.getValue();
            if (!this.searchInShopCar(workshop, productId, shopCar, variant)) {
              this.setSell(productId, workshop, shopCar, variant);
              this.deliveryLiquidCar.next(shopCar);
              return true;
            } else {
              const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshop);
              const line = shopCar.sells[workshopIndex].sellLines.find((s) => s.product === productId && s.variant === variant);
              // @ts-ignore
              line.units++;
              // @ts-ignore
              line.date = new Date();
              return true;
            }
          }else{
            if (weight && weight > 5) {
              shopCar = this.deliveryCommodityCar.getValue();
            } else {
              shopCar = this.deliveryParcelCar.getValue();
            }
            if (!this.searchInShopCar(workshop, productId, shopCar, variant)) {
              this.setSell(productId, workshop, shopCar, variant);
              if (weight && weight > 5) {
                this.deliveryCommodityCar.next(shopCar);
              } else {
                this.deliveryParcelCar.next(shopCar);
              }
              return true;
            } else {
              const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshop);
              const line = shopCar.sells[workshopIndex].sellLines.find((s) => s.product === productId && s.variant === variant);
              // @ts-ignore
              line.units++;
              // @ts-ignore
              line.date = new Date();
              return true;
            }
          }
          break;
      }
    } else if (type === 2 && onlyDatesInCar) {
      shopCar = this.dateCar.getValue();
      const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshop);
      if ((workshopIndex === -1 && shopCar.sells.length === 0) || (workshopIndex === 0 && shopCar.sells.length === 1)) {
        this.setSell(productId, workshop, shopCar, variant);
        this.dateCar.next(shopCar);
        return true;
      }
    }
    return false;
  }

  private removeProduct(workshopId: number, shopCar: ShopCar, index: number) {
    const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshopId);
    if (workshopIndex > -1) {
      shopCar.sells[workshopIndex].sellLines.splice(index, 1);
      if (shopCar.sells[workshopIndex].sellLines.length === 0) {
        shopCar.sells.splice(workshopIndex, 1);
      }
    }
  }

  removeItemInCar(type: number, workshop: number, index: number, weight?: number, is_liquid?: boolean): void {
    let shopCar: ShopCar;
    if (weight && weight > 5) {
      shopCar = {...this.deliveryCommodityCar.getValue()};
      this.removeProduct(workshop, shopCar, index);
      this.deliveryCommodityCar.next(shopCar);
      const deliveryCommodity = JSON.stringify(this.deliveryCommodityCar.getValue());
      localStorage.setItem("deliveryCommodityCar", deliveryCommodity);
    } else {
      shopCar = {...this.deliveryParcelCar.getValue()};
      this.removeProduct(workshop, shopCar, index);
      this.deliveryParcelCar.next(shopCar);
      const deliveryParcel = JSON.stringify(this.deliveryParcelCar.getValue());
      localStorage.setItem("deliveryParcelCar", deliveryParcel);
    }
    this.checkoutService.sendMsg("removeProduct", {});
    this.countNumberProductsInCart();
  }
    
  private searchInShopCar(workshop: number, product: number, shopCar: ShopCar, variant?: number): boolean {
    const workshopIndex = shopCar.sells.findIndex((s) => s.workshop === workshop);
    if (workshopIndex > -1) {
      const sellIndx = shopCar.sells[workshopIndex].sellLines.findIndex((s) => s.product === product && s.variant === variant);
      return sellIndx > -1;
    }
    return false;
  }


  clearCart(): void {
    this.retreatCar.next({ type: 0, sells: [] });
    this.deliveryLiquidCar.next({ type: 1, sells: [] });
    this.deliveryParcelCar.next({ type: 1, sells: [] });
    this.deliveryCommodityCar.next({ type: 1, sells: [] });
    this.dateCar.next({ type: 2, sells: [] });
    this.$numProductsInCart.next(0);
  }


  async saveCar() {
    const deliveryParcel = JSON.stringify(this.deliveryParcelCar.getValue());
    localStorage.setItem("deliveryParcelCar", deliveryParcel);
    const deliveryCommodity = JSON.stringify(this.deliveryCommodityCar.getValue());
    localStorage.setItem("deliveryCommodityCar", deliveryCommodity);
    const retreat = JSON.stringify(this.retreatCar.getValue());
    localStorage.setItem("retreatCar", retreat);
    const deliveryLiquid = JSON.stringify(this.deliveryLiquidCar.getValue());
    localStorage.setItem("deliveryLiquidCar", deliveryLiquid);
    const date = JSON.stringify(this.dateCar.getValue());
    localStorage.setItem("dateCar", date);
    this.countNumberProductsInCart();
  }

  async loadCarKey(key: string) {
    const a:any = localStorage.getItem(key);
    let b = JSON.parse(a);
    return b;
  }

  async loadCar() {
    await this.loadCarKey("deliveryParcelCar").then((deliveryParcel:any) => {
      if (deliveryParcel) {
        this.deliveryParcelCar.next(deliveryParcel);
      }
    });
    await this.loadCarKey("deliveryCommodityCar").then((deliveryCommodity:any) => {
      if (deliveryCommodity) {
        this.deliveryCommodityCar.next(deliveryCommodity);
      }
    });
    await this.loadCarKey("retreatCar").then((retreat:any) => {
      if (retreat) {
        this.retreatCar.next(retreat);
      }
    });
    await this.loadCarKey("deliveryLiquidCar").then((deliveryLiquid:any) => {
      if (deliveryLiquid) {
        this.deliveryLiquidCar.next(deliveryLiquid);
      }
    });
    await this.loadCarKey("dateCar 5").then((date:any) => {
      if (date) {
        this.dateCar.next(date);
      }
    });
  }

  loadProducts(productIds: string): Promise<Product[]> {
    this.setToken();
    // @ts-ignore
    return this.http.get<Product[]>(this.baseurl + "market/getCheckOut/?products=" + productIds, { headers: this.httpHeaders }).toPromise();
  }

  countNumberProductsInCart() {
    let totalArticles = 0;
    this.subsCart1 = this.deliveryParcelCar.subscribe( data => {
      data.sells.forEach( sell => {
        sell.sellLines.forEach( sellLine => {
          const unitsPerProduct = sellLine.units;
          totalArticles += unitsPerProduct
        });
      });
    });
    this.subsCart2 = this.deliveryCommodityCar.subscribe( data => {
      data.sells.forEach( sell => {
        sell.sellLines.forEach( sellLine => {
          const unitsPerProduct = sellLine.units;
          totalArticles += unitsPerProduct
        });
      });
    });
    this.subsCart3 = this.deliveryLiquidCar.subscribe( data => {
      data.sells.forEach( sell => {
        sell.sellLines.forEach( sellLine => {
          const unitsPerProduct = sellLine.units;
          totalArticles += unitsPerProduct
        });
      });
    });
    // this.subsCart4 = this.retreatCar.subscribe( data => {
    //   data.sells.forEach( sell => {
    //     sell.sellLines.forEach( sellLine => {
    //       const data = {
    //         productId: sellLine.product,
    //       }
    //       const unitsPerProduct = sellLine.units;
    //       this.totalArticles += unitsPerProduct
    //
    //     });
    //   });
    // });
    // this.subsCart5 = this.dateCar.subscribe( data => {
    //   data.sells.forEach( sell => {
    //     sell.sellLines.forEach( sellLine => {
    //       const data = {
    //         productId: sellLine.product,
    //       }
    //       const unitsPerProduct = sellLine.units;
    //       this.totalArticles += unitsPerProduct
    //
    //     });
    //   });
    // });
    this.$numProductsInCart.next(totalArticles);
  }
}