<a href="{{goToProduct()}}">
    <div class = "container product mt-2 mb-2" (click) = "goToProduct()">
        <div class = "grid">
            <div class = "col-12" (mouseenter) = "mouseIn()" (mouseleave)="mouseOut()">
                <div *ngIf = "showIcon && !isMobile">
                    <span class="material-symbols-rounded fill icon favorite-icon" (click)="markFavorite();$event.stopPropagation()"
                        *ngIf="(product?.is_wished?.length > 0) && islogged || !islogged && isFavoriteForGuest" 
                        >favorite
                    </span>
                    <span class="material-symbols-rounded icon favorite-icon" (click)="markFavorite();$event.stopPropagation()"
                        *ngIf="!(product?.is_wished?.length > 0) && islogged || !islogged && !isFavoriteForGuest" 
                        >favorite
                    </span>
                </div>
                <img *ngIf="product?.productAd?.length > 0" class ="product-advertise" src="{{adImgs[product.productAd[0]?.type]}}" alt="anuncio">
                <div class = "containerimg">
                    <img src = {{product?.id_image[0]?.image_idimage}} class = "shadow-2 border-round image" alt = {{product?.name}} onerror="this.src='assets/img/shared/imagenotavailable.svg'">
                </div>
            </div>
        </div>
        <div class = "grid" >
            <div class = "col-12 ellipse pr-6 pb-1"  pTooltip="{{product.name}}" tooltipPosition = "left"
                style = "font-size: 13px;font-weight: 500;padding-top: 0.05em;color:black">
                {{product.name}}
            </div>
        </div>
        <div class = "grid offers" *ngIf = "product.productAd[0]?.type == 0">
            <div class = "col-12">
                <label style="color: var(--verde-zaito-2);color:black">Promocionado </label>
            </div>
        </div>
        <div class = "grid offers" *ngIf = "product.productAd[0]?.type == 1">
            <div class = "col-12 pt-1">
                <label style="color: var(--verde-zaito-2);">-{{product.productAd[0]?.discount}}% </label>
                <label style="color: var(--gris-zaito-4);"><del>{{originalPrice | currency: '$': 'symbol': '1.0-0'}}</del></label>
            </div>
        </div>
        <div class = "grid offers" *ngIf = "product.productAd[0]?.type == 2">
            <div class = "col-12 pt-1">
                <!-- <label style="color: var(--verde-zaito-2);">-{{product.productAd[0]?.offer | currency: '$': 'symbol': '1.0-2'}} </label> -->
                <label style="color: var(--gris-zaito-4);"><del>{{originalPrice | currency: '$': 'symbol': '1.0-0'}}</del></label>
            </div>
        </div>
        <div class = "grid">
            <div class = "col-12 pt-1" style = "font-size: 1em;font-weight: 600;color:black">
                {{completePrice| currency: '$': 'symbol': '1.0-0'}}
            </div>
        </div>
    </div>
</a>
