import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-skeleton-text",
  templateUrl: "./skeleton-text.component.html",
  styleUrls: ["./skeleton-text.component.scss"],
})
export class SkeletonTextComponent implements OnInit {

  @Input () value!: number;
  constructor() { }

  ngOnInit() {}

}
