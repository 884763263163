<div *ngIf="!modalCarz && !modalCarzChat && (garageLoaded || !islogged || !home)" class="zaibotImgContainer">
    <div class="zaibotCircle">
        <p-badge *ngIf="newMsg" size="xlarge" [ngStyle]="{'position': 'absolute', 'right': '0px', 'top': '0px'}"></p-badge>
        <a aria-label="Chat on WhatsApp" href="https://wa.me/573044210216" target="_blank">
            <img alt="Chat on WhatsApp" src="assets/img/shared/Whatsapp-logo-vetor.png" class = "zaibotImg"/>
        </a>
    </div>
</div>

<div *ngIf="modalCarz" class="modalCarzContainer">
    <div class="headerModalCarz">
        <div class="titleHeaderContainer">
            <span style="font-size: 15px; color:white; font-weight: 600;">Centro de Soluciones</span>
            <img src="assets/img/home/ZaiSupport.svg" style="margin-left: 15px; height: 30px; width:30px;" alt="Zaibot">
        </div>
        <span class="material-symbols-rounded closeModalCarz fill" (click)="dissmissModalCarz()">cancel</span>
    </div>

    <div class="contentModalCarz">
        <span style="margin-bottom: 20px; margin-top: 20px; font-weight: 600; font-size: 14px;">¿Necesitas ayuda?</span>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 20px; position:relative;">
            <div style="position: relative; width: 90%;">
                <p-badge *ngIf="newMsg" size="xlarge" styleClass="badgeButton"></p-badge>
            </div>
            <p-button class="button-modal-carz" (click)="openModalCarzChat()" label="Continuar por este medio">
                <img src="assets/img/home/ZaiSupport.svg" style="position: absolute; left: 15px; height: 25px; width:25px;" alt="Zaibot">
            </p-button>
            <a href = "https://wa.me/573044210216" target="_blank">
                <p-button class="button-modal-carz whatsapp" label="Continuar en Whatsapp" >
                    <rmx-icon style="position: absolute; left: 15px; height: 25px; width:25px;" name="whatsapp-line"></rmx-icon>
                </p-button>    
            </a>
                    
        </div>
    </div>

    <div class="footerModalCarz">
        <span class="material-symbols-outlined searchIconCarz" (click)="openModalCarzChat()">manage_search</span>
        <div (click)="openModalCarzChat()">
            <span class="hoverTextNot">
                <p class="textNotFoundWhatNeed" >No encuentras lo que necesitas?</p>
                <p class="textNotFoundWhatNeed" >Escríbenos y lo conseguimos.</p>
            </span>
        </div>
    </div>
</div>





<div *ngIf="modalCarzChat" class="modalCarzContainer">
    <div class="backdropGallery" (click)="galleryImages=false" *ngIf="galleryImages"></div>

    <div class="headerModalCarz">
        <div class="titleHeaderContainer">
            <span style="font-size: 15px; color:white; font-weight: 600;">Centro de Soluciones</span>
            <img src="assets/img/home/ZaiSupport.svg" style="margin-left: 15px; height: 30px; width:30px;" alt="Zaibot">
        </div>
        <span class="material-symbols-rounded closeModalCarz fill" (click)="dissmissModalCarzChat()">cancel</span>
    </div>

    <div *ngIf="disableChat" class="messagesNoLoaded">
        <div style="margin-bottom: 10px;">
            <p-skeleton width="58%" height="50px" [style]="{'margin-left': 'auto'}"></p-skeleton>
        </div>
        <div style="margin-bottom: 10px;">
            <p-skeleton width="58%" height="50px" ></p-skeleton>
        </div>
        <div style="margin-bottom: 10px;">
            <p-skeleton width="58%" height="50px" [style]="{'margin-left': 'auto'}"></p-skeleton>
        </div>
        <div style="margin-bottom: 10px;">
            <p-skeleton width="58%" height="50px" ></p-skeleton>
        </div>
        <div style="margin-bottom: 10px;">
            <p-skeleton width="58%" height="50px" [style]="{'margin-left': 'auto'}"></p-skeleton>
        </div>
    </div>

    <div *ngIf="!disableChat && sendedFirtsMsg" class="messagesNoLoaded">
        <div style="margin-bottom: 10px;">
            <p-skeleton width="58%" height="50px" [style]="{'margin-left': 'auto'}"></p-skeleton>
        </div>
    </div>

    <app-chat-carz *ngIf="!disableChat && !sendedFirtsMsg" [messages]="messages" (emitOpenGalleryChat)="emitOpenGalleryChat($event)"></app-chat-carz>

    <div class="footerModalCarz chat">        
        <i class="pi pi-paperclip blue-5" (click)="galleryImages=!galleryImages" style="color: var(--gris-primeng); cursor: pointer; font-size: 1.3rem;"></i>

        <div style="background-color: white; display: flex; align-items: center; width: 100%;">
            <span class="p-input-icon-right container-input-search carz" style="width: 100%; background-color: white;">
                <input [(ngModel)] = "inputMsgCarz" type="text" pInputText style="width: 100%;" 
                (keyup.enter)="sendMsg()" [disabled]="disableChat"/>
            </span>
        </div>
        <span class="material-symbols-rounded fill sendIcon" 
            (click)="sendMsg()">send</span>
    </div>
</div>

<div *ngIf="galleryImages" class="modalCarzContainer gallery">
    <div class="headerModalCarz gallery">
        <span class="material-symbols-rounded closeModalCarz fill" (click)="galleryImages=false">cancel</span>
    </div>

    <div class="contentModalCarz gallery">
        <span>Adjuntar imagen</span>
        
        <div class = "grid mt-2" style="display: flex; gap: 20px;">
            <div *ngIf="previewImage[0]" class="previewImage col-6  md:col-4 lg:col-4 xl:col-4" (click)="displayPreview=true">
                <img  [src]="previewImage[0].previewImageSrc" alt="preview">
            </div>
    
            <div class="containerImg col-4 md:col-6 lg:col-6 xl:col-6">
                <input type="file" name="image" id="image" class="upload" (change)="setImage($event)" accept = ".PNG,.JPG,.JPEG,.SVG"/>
                <label style="cursor:pointer;" for="image">
                    <span class="material-symbols-outlined" style="color: var(--gris-primeng); cursor: pointer;">add_to_photos</span>
                </label>
            </div>
        </div>


    </div>

    <div class="footerModalCarz gallery">
        <p-button pRipple *ngIf="galleryImages" [disabled]="previewImage[0]===undefined" 
        (onClick)="sendImage()" class="galleryButton" label="Enviar"></p-button>
    </div>
</div>


<p-galleria [value]="previewImage" [(visible)]="displayPreview" [containerStyle]="{'max-width': '850px'}"
    [circular]="true" [fullScreen]="true" [showItemNavigators]="false" [showThumbnails]="false" [baseZIndex]="100000">
    <ng-template pTemplate="item" let-item>
        <div style="height: 75vh;">
            <img [src]="item.previewImageSrc" style="height: 100%; display: block;" alt="preview"/>
        </div>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
            <img [src]="item.thumbnailImageSrc" style="display: block;" alt="thumbnail"/>
        </div>
    </ng-template>
</p-galleria>


<!-- Esta es la que mejor funciona -->
<p-galleria [value]="imageChat" [(visible)]="displayGalleryChat" [containerStyle]="{'max-width': '850px'}"
    [circular]="true" [fullScreen]="true" [showItemNavigators]="false" [showThumbnails]="false" [baseZIndex]="100000">
    <ng-template pTemplate="item" let-item>
        <div style="height: 75vh;">
            <img [src]="item.previewImageSrc" style="height: 100%; display: block;" alt="Preview"/>
        </div>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
            <img [src]="item.thumbnailImageSrc" style="display: block;" alt="thumbnail"/>
        </div>
    </ng-template>
</p-galleria>