import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  baseurl = environment.server_url;
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});

  constructor(private http: HttpClient) { }

  private setToken() {
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }

  getDepartament(): Promise<any> {
    this.setToken();
    return this.http.get(this.baseurl + "core/locations/", { headers: this.httpHeaders }).toPromise();
  }

  getCity(id: number): Promise<any> {
    this.setToken();
    return this.http.get(this.baseurl + "core/locations/?located_in=" + id, { headers: this.httpHeaders }).toPromise();
  }

  updateLocation(address: string, location: number, lat: number | undefined, lng: number | undefined, description: string) {
    this.setToken();
    const body =  { address: address, location: location, latitude: lat, longitude: lng, description: description   }
    return this.http.post(this.baseurl + "usersActions/user_address/", body, { headers: this.httpHeaders }).toPromise();
  }

  getInfoUser(): Observable<any>{
    this.setToken();
    return this.http.get(this.baseurl + "usersActions/UserInfo/", { headers: this.httpHeaders });
  }

  getInfoUserCarz(): Promise<any>{
    return this.http.get(this.baseurl + "usersActions/UserInfo/", { headers: this.httpHeaders }).toPromise();
  }

  patchInfoUser(id: number, name: string , nameTwo: string, idnumber: number, phonecel: number, document: string,
                dateBirth: string, userGender: string): Observable<any>{
    this.setToken();
    let body =  {};
    body = { auth_user: {first_name: name, last_name: nameTwo},
    type_id_idtype: document, numberid: idnumber, celphone: phonecel, birth_date: dateBirth, gender: userGender};
    
    return this.http.patch(this.baseurl + "usersActions/UserInfo/" + id + "/" , body ,
          { headers: this.httpHeaders });
    }

}
