import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent {


  redirect(url: string){
    window.open(url, '_system', 'location=yes');
  }
}
