<ng-container *ngIf="comesFrom == 'header'">
    <div class="flex" style="align-items: center;" (click)="openDialogAddress(currentAddress)" >
        <span class="material-symbols-rounded fill font-container" style="margin-right: 5px; color: var(--azul-zaito-5);">location_on</span>
        <div class="ellipsis-address">
            <p class="m-0">{{ currentAddress ? currentAddress : ' Ingresa Ubicación' }}</p>
        </div>
        <span class="material-symbols-rounded fill font-container" style="color: var(--azul-zaito-5);" *ngIf = "!isMobile">arrow_drop_down</span>
    </div>
</ng-container>
<ng-container *ngIf="comesFrom == 'cart'">
    <div class="col-12 flex m-0 p-0">
        <div class="col-10 m-0 p-0 flex align-items-center">
            <p class="m-0" style="font-size: 80%; color: black">{{ currentAddress ? currentAddress : ' Ingresa Ubicación' }}</p>
        </div>
        <div class="col-2 m-0 p-0">
            <span class="material-symbols-rounded fill icon-close cursor-pointer font-container"
            style="color: var(--gris-zaito-4);"
            (click)="openDialogAddress(currentAddress)">edit</span>
        </div>
    </div>
</ng-container>

<p-dialog [(visible)]="showDialogAddress" class="dialog-addresses" [blockScroll]="true" [modal]="true" [closable]="false">
    <ng-template pTemplate="header">
        <div class="col-12 flex" style="padding: 4% 6%;">
            <div class="col-9 md:col-6 lg:col-6 xl:col-6 p-0">
                <div class="title-dialogs">Dirección de Entrega</div>
            </div>
            <div class="col-3 md:col-6 lg:col-6 xl:col-6  flex p-0" style="justify-content: flex-end">
                <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="showDialogAddress = false">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container *ngIf="!showSkeletonAddress; else skeletonAddress">
            <div class="col-12 flex mb-2 address-space" *ngFor="let address of addresses" >
                <div class="col-1" (click)="addressCurrent(address.id)">
                    <p-radioButton [value]="address.current" [(ngModel)]="select"></p-radioButton>
                </div>
                <div class="col-9" (click)="addressCurrent(address.id)">
                    <div style="color: black; font-family: var(--z-primary-font-family); font-size: 95%;">{{ address.address}}</div>
                    <div style="color: var(--gris-zaito-4); font-family: var(--z-primary-font-family); font-size: 85%;">{{ address.description }}</div>
                </div>
                <div class="col-2" (click)="delete(address.id)">
                    <i class="pi pi-trash" style="font-size: 1.5rem"></i>
                </div>
            </div>
            <div class="col-12 flex" style="justify-content: center;">
                <span class="material-symbols-rounded icon fill font-container" style="color: var(--gris-zaito-4); font-size: 2rem;" (click)="openDialogCreateAddress()">add_circle</span>
            </div>
        </ng-container>
        <ng-template #skeletonAddress>
            <app-skeleton-text [value]="1"></app-skeleton-text>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="showDialogCreateAddress" class="dialog-create-addresses" [blockScroll]="true" [modal]="true" [closable]="false" [draggable]="false">
    <ng-template pTemplate="header">
        <div class="col-12 flex" style="padding: 4% 6%;">
            <div class="col-6 p-0">
                <div class="title-dialogs">Crear Dirección</div>
            </div>
            <div class="col-6 p-0 flex" style="justify-content: flex-end">
                <span class="material-symbols-rounded fill icon-dialogs font-container" (click)="showDialogCreateAddress = false">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <app-create-direction (createAddress)="emitCreateAddress()"></app-create-direction>
    </ng-template>
    <ng-template pTemplate="footer">
    </ng-template>
</p-dialog>

