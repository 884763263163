<div *ngIf="value === 1">
    <div class="col-10 col-offset-1 p-0"></div>
    <div class="col-10 col-offset-1" *ngFor="let item of [].constructor(5)">
        <p-skeleton width="100%" height="3rem"></p-skeleton>
    </div>
</div>
<div *ngIf="value === 2">
    <div class="col-12 flex" *ngFor="let item of [].constructor(5)">
        <div class="col-4">
            <p-skeleton width="100%" height="5rem"></p-skeleton>
        </div>
        <div class="col-8">
            <p-skeleton width="100%" height="2rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="100%" height="2rem"></p-skeleton>
        </div>
    </div>
</div>
<div *ngIf="value === 3" class="col-6 col-offset-3 p-0" style="height: 100%;">
    <div class="col-6 col-offset-3 p-0">
        <p-skeleton width="100%" height="14rem" styleClass="mt-4"></p-skeleton>
    </div>
</div>
<div *ngIf="value === 4">
    <div class="col-12 flex mt-4" *ngFor="let item of [].constructor(1)">
        <div class="col-4">
            <p-skeleton width="100%" height="6rem"></p-skeleton>
        </div>
        <div class="col-8">
            <p-skeleton width="100%" height="2.5rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="100%" height="3rem"></p-skeleton>
        </div>
    </div>
</div>
<div *ngIf="value === 44">
    <div class="col-12 mt-5" style="padding: 0 1rem;">
        <p-skeleton width="100%" height="6rem"></p-skeleton>
    </div>
    <div class="col-12 mt-3 mb-4" style="padding: 0 1rem;">
        <p-skeleton width="100%" height="5rem"></p-skeleton>
    </div>
</div>
<div *ngIf="value === 5" class="flex">
    <div *ngFor="let item of [].constructor(5)" class="mr-5">
        <p-skeleton height="14vh" width="8vw"></p-skeleton>
    </div>
</div>
<div *ngIf="value === 6" class="flex">
    <div class="col-12 mr-5 flex flex-wrap">
        <div class="col-6" *ngFor="let item of [].constructor(2)">
            <p-skeleton height="6vh"></p-skeleton>
        </div>
        <div class="col-12 mb-4">
            <p-skeleton height="6vh"></p-skeleton>
        </div>
        <div class="col-12" *ngFor="let item of [].constructor(5)">
            <p-skeleton height="5vh"></p-skeleton>
        </div>
    </div>
</div>
<div *ngIf="value === 7" class="flex">
    <div class="col-12 mr-5 flex flex-wrap">
        <div class="col-4" *ngFor="let item of [].constructor(18)">
            <p-skeleton height="13vh"></p-skeleton>
        </div>
    </div>
</div>