<div class="divisionLineZaito">
    <div class="blueZaito"></div>
    <div class="redZaito"></div>
</div>

<div class="grid grid-nogutter" style="margin-top: 1.7rem; margin-bottom:10px;">
    <div class="col-7 col-offset-3 moreMarginToLeft">
        <div class="grid">
            <div class="col">
                <p class="subtitleFooter">Categorías</p>
                <a *ngFor = "let cat of categories1" href="{{cat.idcategory === 0 ? 'shop': 'shop?idCategory=' + cat.idcategory}}">
                    <p style="cursor:pointer" >
                        {{cat.name}}
                    </p>
                </a>
            </div>
        
            <div class="col-12  md:col lg:col xl:col ">
                <p class="subtitleFooter">Sobre Zaito</p>
                <a href="/termsAndConditions?type=tyc">
                    <p style="cursor: pointer;" >Términos y Condiciones</p>
                </a>
                <a href="/termsAndConditions?type=data">
                    <p style="cursor: pointer;" >Política de Tratamiento de Datos</p>
                </a>
                <a href="mailto:Info@zaito.com.co" class="mailToText">Contacto Corporativo</a>
                <a class="mailToText"
                    href="mailto:Info@zaito.com.co">
                    Info@zaito.com.co</a>
                <a href = "https://www.microsoft.com/en-us/startups?rtc=1" target="_blank">
                    <img src="assets/img/shared/footer/startups_wordmark_purple.png" style="cursor: pointer; width: 60%;"
                    alt="startups wordmark purple"/>
                </a>
            </div>
        
            <div class="col-12  md:col lg:col xl:col ">
                <p class="subtitleFooter">App disponible en</p>
                <div style="display:flex; gap: 5px; flex-direction: column;">
                    <a href = "https://apps.apple.com/co/app/zaito/id1569881672" target="_blank">
                        <img src="assets/img/shared/footer/AppStoreApple.png" style="cursor: pointer; width: 45%;"
                        alt="Appstore Apple"/>
                    </a>
                    <a href = "https://play.google.com/store/apps/details?id=com.zaito.management&hl=es-419" target="_blank">
                        <img src="assets/img/shared/footer/GooglePlayShop.png" style="cursor: pointer; width: 45%;"
                        alt="Google Play Shop"/>
                    </a>
                    <img src="assets/img/shared/footer/Colombia Flag.png" style="width: 45%;"
                    alt="Colombia Flag" />
                </div>

            </div>
        
            <div class="col" style="display: flex; flex-direction: column;">
                <p class="subtitleFooter">Medios de pago</p>
                <img src ="assets/img/shared/Metodos-de-Pago-3.0.png" style = "width: 100%;">
                <!-- <img src="assets/img/shared/footer/epaycoLogo.png"
                alt="Epayco Logo" style="width: 30%; min-width: 80px;"/>
                <img src="assets/img/shared/footer/CoordinadoraLogo.png" style="width: 60%;"
                alt="Coordinadora Logo" />
                <img src = "assets/img/shared/footer/LogoSiste-04.png" class = "mt-1"  style="width: 90%;margin-left: -10%;"
                alt="Sistecredito Logo" 
                >
                <img src = "assets/img/shared/footer/LogoSiste-04.png" class = "mt-1"  style="width: 90%;margin-left: -10%;"
                alt="Sistecredito Logo" 
                >
                <img src = "assets/img/shared/footer/Addi-logo-zai.png" class = "mt-1 ml-1" 
                style="width: 40%;height: 10%;min-width: 10px;"
                alt="Addi Logo" 
                > -->
            </div>
        </div>
    </div>
</div>

<div class="grid grid-nogutter" style="margin-bottom: 3px;">
    <div class="col-4 md:col-3 lg:col-3 xl:col-3 leftNavbar">
        <a href = "https://www.facebook.com/profile.php?id=100073604613035" target="_blank">
            <rmx-icon style="margin-left:20px; cursor:pointer; color: var(--azul-zaito-5);" 
                [style.height] = "isMobile ? '35px' : '20px'" [style.width] = "isMobile ? '20px' : '20px'"
                name="facebook-fill">
            </rmx-icon>
        </a>
        <a href = "https://www.instagram.com/zaito.co/?hl=es" target="_blank">
            <rmx-icon style="cursor:pointer; color: var(--azul-zaito-5);" 
            [style.height] = "isMobile ? '35px' : '20px'" [style.width] = "isMobile ? '20px' : '20px'"
            name="instagram-fill">
            </rmx-icon>
        </a>
        <a href = "https://www.linkedin.com/company/zaito/" target="_blank">
            <rmx-icon style="cursor:pointer; color: var(--azul-zaito-5);" 
            [style.height] = "isMobile ? '35px' : '20px'" [style.width] = "isMobile ? '20px' : '20px'"
            name="linkedin-box-fill">
            </rmx-icon>
        </a>
        <a href = "https://www.youtube.com/channel/UC1pMDvgIcfrXmg2qHtrca3g" target="_blank">
            <rmx-icon style="cursor:pointer; color: var(--azul-zaito-5);" 
            [style.height] = "isMobile ? '35px' : '20px'" [style.width] = "isMobile ? '20px' : '20px'"
            name="youtube-fill">
            </rmx-icon>
        </a>
        <a href="https://www.tiktok.com/@zaito572?_t=8fr1VOo1nC1&_r=1" target="_blank" class="no-highlight">
            <img src = "../../../../assets/img/shared/Tiktok_Zai.svg" style = "height: 1.5em;min-width: 10px;">
        </a>
    </div>

    <div class="col-5 md:col-6 lg:col-6 xl:col-6 centerNavbar">
        <img src="assets/img/shared/footer/ZaitoCircle.svg" [style.height] = "isMobile ? '40px' : '50px'"
        style="cursor: pointer;"
        alt="Zaito Logo" (click)="gotoTop()"/>
    </div>

    <div class="col-3 rightNavbar">
        <p class="reservedRightText">
            <rmx-icon style="height: 13px; width:13px;" name="creative-commons-line"></rmx-icon>
            <span>2024 Zaito SAS. All rights reserved.</span>
        </p>
    </div>
</div>