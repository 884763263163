import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  baseurl = environment.server_url;
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});

  $updateAddress = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  private setToken() {
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }

  getLocation(): Promise<any> {
    this.setToken();
    return this.http.get(this.baseurl + "usersActions/user_address/", { headers: this.httpHeaders }).toPromise();
  }

  currentLocation(id: number, current: boolean ) {
    this.setToken();
    const body =  { current: current }
    return this.http.patch(this.baseurl + "usersActions/user_address/"+ id + "/", body, { headers: this.httpHeaders }).toPromise();
  }

  deleteLocation(id):Observable<any>{
    this.setToken();
    return this.http.delete(this.baseurl + "usersActions/user_address/" + id + "/", { headers: this.httpHeaders });
  }
}
