import { Injectable } from '@angular/core';
import { MessageService } from "primeng/api";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  toastError: BehaviorSubject<string> = new BehaviorSubject("");
  toastSuccess: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private messageService: MessageService) { }

  setMsgSuccess(msg: string) {
    this.messageService.add({
      severity: "success",
      summary: "Éxito",
      detail: msg,
      life: 6000
    });
  } 

  setMsgError(msg: string) {
    this.messageService.add({
        severity: "error",
        summary: "Error!",
        detail: msg,
        life: 6000
    });
  }
  
}