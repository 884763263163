import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { DatesService } from 'src/app/services/dates/dates.service';

@Component({
  selector: 'app-calendar-product-card',
  templateUrl: './calendar-product-card.component.html',
  styleUrls: ['./calendar-product-card.component.scss']
})
export class CalendarProductCardComponent {

  @Input() product: any;
  @Input() i: any;
  @Input() variant: any;

  @ViewChild('calendar') calendar:any;

  @Output() emitDate = new EventEmitter<{product: any, day: Date, index: number, variant: number}>();
  

  dates:any[] = [];
  schedules: any[] = [];

  selectedDay;

  selectedHour;

  selectedHourindex;

  minDate;
  maxDate;

  firstTIme = true;


  loadingDates = false;


  constructor(private dateService: DatesService, private config: PrimeNGConfig,) {
    this.config.setTranslation( {
      dayNames: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      dayNamesShort: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      dayNamesMin: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
          'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      today: 'Hoy',
      clear: 'Limpiar',
    });
    const lastDate = new Date();
    const minDate = new Date();
    lastDate.setMonth(lastDate.getMonth() + 1);
    this.maxDate = lastDate;
    this.minDate = minDate;
  }


  async getHours(event, productId){
    this.loadingDates = true;
    this.firstTIme = false;
    this.selectedHourindex = null;
    const date = this.checkDateAndHour(event);
    const response = await lastValueFrom(this.dateService.postSchedule(event,productId))
    this.schedules = response.free_hours;
    this.loadingDates = false;
  }

  checkHours(event, index){
    if (this.selectedHourindex === null){
      this.selectedDay = null
    }
  }

  selectHour(schedule){
    this.selectedDay = new Date(schedule);
    this.emitDate.emit({product: this.product, day: this.selectedDay, index: this.i, variant: this.variant})
    this.calendar.toggle()
  }

  private checkDateAndHour(dateSelected): Date {
    const date = new Date(dateSelected);
    const today = new Date();
    const isSameDay = date.toISOString().split("T")[0] === today.toISOString().split("T")[0];
    const delayedHour = 0;
    if (isSameDay) {
      today.setHours(today.getHours() + delayedHour);
      return today;
    } else {
      date.setHours(date.getHours() - 11);
      return date;
    }
  }


  getTimeString(date: Date): string {
    if(date){
      const hours = date.getHours();
      const minutes = date.getMinutes();
    
      // Convert hours and minutes to two-digit format
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    
      return `${formattedHours}:${formattedMinutes}`;
    }else{
      return "";
    }
  }

}
