import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/shared/account/account.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { AddressService } from 'src/app/services/user/address/address.service';
import {ConfirmationService} from 'primeng/api';
import { UserprofileService } from 'src/app/services/userProfile/userprofile.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {

  @Input() comesFrom = "";

  addresses!: any[];
  disableIonRadio:boolean = false;
  currentAddress:string = "";
  showDialogAddress:boolean = false;
  showDialogCreateAddress:boolean = false;
  select!:any
  showSkeletonAddress:boolean = false;
  isMobile = false;
  showDeleteDialog = false;

  islogged:boolean = false;
  @Output() changeOrAddAddress = new EventEmitter<boolean>(false);
  subsUpdateAddress: Subscription;

  constructor(private addressService: AddressService,
              private accountInfo: AccountService,
              private router: Router,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private generalConfigurationService: GeneralConfigurationService,) {}

  async ngOnInit() { 
    this.islogged = await this.accountInfo.isLogged();
    if (this.islogged) {
      this.getSubscriptions();
      this.getUserAddress();
    }
    this.isMobile = this.generalConfigurationService.isMobile;
  }

  ngOnDestroy() {
    if (this.subsUpdateAddress) {
      this.subsUpdateAddress.unsubscribe();
    }
  }

  getSubscriptions() {
    this.subsUpdateAddress =  this.addressService.$updateAddress.subscribe( data=> {
      if (data) {
         this.getUserAddress();
         this.addressService.$updateAddress.next(false);
      }
    });
  }

  private async getUserAddress(): Promise<void> {
    await this.addressService.getLocation().then( async resp => {
      this.addresses = resp;
      this.disableIonRadio = false;
      if (this.addresses.length > 0) {
        const currentAddress = resp.find((data: any) => data.current === true);    
        if (currentAddress) {
          this.currentAddress = currentAddress.address;
          this.select = currentAddress.current;
        } else {
          await this.addressCurrent(this.addresses[0].id);
        }
      }
      this.showSkeletonAddress = false;
    }).catch(error => {
    });
  }

  async addressCurrent(id: number): Promise<void> {
    this.showSkeletonAddress = true;
    if (!this.disableIonRadio) {
      this.disableIonRadio = true;
      await this.addressService.currentLocation(id, true).then( async data => {
        await this.getUserAddress();
        this.changeOrAddAddress.emit(true);
        this.addressService.$updateAddress.next(true);
      }).catch( error => {
      });
    }
  }

  openDialogAddress(currentAddress:any) {
    if (this.islogged) {
      if (currentAddress) {
        this.showDialogAddress = true;
      } else {
        this.showDialogCreateAddress = true;
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  openDialogCreateAddress() {
    this.showDialogCreateAddress = true;
  }

  emitCreateAddress() {
    this.showDialogCreateAddress = false;
    this.showSkeletonAddress = true;
    this.getUserAddress();
    if (!this.showDialogAddress) {
      this.showDialogAddress = true;
    }
    this.changeOrAddAddress.emit(true);
    this.addressService.$updateAddress.next(true);
  }

  delete(id){
    this.confirmationService.confirm({
      message: "¿Deseas eliminar esta dirección?",
      accept: () => {
        this.addressService.deleteLocation(id).subscribe(() => {
          this.emitCreateAddress();
          this.messageService.add({severity:'success', summary: 'Los cambios han sido aplicados con éxito!', life: 3000});
        }, error =>{
          const canNotError = error.status === 500 && error.ok === false && error.statusText === "Internal Server Error";
          const canNotErrorConflict = error.status === 409 && error.statusText === "Conflict";
          if(canNotErrorConflict) {
            // this.openModalInfoDeletedCar(vehicle);
            console.log("error");
          } else if (canNotError) {
            // this.toastErrorConnection();
          }
        })
      },
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
      rejectButtonStyleClass: "botton-reject-confirm"
   });
  }
  

}
