import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  baseurl = environment.server_url;
  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});

  constructor(private http: HttpClient) { }

  private setToken() {
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }

  getFavorites(page:number): Promise<any> {
    this.setToken();
    return this.http.get(this.baseurl + "market/wishes/?page=" + page, { headers: this.httpHeaders }).toPromise();
  }

  markAsFavorite(id: number) { 
    this.setToken();
    const body = {product: id};
    return this.http.post(this.baseurl + "market/wishes/", body, {headers: this.httpHeaders})
  }

  deleteFavorite(id: number) {
    this.setToken();
    return this.http.delete(this.baseurl + "market/wishes/" + id + "/", {headers: this.httpHeaders})
  }

  getFavoritesForGuest(ids: number[]) {
    const body = {ids};
    return this.http.post(this.baseurl + "market/get_products_guest/", body, {headers: this.httpHeaders});
  }
}
