import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor() { }

  async isLogged() {
    const token = await localStorage.getItem("token");
    return token? true : false;
  }
}
