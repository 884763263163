import { Component } from '@angular/core';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';

@Component({
  selector: 'app-sistecred-info',
  templateUrl: './sistecred-info.component.html',
  styleUrls: ['./sistecred-info.component.scss']
})
export class SistecredInfoComponent {

  isMobile = false;

  constructor(private generalConfigurationService: GeneralConfigurationService) { }

  async ngOnInit() {
    this.isMobile = this.generalConfigurationService.isMobile;
    
  }

  sendToInfo(){
    if (this.isMobile){
      window.open("https://api.whatsapp.com/send?phone=573208899898", '_blank');
    }else{
      window.open("https://www.sistecredito.com/qrtiendas.html", '_blank');
    }
  }

}
