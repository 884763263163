<div class = "container">
    <div class = "grid  mt-2">
        <div class = "col-12 pl-3 pb-5 pr-3" style = "padding-top: 0;">
            <img src = "../../../../assets/img/shared/Zaito-logo-shield.png" style = "height: 100%;" >
            <!-- <img src = "../../../../assets/img/shared/Logo-Zaito-Shield.svg" style = "height: 15%;"> -->
        </div>
    </div>
    <div class = "grid">
        <div class = "col-2 sm:col-12 md:col-12 lg:col-1 xl:col-1 flex justify-content-center" style = "align-items: flex-start;">
            <img src = "../../../../assets/img/shared/sync-circle.svg" style = "width: 1.8em;">
        </div>
        <div class = "col">
            <div class = "grid">
                <div class = "col-12">
                    <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)">
                        Retractos y Devoluciones Gratis!
                    </p>
                </div>
            </div>
            <div class = "grid">
                <div class = "col-12" style = "padding-top: 0;">
                    <p style = "color:var(--gris-zaito-5);font-size: 1em;font-weight: 500;">
                        Hasta 5 Días luego de que recibas tu compra.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class = "grid">
        <div class = "col-2 sm:col-12 md:col-12 lg:col-1 xl:col-1 flex justify-content-center" style = "align-items: flex-start;">
            <img src = "../../../../assets/img/shared/navigate-circle.svg"  style = "width: 1.8em;">
        </div>
        <div class = "col">
            <div class = "grid">
                <div class = "col-12">
                    <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5);display: flex;align-items: flex-end;">
                        Enviamos con <img src = "../../../assets/img/shared/footer/CoordinadoraLogo.png" style = "height: 1.1em;">
                    </p>
                </div>
            </div>
            <div class = "grid">
                <div class = "col-12" style = "padding-top: 0;">
                    <p style = "color:var(--gris-zaito-5);font-size: 1em;font-weight: 500;">
                        Despachamos el mismo día o al siguiente
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class = "grid">
        <div class = "col-2 sm:col-12 md:col-12 lg:col-1 xl:col-1  flex justify-content-center"  style = "align-items: flex-start;">
            <img src = "../../../../assets/img/shared/card.svg"   style = "width: 1.8em;">
        </div>
        <div class = "col-10 sm:col-10 md:col-10 lg:col-11 xl:col-11">
            <div class = "grid">
                <div class = "col-12">
                    <p style = "font-size: 1em;font-weight: 500;color:var(--azul-zaito-5)"  >
                        Medios de Pago
                    </p>
                </div>
            </div>
            <div class = "grid">
                <div class = "col-12 flex align-items-center" style = "padding-top: 0;">
                    <img src = "../../../../assets/img/shared/Metodos-de-Pago-3.0.png" style = "height: 2.8em;">
                </div>
            </div>
        </div>
    </div>
    <div class = "grid mt-3">
        <div class = "col-12" style = "font-weight: 600;color:black;font-family: var(--z-primary-font-family);">
            Todos nuestros productos son 100% originales, somos distribuidores oficiales de todas las Marcas que se 
            comercializan dentro de nuestro E-commerce. Realizamos Facturación Electrónica.
        </div>
    </div>
    <div class = "grid pt-2 pb-2" >
        <div class = "col-12">
            <img src = "../../../../assets/img/shared/ZaiDistribuidoresOf.png" style = "width: 90%;">
        </div>
    </div>
    <div class = "grid mt-2">
        <div class = "col-12" style = "font-weight: 600;font-family: var(--z-primary-font-family);color: var(--azul-zaito-5);font-size: 1.2em;">
            Conoce más sobre ZAITO...
        </div>
        <div class = "col-12" style = "font-weight: 600;font-family: var(--z-primary-font-family);color: black">
            Nuestra App
        </div>
        <div class = "col-11 sm:col-11 md:col-11 lg:col-9 xl:col-9 flex justify-content-evenly ml-2">
            <div class = "grid">
                <div class = "col">
                    <a href = "https://apps.apple.com/co/app/zaito/id1569881672" target="_blank">
                        <img src = "../../../../assets/img/shared/footer/AppStoreApple.png" 
                        style = "cursor: pointer;">
                    </a>
                </div>
                <div class = "col">
                    <a href = "https://play.google.com/store/apps/details?id=com.zaito.management&hl=es_419" target="_blank">
                        <img src = "../../../../assets/img/shared/footer/GooglePlayShop.png"
                        style = "cursor: pointer;">
                    </a>
                </div>
                <div class = "col">
                    <a href = "https://www.zaito.co" target="_blank">
                        <img src = "../../../../assets/img/shared/wwwzaitoco.png"
                        style = "cursor: pointer;">
                    </a>
                </div>
            </div>
            
           
            
            
            
        </div>
        <div class = "col-12" style = "font-weight: 600;font-family: var(--z-primary-font-family);color: black">
            Redes Sociales
        </div>
        <div class = "col-11 sm:col-11 md:col-11 lg:col-10 xl:col-10 flex justify-content-around">
            <a href="https://www.instagram.com/zaito.co/" target="_blank" style = "outline: none;">
                <i class="pi pi-instagram socialMedia"></i>
            </a>
            <a href="https://www.facebook.com/people/Zaito/100073604613035/" target="_blank">
                <i class="pi pi-facebook socialMedia"></i>
            </a>
            <a href="https://wa.me/573044210216" target="_blank" class="no-highlight">
                <i class="pi pi-whatsapp socialMedia"></i>
            </a>
            <a href="https://www.tiktok.com/@zaito572?_t=8fr1VOo1nC1&_r=1" target="_blank" class="no-highlight">
               <img src = "../../../../assets/img/shared/Tiktok_Zai.svg" style = "height: 2em;">
            </a>
            <a href="https://www.youtube.com/channel/UC1pMDvgIcfrXmg2qHtrca3g" target="_blank" >
                <i class="pi pi-youtube socialMedia"></i>
            </a>
            <a href="https://www.linkedin.com/company/71469273" target="_blank">
                <i class="pi pi-linkedin socialMedia"></i>
            </a>
        </div>
        <div class = "col-12" style = "font-weight: 600;font-family: var(--z-primary-font-family);color: black">
            Tienda Oficial en Mercado Libre
        </div>
        <div class = "col-12 ml-2">
            <a href = "https://www.mercadolibre.com.co/tienda/zaito " target="_blank">
                <img src = "../../../../assets/img/shared/MLpanelImg.png" 
                style = "height: 3.5em;cursor: pointer;">
            </a>
            
        </div>
    </div>
</div>