<div style="z-index: -10;">
    <div style="width: 100%; height: 50vh; background-color: var(--azul-zaito-5)">

        <div style="height: 25%;">
            <img src="assets/img/cellphones/logozaitohwhite.svg" 
            class="logoImg" alt="Logo">
        </div>

        <div style="height: 45%;">
            <h2 style="margin-block-end: 0em; margin-block-start: 0;">Soluciones para</h2>
            <h2 style="margin-block-start: 0em;">Motos y Motociclistas</h2>
        </div>

        <img src="assets/img/cellphones/MotoroadCategorys.png" 
        class="motoImg" alt="Categorias Moto">
        

        <div class="containerCircle">
            <div class="semiCircle"></div>
        </div>

    </div>

    <div style="width: 100%; height: 50vh;">
                
        <div class="containerCircle down">
            <div class="semiCircle down"></div>
        </div>

        <div style="height:18%;">
            <p style="margin-block-end: 0em; margin-block-start: 0em;">Para dispositivos móviles</p>
            <p style="margin-block-start: 0em;">Descarga la app:</p>
        </div>

        <div class="ContainerImgs">
            <img src="assets/img/shared/footer/AppStoreApple.png" style="cursor: pointer; width: 40%;"
            alt="Appstore Apple" (click)="goToAppStore()"/>
            <img src="assets/img/shared/footer/GooglePlayShop.png" style="cursor: pointer; width: 40%;"
            alt="Playstore" (click)="goToGooglePlay()"/>
        </div>
    </div>
</div>