import { Component, OnInit, Input } from '@angular/core';
import { onSnapshot} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Subscription} from 'rxjs';
import { UploadImageService } from 'src/app/services/azure-blob-storage/upload-image.service';
import { CarzService } from 'src/app/services/carz/carz.service';
import { GarageService } from 'src/app/services/garage/garage.service';
import { AccountService } from 'src/app/services/shared/account/account.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { ShopService } from 'src/app/services/shop/shop.service';

@Component({
  selector: 'app-support-carz',
  templateUrl: './support-carz.component.html',
  styleUrls: ['./support-carz.component.scss']
})
export class SupportCarzComponent implements OnInit {

  modalCarz= false;
  modalCarzChat = false;
  subscriptionModalOpen!: Subscription;
  susbscriptionGarageLoaded!: Subscription;
  garageLoaded = false;
  islogged:boolean = false;
  linkWhatsapp= "";
  @Input() home = false;
  
  chatExist:boolean = false;
  galleryImages:boolean = false;
  image!:any;
  previewImage: any[] = [];
  displayPreview:boolean = false;
  messages!: any;
  sendedFirtsMsg = false;

  inputMsgCarz = ""
  subscriptionListening !: Subscription;
  subscriptionBadge!: Subscription;
  newMsg = false;
  disableChat = true;

  displayGalleryChat:boolean = false;
  imageChat: any[] = [];

  currentRoute: string;

  isMobile = false;

  constructor(private carzService: CarzService,
              private garageService: GarageService,
              private accountInfo: AccountService,
              private uploadService: UploadImageService,
              private generalConfigurationService: GeneralConfigurationService,
              private shopService: ShopService,
              private router: Router){}


  async ngOnInit() {
    this.isMobile = this.generalConfigurationService.isMobile;
    this.islogged = await this.accountInfo.isLogged();
    this.susbscriptionGarageLoaded = this.garageService.garageLoaded.subscribe(async (res) => {
      this.garageLoaded = res;
      if(res){
        this.newMsg = false;
        this.disableChat = true;
        this.messages = [];
        await this.validateChatExist();
        await this.updateBadgeMsgs();                
      }
    });
    
    this.subscriptionModalOpen = this.carzService.ObserModalOpen.subscribe(async (res) =>{
      if(res.modalChat.isProduct){
        window.open("https://wa.me/573044210216?text=" + res.modalChat.text, '_blank')
      }else{
        window.open("https://wa.me/573044210216", "_blank")
      }
      /* this.modalCarz = res.modal;
      this.modalCarzChat = res.modalChat.active;
      this.inputMsgCarz = res.modalChat.text; */
    });
    
    if(!this.home || !this.islogged){
      await this.validateChatExist();
      await this.updateBadgeMsgs();      
    }

    this.currentRoute = this.router.url;
  }

  ngOnDestroy(){    
    if(this.subscriptionModalOpen) this.subscriptionModalOpen.unsubscribe();
    if(this.susbscriptionGarageLoaded)this.susbscriptionGarageLoaded.unsubscribe(); 
    if(this.subscriptionListening) this.subscriptionListening.unsubscribe();
    if(this.subscriptionBadge) this.subscriptionBadge.unsubscribe();
  }

  async updateBadgeMsgs() {
    await this.carzService.ThereIsNewMsg()
      .then((q) => {        
        if (q !== 0){
          const unsubscribe = onSnapshot(q, (snapshot: any) => {
            try {
              const newUserMsg = (snapshot.data()!['noreaded_user']);
              this.newMsg = (newUserMsg > 0) ? true : false;
            } catch {
              this.newMsg = false;
            }
            this.subscriptionListening = this.carzService.removeListenMsgs.subscribe((res) => {
              if (res) {
                unsubscribe();
              }
            });
          });
        }

      });
  }

  openModalCarz(){
    this.modalCarz = true;
    const objectOpenModal = {
      modal:true, 
      modalChat: {
        active:false, 
        text: "",
        isProduct: false
      }
    }
    this.carzService.ObserModalOpen.next(objectOpenModal)
  }

  dissmissModalCarz(){
    this.modalCarz = false;
    this.galleryImages = false;
  }

  openModalCarzChat(){
    this.modalCarz = false;
    this.modalCarzChat = true;
  }

  dissmissModalCarzChat(){
    this.modalCarzChat = false;
    this.galleryImages = false;
  }

  async validateChatExist() {
    
    await this.carzService.checkIfChatsExist()
    .then((chats:number) => {
      this.chatExist = chats > 0 ? true : false;      
      if (this.chatExist) {
        this.getMessagesInfo();        
      } else {        
        this.messages = [];
        this.disableChat = false;
      }
    }, error => {
      console.error(error)
    });
  }

  async getMessagesInfo() {
    try {
    await this.carzService.getChatsCarz()
    .then((q) =>{
      if(q !== 0){
        const unsubscribe = onSnapshot(q, (querySnapshot:any) => {
          this.messages = [];
          querySnapshot.forEach((doc:any) => {
            this.messages.push(doc.data());
          });
          const filter = this.garageService.getValueFilterVehiclePrincipal();
          for (let i = 0; i<this.messages.length; i++) {
            if (this.messages[i].product != 0) {
              this.shopService.getProduct(this.messages[i].product, filter).then(data => {
                this.messages[i]['name'] = data.name;
                const index = data.id_image.findIndex( (d: any) => d.principal == true)
                this.messages[i]['imageProduct'] = data.id_image[index].image_idimage.url
              }).catch((error) => {
                console.log(error);
              })
            }
          }
          this.disableChat = false;
          this.subscriptionListening = this.carzService.removeListenMsgs.subscribe((res)=>{
            if(res){
              unsubscribe();
            } 
          });
        });
      }
    });
    } catch (error) {
      console.log(error);
    }
  }


  async sendMsg(){
    const msg = this.inputMsgCarz;
    this.inputMsgCarz = "";
    if(msg != ""){
      await this.validateBeforeSend(msg, null);
    }
  }
  
  setImage(event:any){
    this.image = event.target.files[0];
    this.uploadService.previewExtractBase64(this.image).then((image:any) =>{
      this.previewImage.pop();
      this.previewImage.push({
        "previewImageSrc": image.base,
        "thumbnailImageSrc": image.base,
        "alt": "preview",
        "title": "preview"
      });
    });
  }

  async sendImage(){
    this.disableChat = true;
    this.previewImage.pop();
    this.galleryImages = false;
    if (this.image){
      const url = await this.uploadService.HandleInput(this.image);
      this.validateBeforeSend("", url);
    }
  }
  

  private async validateBeforeSend(msg: string, url:any) {
    if (this.chatExist) {
      await this.carzService.sendMessage(msg, url);
    } else {
      this.sendedFirtsMsg = true;
      await this.carzService.createChatRoom();
      await this.carzService.sendMessage(msg, url);
      this.chatExist = true;
      this.getMessagesInfo();
      this.updateBadgeMsgs();
      this.sendedFirtsMsg = false;
    }
  }

  secondsToHms(d:any) {
    const date = new Date(d * 1000);
    return date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  }

  goToAppStore(){
    window.open(this.linkWhatsapp, '_blank')
  }

  emitOpenGalleryChat($event:any){
    this.imageChat.pop();
    this.imageChat.push({
      "previewImageSrc": $event,
      "thumbnailImageSrc": $event,
      "alt": "image chat",
      "title": "image chat"
    });    
    this.displayGalleryChat = true;
  }

}
