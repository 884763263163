import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthUserService } from 'src/app/services/auth/login-register/auth-user.service';
import { Router } from '@angular/router';
import { getAuth, RecaptchaVerifier} from 'firebase/auth';
import { Subscription } from "rxjs";
import { UserprofileService } from 'src/app/services/userProfile/userprofile.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  genders = [
    { text : "Hombre", value : "M"},
    { text : "Mujer", value : "W"},
    { text : "Otro", value : "O"},
  ];

  form!: FormGroup;
  displayModal!: boolean;
  trySendCodeAgain = false;
  reCaptchaVerifier: any;
  subscriptionOtpVerified!: Subscription;
  otpVerified = false;
  changedForm:boolean = false;

  id!: number;
  firstName: string = "";
  lastName: string = "";
  numberid!: number;
  email: string = "";
  phoneCel!: number;
  birth: string = "";
  userChangeBirth = false;
  gender: string = "-";
  genderSend: string = "";
  document: string = "";
  initialBirth!: string;

  isMobile = false;


  constructor(private formBuilder: FormBuilder,
              private authUser: AuthUserService,
              private router: Router,
              private profile: UserprofileService,
              private messageService: MessageService,
              private generalConfigurationService: GeneralConfigurationService,
              private config: PrimeNGConfig,
              ) { 

                this.buildForm();
                this.config.setTranslation( {
                  dayNames: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
                  dayNamesShort: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
                  dayNamesMin: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
                  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
                      'Noviembre', 'Diciembre'],
                  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                  today: 'Hoy',
                  clear: 'Limpiar',
                });
              }

  async ngOnInit() {
    this.form.disable()
    this.isMobile = this.generalConfigurationService.isMobile;
    await this.profile.getInfoUser().subscribe( data => {
      this.form.enable();
      this.id = data[0].id;
      this.firstName =  data[0].auth_user.first_name;
      this.lastName = data[0].auth_user.last_name;
      this.email = data[0].auth_user.username;
      this.phoneCel = parseInt(data[0]?.celphone.replace("+57","")) ;
      this.birth = data[0].birth_date;
      this.genderSend = data[0].gender;
      if (data[0].gender === "M"){
        this.gender = "Hombre";
      } else if (data[0].gender === "W") {
        this.gender = "Mujer";
      } else if (data[0].gender === "O") {
        this.gender = "Otro";
      } else {
        this.gender = "-";
      }

      this.feedForm();

      this.form.valueChanges.subscribe(x => {
        this.changedForm = true;
      });

      this.form?.get("birthForm")?.valueChanges.subscribe(x => {
        // console.log(this.birth);
        this.userChangeBirth = true;
      })
    }, error => console.log(error));    

    const auth = getAuth();

    this.reCaptchaVerifier = new RecaptchaVerifier('sign-in-button-register', {
      'size': 'invisible',
      'expired-callback': () => {
      }
    }, auth);
    this.subscriptionOtpVerified = this.authUser.ObservOtpVerified.subscribe((res) => {
      this.otpVerified = res;
      if(res === true){
        this.phoneCel = parseInt(this.authUser?.formDigitedPhone?.replace("+57","").replace(" ", ""));
        this.form.patchValue({phoneCelForm: this.phoneCel});
      }
    });
    this.otpVerified = true;
  }

  ngOnDestroy(): void {
    if (this.subscriptionOtpVerified) {
      this.subscriptionOtpVerified.unsubscribe();
    }
  } 

  private buildForm() {
    this.form = this.formBuilder.group({
      firstNameForm: ["",[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(30)]],
      lastNameForm: ["",[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(150)]],
      emailForm: ["",[
        Validators.required,
        Validators.email,
        Validators.maxLength(254)]],
      phoneCelForm: [""],
      birthForm: [""],
      genderSend: [""],
    });
  }

  private feedForm(): void {
    this.form.patchValue({
      firstNameForm: this.firstName,
      lastNameForm: this.lastName,
      emailForm: this.email,
      phoneCelForm: this.phoneCel,
      birthForm: this.birth,
      genderSend: this.genderSend
    });
  }

  private takeFormInfo(): void {
    this.firstName =this.form?.get("firstNameForm")?.value;
    this.lastName = this.form?.get("lastNameForm")?.value;
    this.email = this.form?.get("emailForm")?.value;
    // si no ha sido cambiada la fecha, siendo birth!= null, entonces no hay que hacer la expresión regular.
    if(this.userChangeBirth){
      this.birth = this.form?.get("birthForm")?.value.toLocaleDateString("ko-KR").replace(/\./g,"-").replace(/ /g, "");
      this.birth = this.birth.substring(0, this.birth.length - 1);
    }

    // console.log(this.birth);
      
    this.genderSend = this.form?.get("genderSend")?.value;
  }  

  async onSubmit(): Promise<void> {
    this.takeFormInfo();
    // console.log(this.id, this.firstName, this.lastName, this.numberid, this.phoneCel, this.document, this.birth, this.genderSend);
    
    this.profile.patchInfoUser(this.id, this.firstName, this.lastName, this.numberid,
                              this.phoneCel, this.document, this.birth, this.genderSend)
                                  .subscribe( data => {
                                    this.messageService.add({ 
                                      severity: 'success', summary: 'Cambios exitosos', 
                                      detail: 'Los cambios han sido aplicados con éxito!' 
                                    });
                                    setTimeout(()=> this.router.navigate(['/home']), 700)
                                  }, error => {
                                    this.messageService.add({
                                      severity: 'error', summary: 'Error',
                                      detail: 'Hubo un error guardando los cambios.'
                                    });
                                    console.log(error);
                                  });
  }


  navigateLogin() {
    this.router.navigate(["/login"]);
  }

}
