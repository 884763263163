import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoriesService } from './categories.service';
import { GarageService } from '../garage/garage.service';

export interface ProductFilter {
  order?: "" | "-";
  orderBy?: string;
  visible?: 0 | 1 | 2;
  isService?: 0 | 1 | 2;
  categories?: string;
  sell_type?: string;
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  baseurl = environment.server_url;

  token = localStorage.getItem("token");
  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});

  ids = [];

  readonly pagenumber = 20;

  selectedSort: any = undefined;
  
  private trigger= new Subject<boolean>

  private loading = new Subject<boolean>

  selectedCat: any;

  search!: string;

  private order: any;

  private pricesrange: any;

  private promo: any;
  $loading = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient, private categoryService: CategoriesService, private garageService: GarageService) {
    this.trigger.next(false)
    this.loading.next(false);
   }
  
  private setToken(){
    const filter = this.garageService.getValueFilterVehiclePrincipal();
    this.token = localStorage.getItem("token");
    if (this.token && filter){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null || !filter){
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
  }

  setPromo(promo: number){
    this.promo = promo;
  }

  async activeTrigger(active: boolean){
    this.trigger.next(active);
  }

  getTrigger(){
    return this.trigger.asObservable();
  }

  async activeLoading(loading: boolean){
    this.loading.next(loading);
  }

  getLoading(){
    return this.loading.asObservable();
  }


  setOrder(order: any){
    this.order = order
  }

  get Order(){
    return this.order;
  }

  setpriceRange(range: any){
    this.pricesrange = range;

  }

  get prices(){
    return this.pricesrange;
  }

  getRecommendations(): Observable<any>{
    this.setToken();
    return this.http.get(this.baseurl + "ads/recommendeds/", { headers: this.httpHeaders })
  }

  getProducts(ids: number[]): Observable<any>{
    this.setToken();
    const body = {ids}
    let params = new HttpParams;
    if(this.search){
      params = params.set('search', this.search);
    }
    if(this.selectedCat){
      params = params.set('categories', this.selectedCat.data.idcategory);
    }
    if(this.order){
      params = params.set('order', this.order.order);
      params = params.set('order_by', this.order.orderBy);
    }
    if (this.pricesrange){
      params = params.set('min', this.pricesrange[0]);
      params = params.set('max', this.pricesrange[1]);  
    }
    if(this.promo){
      params = params.set('prom', this.promo);
    }
    params = params.set("page_size", this.pagenumber);
    params = params.set("include_quotations", 0);
    return this.http.post(this.baseurl + "shop/v3/userProducts/", body, { headers: this.httpHeaders, params })
  }

  getProductsV1(page: number): Observable<any>{
    this.setToken();
    let params = new HttpParams;
    if(this.search){
      params = params.set('search', this.search);
    }
    if(this.selectedCat){
      params = params.set('categories', this.selectedCat.data.idcategory);
    }
    if(this.order){
      params = params.set('order', this.order.order);
      params = params.set('order_by', this.order.orderBy);
    }
    if (this.pricesrange){
      params = params.set('min', this.pricesrange[0]);
      params = params.set('max', this.pricesrange[1]);  
    }
    if(this.promo){
      params = params.set('prom', this.promo);
    }
    params = params.set("page_size", this.pagenumber);
    params = params.set("include_quotations", 0);
    params = params.set("page", page)
    return this.http.get(this.baseurl + "shop/userProducts/", { headers: this.httpHeaders, params })
  }

  getProduct(id: number, filter:boolean): Promise<any> {
    if (this.token) {
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else if (this.token === null) {
      this.httpHeaders = new HttpHeaders({ "Content-type": "application/json"});      
    }
    const f = filter? 1 : 0;
    return this.http.get<any>(this.baseurl + "shop/Product/" + id + "/?filter=" + f, { headers: this.httpHeaders }).toPromise();
  }

  searchBar(search: string): Observable<any> {
    this.setToken();
    const body = {
      "search_string":  search,
      "has_pricipal_car": this.token === null ? false : true
    };
    return this.http.post(this.baseurl +  "shop/searchProducts/", body, { headers: this.httpHeaders });
  }
}
