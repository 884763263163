import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { PixelEventName, EventId, PixelEventProperties } from '../../interfaces/pixel';
import { filter } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
declare const fbq: any;

@Injectable({
  providedIn: 'root'
})
export class PixelService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,) { }

  track(
    eventName: PixelEventName,
    properties?: PixelEventProperties,
    eventId?: EventId
  ): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if(properties && eventId){
      fbq('track', eventName, properties, eventId);
    }
    else if (properties) {
      fbq('track', eventName, properties);
    } else {
      fbq('track', eventName);
    }

  }

  trackCustom(eventName: string, properties?: object, eventId?: EventId): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if(properties && eventId){
      fbq('track', eventName, properties, eventId);
    }
    if (properties) {
      fbq('trackCustom', eventName, properties);
    } else {
      fbq('trackCustom', eventName);
    }
  }
}
