import { Component, Input, OnInit, OnDestroy, Renderer2, Inject } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

// import { ModalController } from "@ionic/angular";
// import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { BehaviorSubject, Subscription, lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { CheckoutService } from "src/app/services/buys/checkout.service";
import { CheckoutValues, DatePackageCheckout, DeliveryPackageCheckout, PackageDimensions, RetreatPackageCheckout, Sell, SellLine, ShopCar, ShopCarSells } from "src/app/interfaces/shopCart";
import { Product } from "src/app/interfaces/shop";
import { UserprofileService } from "src/app/services/userProfile/userprofile.service";
import { DeliveryObject, ShippingObject } from "src/app/interfaces/shipping";
import { DeliveryService } from "src/app/services/shop/delivery.service";
import { AlliesService } from "src/app/services/allies/allies.service";
import { DOCUMENT } from '@angular/common';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { BuysService } from "src/app/services/buys/buys.service";
import { GeneralConfigurationService } from "src/app/services/shared/configuration/general-configuration.service";
import { PixelService } from "../../../services/pixel/pixel.service";
import { DialogService } from "primeng/dynamicdialog";
import { ContraentregaComponent } from "../../contraentrega/contraentrega.component";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ScheduleComponent } from "../../schedule/schedule.component";
import { ShopCartService } from "src/app/services/shop/shop-cart.service";
import { AddiService } from "src/app/services/addi/addi.service";
// import { App } from "@capacitor/app";

@Component({
  selector: "app-modal-checkout",
  templateUrl: "./modal-checkout.component.html",
  styleUrls: ["./modal-checkout.component.scss"],
})
export class ModalCheckoutComponent implements OnInit {

  dateSells: any = null;
  retreatCart!: ShopCar;
  products: Product[] = [];
  deliveryParcelCart!: ShopCar;
  deliveryCommodityCart!: ShopCar;
  retreatPackages: RetreatPackageCheckout[] = [];
  deliveryPackagesParcelCart: DeliveryPackageCheckout[] = [];
  deliveryPackagesCommodityCart: DeliveryPackageCheckout[] = [];
  userAddress: {address: string, description: string} = {address: "", description: ""};
  userCityCodeDane = "";
  observaciones = "";
  locationId = 0;
  deliveryLiquidCart!: ShopCar;
  deliveryPackagesLiquidCart: DeliveryPackageCheckout[] = [];

  datePackages: DatePackageCheckout[] = [];
  semaphore: BehaviorSubject<number> = new BehaviorSubject(-1);
  semaphoreSubscription!: Subscription;

  date = new Date();
  sellsCreated: any[] = [];

  sellsIdChecked: number[] = [];
  total = 0;

  showSkeleton = true;
  payEnable = false;
  // disableCloseModal = false;
  // buttonTouched = false;

  private userName = "";

  couponDelivery: any;
  couponDiscount: any;

  sistecreditDialog = false;
  idTypes = [ {value: 'CC'}, {value: 'CE'}];
  idType: any;
  idnumber: any;
  sistecredit = false;

  isMobile = false;
  loadingSiste = false;

  _fbp: string|undefined
  
  type: number

  paymentDialog = false;

  paymentMethod: any;

  contra = false;

  genericInfoUser: any;

  dateDialog = false;

  allow_addi = false;

  addiDialog = false;

  email = "";

  loadingAddi = false;

  subtotal = 0;



  constructor(private checkoutService: CheckoutService,
              private userProfileService: UserprofileService,
              private delivery_service: DeliveryService,
              private alliesService: AlliesService,
              private _renderer2: Renderer2,
              private buysService: BuysService,
              @Inject(DOCUMENT) private _document: Document,
              private gtmService: GoogleTagManagerService,
              private pixel: PixelService,
              private generalConfigurationService: GeneralConfigurationService,
              public dialogService: DialogService,
              private router: Router,
              private messageService: MessageService,
              private shopCartService: ShopCartService,
              private addiService: AddiService) { }

  async ngOnInit(): Promise<void> {
    this._fbp = this.generalConfigurationService.getCookie('_fbp');
    this.isMobile = this.generalConfigurationService.isMobile;
    await this.getInformation();
    setTimeout( () => {this.payEnable = true}, 1500);
    /* await this.setPackageInformation(); */
    this.semaphoreSubscription = this.semaphore.subscribe((data) => {
      if (data === 0) {
        this.afterPayment();
      }
    });
    this.setTotalPayment();
    this.showSkeleton = false;
  }

  checkoutTrigg() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'begin_checkout',
      data: {currency: 'COP', value: this.total},
    };
    this.gtmService.pushTag(gtmTag);
    this.pixel.track('InitiateCheckout', {
      content_category: 'page',
      content_name : 'Checkout',
      currency: 'COP',
      value: this.total
    });
  }

  checkAddi(){
    this.addiService.checkPrices(this.total).subscribe((data) => {
      if(this.total < data.maxAmount && this.total > data.minAmount){
        this.allow_addi = true;
      }else{
        this.allow_addi = false;
      }
    })
  }

 


  async getInformation() {
    // this.dateSells = 
    this.retreatCart = this.checkoutService.retreatCart;
    this.products = this.checkoutService.products ? this.checkoutService.products : [];
    this.deliveryParcelCart = this.checkoutService.deliveryParcelCart;
    this.deliveryCommodityCart = this.checkoutService.deliveryCommodityCart;
    this.retreatPackages = this.checkoutService.retreatPackages ? this.checkoutService.retreatPackages : [];
    this.deliveryPackagesParcelCart = this.checkoutService.deliveryPackagesParcelCart ? this.checkoutService.deliveryPackagesParcelCart : [];
    this.deliveryPackagesCommodityCart = this.checkoutService.deliveryPackagesCommodityCart ? this.checkoutService.deliveryPackagesCommodityCart : [];
    this.userAddress = this.checkoutService.userAddress;
    this.userCityCodeDane = this.checkoutService.userCityCodeDane;
    this.observaciones = this.checkoutService.observaciones;
    this.locationId = this.checkoutService.locationId;
    this.deliveryLiquidCart = this.checkoutService.deliveryLiquidCart;
    this.deliveryPackagesLiquidCart = this.checkoutService.deliveryPackagesLiquidCart ? this.checkoutService.deliveryPackagesLiquidCart : [];
    this.couponDelivery = this.checkoutService.couponDelivery;
    this.couponDiscount = this.checkoutService.couponDiscount;
    this.type = this.checkoutService.type;
  }

  applyCoupon(pack: any){
    return pack.price * (this.couponDiscount.campaing.promotion_value / 100)
  }

  async ngOnDestroy(): Promise<void> {
    this.semaphoreSubscription.unsubscribe();  
    // await this.removeListeners();
  }


  private async setPackageInformation(): Promise<void> {
    try {
      if (this.dateSells) {
        this.dateSells.chain.forEach((product:any, index:any) => {
          const dateCheckout: CheckoutValues[] = [];
          const productCopy = product.sell_lines[0].product_copy;
          const productPrice = productCopy.prize * (1 + productCopy.tax / 100);
          const installPrice = productCopy.install_prize * (1 + productCopy.install_tax / 100);
          let discount = 0
          if (product.sell_lines[0].discount_type === 1) {
            discount = product.sell_lines[0].discount ? product.sell_lines[0].discount : 0;
            discount = (productPrice) * (discount / 100);
          } else if (product.sell_lines[0].discount_type === 2) {
            discount = product.sell_lines[0].discount;
          }
          const value: CheckoutValues = {
            name: product.sell_lines[0].product_idproduct.name,
            price: productPrice,
            discount: discount,
            installPrice: installPrice,
            installTax: productCopy.install_tax
          };
          dateCheckout.push(value);
          const subTotal = this.getSubtotal(dateCheckout);
          const data: DatePackageCheckout = {
            sellIndex: index,
            installPrice,
            subTotal: subTotal + installPrice,
            package: dateCheckout,
            packageToShow: dateCheckout
          };
          this.datePackages.push(data);
          this.sellsIdChecked.push(product.idventa);
          const sell = {
            idventa: product.idventa,
            sell_type: product.sell_type,
            workshop: product.workshop.idworkship,
            productName: product.sell_lines[0].product_idproduct.name,
            productImg: product.sell_lines[0].product_idproduct.id_image[0].image_idimage,
          };
          this.sellsCreated.push(sell);
        });
      }
    } catch (error) {
      /* console.error(error); */
      // this.toastService.toastErrorConnection("Error de conexión");
    }
  }


  async pay(infoUser?: any, data?: any): Promise<void> {
    this.payEnable = false;
    if(this.paymentMethod == "addi"){
      this.loadingAddi = true;
    }
    if(!infoUser){
      infoUser = await lastValueFrom(this.userProfileService.getInfoUser())
    } 
    const enviosObj = await this.checkoutService.infoGeneral(infoUser, this.date, this.userAddress, this.userCityCodeDane);
    const sells: Sell[] = [];
    const quantity = this.deliveryParcelCart.sells.length + this.deliveryCommodityCart.sells.length + this.deliveryLiquidCart.sells.length;
    this.semaphore.next(quantity);
    if (this.deliveryParcelCart.sells.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.deliveryParcelCart.sells.length; i++) {
          const sell = this.deliveryParcelCart.sells[i];
          try {
            let delivery: any;
            let deliveryPrize = 0;
            if(this.type === 1){
              delivery = await this.infoEnvio(sell.workshop, sell.sellLines, enviosObj, "Parcel", false);
              if (delivery instanceof HttpErrorResponse) {
                throw new Error(delivery.error.error);
              }
              deliveryPrize = this.deliveryPackagesParcelCart[0].realDeliverPrice;
            }
            this.createSells(sell, sells, this.type, delivery, deliveryPrize);
          } catch (error) {
            console.log(error);
          }
        }
    }
    if (this.deliveryCommodityCart.sells.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.deliveryCommodityCart.sells.length; i++) {
          const sell = this.deliveryCommodityCart.sells[i];
          try {
            let delivery: any;
            let deliveryPrize = 0;
            if(this.type === 1){
              delivery = await this.infoEnvio(sell.workshop, sell.sellLines, enviosObj, "Commodity", false);
              if (delivery instanceof HttpErrorResponse) {
                throw new Error(delivery.error.error);
              }
              deliveryPrize = this.deliveryPackagesCommodityCart[0].realDeliverPrice;
            } 
            this.createSells(sell, sells, this.type, delivery, deliveryPrize);
          } catch (error) {
            console.log("error");
          }
        }
    }
    if (this.deliveryLiquidCart.sells.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.deliveryLiquidCart.sells.length; i++) {
          const sell = this.deliveryLiquidCart.sells[i];
          try {
            let delivery: any;
            let deliveryPrize = 0;
            if(this.type === 1){
              delivery = await this.infoEnvio(sell.workshop, sell.sellLines, enviosObj, "liquid", false);
              if (delivery instanceof HttpErrorResponse) {
                throw new Error(delivery.error.error);
              }
              deliveryPrize = this.deliveryPackagesLiquidCart[0].realDeliverPrice;
            }
            this.createSells(sell, sells, this.type, delivery, deliveryPrize);
          } catch (error) {
            console.log("error");
          }
        }
    }
    sells.forEach((sell) => {
      if(this.paymentMethod === "contraentrega"){
        sell!.franchie = "CON";
        sell!.payment_gateway_id = sell?.delivery?.remission_code;
        sell!.payed_value = this.total;
        sell!.payed_date = new Date();
        sell!.user_number_id = data.idNumber;
        sell!.user_type_id = data.idType
      }else if (this.paymentMethod === "addi"){
        sell!.user_type_id = 'Cédula de ciudadanía';
        sell!.user_number_id = this.idnumber;
        sell!.email = this.email;
      }
      this.delivery_service.checkout(sell).then(async (data:any) => {
        this.sellsCreated.push(data);
        this.sellsIdChecked.push(data.idventa);
        if(this.paymentMethod === "contraentrega" && environment.production){
          lastValueFrom(this.delivery_service.createBill(data.idventa)).catch((error) => {
            console.log(error);
          })
        }
        this.semaphore.next(this.semaphore.value - 1);
      }).catch((error:Error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: 'Hubo un error al momento de crear tu compra, inténtalo mas tarde.', life: 4000});
      });
    });
  }

  getVariantName(pakage: any){
    return (pakage.variantName ? pakage.variantName : '');
  }

  async infoEnvio(workshopId: number, sellLines: SellLine[], enviosObj: Partial<ShippingObject>, shippingType: string, is_liquid?:boolean): Promise<DeliveryObject> {
    const enviosObjInfo = {... enviosObj}
    // @ts-ignore
    let detalles: any = [];
    // @ts-ignore
    const contenido: any = [];
    let valorDeclarado = 0;
    let names = "";
    let dimensionsAux: PackageDimensions = {
      alto: 0,
      ancho: 0,
      largo: 0,
      peso: 0,
      unidades: 1,
    }
    let max_volume = 0
    sellLines.forEach((sellLine, index) => {
      const detalleProduct:any = this.getProductInfo(workshopId, sellLine, shippingType);
      if (detalleProduct.discount > 0) {
        const originalPrice = (detalleProduct.price * (1 + (detalleProduct.tax / 100))) - detalleProduct.discount;
        const pricewithouthIva = Math.ceil(originalPrice / (1 + (detalleProduct.tax / 100)));
        valorDeclarado += pricewithouthIva;
      } else {
        valorDeclarado += detalleProduct.price;
      }
      const dimensions = detalleProduct.dimensions
      let volume = dimensions.alto * dimensions.ancho * dimensions.largo;
      if(volume > max_volume){
        max_volume = volume;
        detalles = [detalleProduct.dimensions];
      }
      contenido.push(detalleProduct.name);
      names = names+detalleProduct.name[0]
    });
    const data = await this.alliesService.getInfoMarker(workshopId).catch(() => console.log("error"));
    const dir = data.address.indexOf(",");
    enviosObjInfo.nit_remitente = data.enterprise.number_id;
    enviosObjInfo.nombre_remitente = data.visible_name;
    enviosObjInfo.direccion_remitente = data.address.slice(0, dir);
    enviosObjInfo.telefono_remitente = data.phoneNumber;
    enviosObjInfo.ciudad_remitente = data.city.dane_code;
    // @ts-ignore
    enviosObjInfo.detalles = detalles;
    // @ts-ignore
    enviosObjInfo.contenido = contenido.join().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    enviosObjInfo.valor_declarado = valorDeclarado;
    enviosObjInfo.observaciones = this.observaciones.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
    try {
      // @ts-ignore
      /* const dataResp = await this.delivery_service.postGuia(enviosObjInfo, ); */
      let dataResp: any = undefined;
      if (this.paymentMethod === 'contraentrega'){
        let nameaux = enviosObj.nombre_remitente?.charAt(0)
        const unixTime = Math.floor(Date.now() / 1000);
        enviosObjInfo.referencia = names + (nameaux !== undefined ? nameaux : '') + unixTime + ""
        enviosObjInfo.valor = this.total + ""
        dataResp = await this.delivery_service.postGuiaContra(enviosObjInfo);
      }else{
        // @ts-ignore
        dataResp = await this.delivery_service.postGuia(enviosObjInfo, is_liquid);
      }
      const dirUser = this.userAddress.address.indexOf(",");
      const guide = dataResp.id_remision["#text"];
      // tslint:disable-next-line: variable-name
      const remission_code = dataResp.codigo_remision["#text"];
      const userAddress = this.userAddress.address.slice(0, dirUser);
      const ally_Address = data.address.slice(0, dir);
      const ally_Location = data.city.id;
      const guide_link = dataResp.url_terceros["#text"];
      const user_description = this.userAddress.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("ñ", "n").replace("Ñ", "N");
      const delivery: DeliveryObject = {
          guide, remission_code, userLocation: this.locationId,
          userAddress, ally_Address, ally_Location, guide_link, user_description
      };
      return delivery;
    } catch (error:any) {
      if (error.error?.error) {
        // this.toastService.toastErrorConnection(error.error.error);
      } else {
        // this.toastService.toastErrorConnection(error.error);
      }
      return error;
    }
  }

  continueSiste(){
    this.sistecreditDialog = true;
  }

  /* async buySiste(){
    this.sistecredit = true;
    this.loadingSiste = true;
    this.pay();
  } */

  private createSells(sell: ShopCarSells, sells: Sell[], sellType: number, delivery?:any, deliveryPrize?:any): void {
    let aux: Sell;
    const sellLines = sell.sellLines.map((sellLine:any) => {
      return { units: sellLine.units, product_idproduct: sellLine.product, variant: sellLine.variant };
    });
    if (sellType == 1) {
        aux = { sell_lines: sellLines, workshop: sell.workshop, sell_type: sellType, delivery_prize: deliveryPrize,
                delivery: {
                  guide: delivery.guide, remission_code: delivery.remission_code, userAddress: delivery.userAddress,
                  userLocation: delivery.userLocation, ally_addres: delivery.ally_Address,
                  ally_location: delivery.ally_Location, guide_link: delivery.guide_link, user_description: delivery.user_description
                }, from_webpage: true, coupon_discount: this.couponDiscount?.id, coupon_delivery: this.couponDelivery?.id
              };
    } else if (sellType == 0) {
        aux = { sell_lines: sellLines, workshop: sell.workshop, sell_type: sellType, from_webpage: true,
          coupon_discount: this.couponDiscount?.id, coupon_delivery: this.couponDelivery?.id };
    }
    // @ts-ignore
    sells.push(aux);
  }

  private async afterPayment() {

    let base:number = 0;
    let tax:number = 0;
    let names:string[] = [];

    if (this.deliveryPackagesParcelCart.length > 0) {
      await this.deliveryPackagesParcelCart.forEach( async pack => { 
        await pack.package.forEach(product => {
          let units = this.getUnits(pack.packageToShow,product.name, product.variantName);
          let price = product.price - product.discount;
          let taxProduct:any = this.getTaxProduct(product.idproduct)? this.getTaxProduct(product.idproduct) : 0;
          let base_price = price / (1 + (taxProduct / 100));
          base = base + (base_price * units);
          tax = tax + ((base_price * (taxProduct / 100)) * units);
          names.push(product.name);
          
        });
        /* base = base + pack.deliveryPrice; */
      });
    }
    setTimeout(async () => {
      if (this.deliveryPackagesCommodityCart.length > 0) {
        await this.deliveryPackagesCommodityCart.forEach( async pack => { 
          await pack.package.forEach(product => {
            let units = this.getUnits(pack.packageToShow,product.name, product.variantName);
            let price = product.price - product.discount;
            let taxProduct:any = this.getTaxProduct(product.idproduct)? this.getTaxProduct(product.idproduct) : 0;
            let base_price = price / (1 + (taxProduct / 100));
            base = base + (base_price * units);
            tax = tax + ((base_price * (taxProduct / 100)) * units);
            names.push(product.name);
          });
          /* base = base + pack.deliveryPrice; */
        });
      }
    }, 0);

    setTimeout(async () => {
      if (this.deliveryPackagesLiquidCart.length > 0) {
        await this.deliveryPackagesLiquidCart.forEach( async pack => { 
          await pack.package.forEach(product => {
            let units = this.getUnits(pack.packageToShow,product.name, product.variantName);
            let price = product.price - product.discount;
            let taxProduct:any = this.getTaxProduct(product.idproduct)? this.getTaxProduct(product.idproduct) : 0;
            let base_price = price / (1 + (taxProduct / 100));
            base = base + (base_price * units);
            tax = tax + ((base_price * (taxProduct / 100)) * units);
            names.push(product.name);
          });
          /* base = base + pack.deliveryPrice; */
        });
      }
    }, 0);
    if (this.sistecredit){
      const orderId = this.sellsIdChecked.join(";")
      const body = {
        idDocument: this.idnumber,
        typeDocument: this.idType.value,
        transactionDate: new Date(),
        valueToPaid: this.total,
        vendorId: environment.vendorId,
        storeId: environment.storeId,
        orderId,
        responseUrl: environment.checkout_url + "/sistecreditoWeb"
      }
      const bodyfire = {data : this.sellsIdChecked}
      await this.buysService.saveSell(orderId,bodyfire)
      lastValueFrom(this.buysService.getSisteLink(body)).then(response => {
        const url = response.data.urlToRedirect + "?transactionId=" + response.data.transactionId;
        window.location.replace(url);
        localStorage.removeItem("sells");
        localStorage.setItem("sells", JSON.stringify(this.sellsIdChecked));
        this.sellsIdChecked = [];
      }).catch((error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: 'Existe una transacción en curso, inténtalo más tarde.', life: 4000});
      });
    }else if (this.paymentMethod === "contraentrega"){
      this.paymentDialog = false;
      this.router.navigateByUrl(`payment/accepted?ids=${this.sellsIdChecked.join(";")}`)
    }
    else if (this.paymentMethod === "epayco"){
      let script = this._renderer2.createElement('script');
      const ownUrl = window.location.hostname;
      script.type = `text/javascript`;
      script.text = `
        var handler = ePayco.checkout.configure({
          key: '4bb75aee733562d3de6aaccfb9f7390a',
          test: ${environment.production ? 'false' : 'true'}
        });
        var data={
          //Parametros compra (obligatorio)
          name: "Compra Zaito",
          description: "${names.toString()}",
          currency: "cop",
          amount: ${this.total},
          tax_base: ${base},
          tax: ${tax},
          country: "co",
          lang: "es",
  
          //Onpage="false" - Standard="true"
          external: "false",
  
          extra1: "${this.sellsIdChecked.join(";")}",
          extra2: "${this._fbp}",
          //Atributos opcionales
          confirmation: "${environment.checkout_url}/payweb/",
          acepted: "https://www.zaito.co/payment/accepted?ids=${this.sellsIdChecked.join(";")}",
          pending: "https://www.zaito.co/payment/pending",
          rejected: "https://www.zaito.co/payment/rejected",
          response: "https://www.zaito.co/",
        }
        handler.open(data)`;
        this._renderer2.appendChild(this._document.body, script);
        this.sellsIdChecked = [];
    }else{
      this.addiService.getLink(this.sellsIdChecked.join(";")).subscribe((link) => {
        window.location.replace(link.url)
        this.loadingAddi = false;
      }, error => {
        
        this.loadingAddi = false;
        this.addiDialog = false;
      })
    }
    
  }

  getUnits(packages,productName, variantName?){
    let cont = 0;
    packages.forEach((pack) => {
      if (pack.name === productName){
        if(variantName){
          if(variantName === pack.variantName){
            cont++;
          }
        }else{
          cont++;
        }
      } 
    })
    return cont;
  }

  getTaxProduct(idProduct:any) {
    const product:any = this.products.find(resp => resp.idproduct == idProduct);
    return product.tax;
  }

  private getProductInfo(allyId: number, sellLine: SellLine, shippingType: string): CheckoutValues {
    const product = this.products.filter((p) => p.idproduct === sellLine.product);
    const productAux:any = product.find((p:any) => p.workship_idworkship.idworkship === allyId);
    const discount = this.setProductDiscount(productAux);
    // @ts-ignore
    let dimensions: PackageDimensions = null;
    if (productAux.dimensions !== null) {
      if (shippingType === "Parcel") {
        dimensions = this.setCommodityDimensions(productAux, sellLine.units);
      } else if (shippingType === "Commodity") {
        dimensions = this.setCommodityDimensions(productAux, sellLine.units);
      }else if (shippingType === "liquid"){
        dimensions = this.setParcelDimensions(productAux, sellLine.units);
      }
    }
    const data: CheckoutValues = {
      name: productAux.name,
      price: productAux.prize,
      discount,
      installPrice: productAux.install_prize * (1+ (productAux.install_tax/ 100)),
      installTax: productAux.install_tax,
      dimensions,
      tax: productAux.tax,
    };
    return data;
  }

  private setProductDiscount(product: Product): number {
    let discount = 0;
    if (product.productAd.length > 0) {
      if (product.productAd[0].type === 1) {
        discount = (product.productAd[0].discount / 100) * product.prize;
      } else if (product.productAd[0].type === 2) {
        // @ts-ignore
        discount = product.productAd[0].offer;
      }
    }
    return discount;
  }
  
  private setParcelDimensions(product: Product, units: number): PackageDimensions {
    const dimensions: PackageDimensions = {
      alto: product?.dimensions?.height ?? 0 * units,
      ancho: product?.dimensions?.width ?? 0 * units,
      largo: product?.dimensions?.length ?? 0 * units,
      peso: product?.dimensions?.weight ?? 0 * units,
      unidades: 1
    };
    return dimensions;
  }

  private setCommodityDimensions(product: Product, units: number): PackageDimensions {
    const dimensions: PackageDimensions = {
      alto: product?.dimensions?.height ?? 0,
      ancho: product?.dimensions?.width ?? 0 ,
      largo:  product?.dimensions?.length ?? 0,
      peso: product?.dimensions?.weight ?? 0,
      unidades: 1
    };
    return dimensions;
  }


  private getSubtotal(checkoutValues: CheckoutValues[]): number {
    let subTotal = 0;
    checkoutValues.forEach(value => {
      if(this.couponDiscount){
        subTotal = subTotal + value.price - (value.price* (this.couponDiscount.campaing.promotion_value / 100));
      }else{
        subTotal = subTotal + value.price - value.discount;
      }
    });
    return subTotal;
  }

  private setTotalPayment(): void {
    this.retreatPackages.forEach( pack => {
      this.total += pack.subTotal;
      console.log(pack);
      pack.packageToShow.forEach((data) => {
        this.subtotal += data.price
      })
    });
    this.deliveryPackagesParcelCart.forEach( pack => {
      this.total += pack.subTotal;

      console.log(pack);
      pack.packageToShow.forEach((data) => {
        this.subtotal += data.price
      })
    });
    this.deliveryPackagesCommodityCart.forEach( pack => {

      console.log(pack);
      this.total += pack.subTotal;
      pack.packageToShow.forEach((data) => {
        this.subtotal += data.price
      })
    });
    this.datePackages.forEach(pack => {
      
      this.total += pack.subTotal;
      pack.packageToShow.forEach((data) => {
        this.subtotal += data.price
      })
    });
    this.deliveryPackagesLiquidCart.forEach( pack => {

      console.log(pack);
      this.total += pack.subTotal;
      pack.packageToShow.forEach((data) => {
        this.subtotal += data.price
      })
    });
    this.checkAddi();
    this.checkoutTrigg();
  }

  toPayment(){
    if(this.paymentMethod  === "epayco"){
      this.sistecredit = false;
      this.contra = false;
      this.pay();
    }else if (this.paymentMethod === "sistecredit"){
      this.sistecreditDialog = true;
      this.sistecredit = true;
      this.contra = false;
    }else if (this.paymentMethod === "contraentrega"){
      this.sistecredit = false;
      this.contra = true;
      this.showContra();
    }else {
      this.addiDialog = true;
    }
  }

  async showContra(){
    const infoUser = await lastValueFrom(this.userProfileService.getInfoUser())
    const ref = this.dialogService.open(ContraentregaComponent, {
      data: {
        address: this.userAddress.address,
        extra: this.userAddress.description,
        total: this.total,
        location: this.locationId,
        name: infoUser[0].auth_user.first_name,
        last_name: infoUser[0].auth_user.last_name,
        phone: infoUser[0].celphone
      },
      dismissableMask: false,
      showHeader: true,
      width: this.isMobile ? '100vw' : '30%',
      height:  this.isMobile ? '100vh' : '70%'});
    ref.onClose.subscribe((data) => {
      if(data){
        this.pay(infoUser, data);
      }
    })
  }


  openSchedule(){
    let height = "";
    let width = "35%"
    if (window.innerWidth < 1400){
      height = '90vh'
      width = "50%"
    }
    const productsAux: any[] = [];
    let discounts= new Map<number, number>();
    const variants: any[] = []
    this.deliveryPackagesParcelCart.forEach((data) => {
      data.packageToShow.forEach((packaget) => {
        const product = this.products.filter((p) => p.name === packaget.name);
        productsAux.push(product[0]);
        variants.push(packaget.variant);
        discounts.set(product[0].idproduct as number, packaget.discount);
      })
    })
    this.deliveryPackagesCommodityCart.forEach((data) => {
      data.packageToShow.forEach((packaget) => {
        const product = this.products.filter((p) => p.name === packaget.name);
        productsAux.push(product[0]);
        variants.push(packaget.variant);
        discounts.set(product[0].idproduct as number, packaget.discount);
      })
    })
    const ref = this.dialogService.open(ScheduleComponent, {
      closable: true,
      dismissableMask: true,
      showHeader: false,
      width: this.isMobile ? '100vw' : width,
      height: this.isMobile ? '90vh' : height,
      contentStyle: {'overflow-x': 'hidden'},
      data: {products: productsAux, discounts, coupon : this.couponDiscount, variants}
    });
    ref.onClose.subscribe((data: any) => {
      if (data.data) {
        localStorage.removeItem("sells");
        this.shopCartService.clearCart();
        setTimeout(() => {
          this.router.navigate(["/history"]);
        }, 1000);
      }
    });
  }

  get roundSubTotal(){
    return Math.round(this.subtotal);
  }

}
