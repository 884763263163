import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';

@Component({
  selector: 'app-banner-cookies',
  templateUrl: './banner-cookies.component.html',
  styleUrls: ['./banner-cookies.component.scss']
})
export class BannerCookiesComponent {

  displayModal!: boolean;
  necesaryCookie = true;
  preferenceCookies = true;
  staticsCookies = true;
  marketingCookies = true;

  isMobile = false;
  @Output() aceptedCookies = new EventEmitter<string>();

  constructor(private router: Router, private generalConfigurationService: GeneralConfigurationService,){
    this.isMobile = this.generalConfigurationService.isMobile;
  }

  showModalDialog() {
    this.displayModal = true; 
  }

  dissmissModal() {
    this.displayModal = false;
  }

  closeAndDismiss(){
    this.displayModal = false;
    this.aceptedCookies.emit('closed')
  }

  aceptCookies(){
    const now = new Date()
    const item = {
      value: 'acepted',
      expire: now.setFullYear(now.getFullYear() + 1)
    }
    localStorage.setItem('cookies', JSON.stringify(item));
    this.aceptedCookies.emit('acepted')
  }

  closeCookies(){
    this.aceptedCookies.emit('closed')
  }

  goToTermsAndConditions(){
    this.router.navigate(['/termsAndConditions'])
  }
  
}
