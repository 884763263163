import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  islogged!:boolean;

  constructor(private router: Router) {}

  async canActivate(): Promise<boolean>  {
    const key = localStorage.getItem("token");
    if (key) {
      return true;
    }
    this.router.navigateByUrl("login");
    return false;
  }
  
}
