import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigurationService {

  $GoToTop = new BehaviorSubject<boolean>(false);

  isMobile = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
   }

   getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts !== undefined && parts.length === 2){
      return parts.pop()?.split(';').shift();
    }else{
      return;
    }
  }
}
