import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegisterComponent } from './core/auth/register/register.component';
import { CellphonesComponent } from './core/cellphones/cellphones.component';
import { ProfileComponent } from './core/profile/profile.component';
import { AuthGuard } from './guards/auth.guard';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';
import { ProductViewComponent } from './shared/product-view/product-view.component';

const routes: Routes = [
  {
    path: "register",
    component: RegisterComponent
  },  
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "product", 
    component: ProductViewComponent,
  },
  {
    path: "cellphones", 
    component: CellphonesComponent,
  },
  {
    path: "termsAndConditions", 
    component: PdfViewerComponent,
  },
  {
    path: "politicadeprotecciondedatospersonales",
    redirectTo: "termsAndConditions",
    pathMatch: 'full'
  },
  {
    path: "",
    loadChildren: () => import("./core/core.module").then(m => m.CoreModule)
  },


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
