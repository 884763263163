
<div class="grid m-0 header-container" [ngStyle]="{'position': displaySideBarCart == false ? 'unset' : 'static' }">
    <div class = "col-12 flex justify-content-center" *ngIf = "isMobile && islogged">
        <app-address comesFrom="header"></app-address>
    </div>
    <div class="col-4 md:col-1 lg:col-1 xl:col-1 p-0 flex">
        <img src="assets/img/shared/HorizontalLogo.png" (click)="goToHome()" alt="zaito" class="cursor-pointer" style="width: 75%; margin-left: 5%;">
    </div>
    <div class="col-2 p-0 flex" style="cursor: pointer;" *ngIf = "!isMobile && islogged; else space">
        <app-address comesFrom="header"></app-address>
    </div>
    <ng-template #space>
        <div class = "col-2 " *ngIf = "!isMobile">

        </div>
    </ng-template>
    <div class="col-6 p-0 flex"  *ngIf = "!isMobile">
        <app-search-bar style="width: 100%;"></app-search-bar>
    </div>
    <div class="col-8 p-0  md:col-3 lg:col-3 xl:col-3 flex" style="justify-content: flex-end;">
        <div class="flex cursor-pointer" (click)="islogged ? menuOptions.toggle($event) : router.navigate(['login'])">
            <div class="user-name"  *ngIf = "!isMobile">{{ userName ? userName : 'Ingresa' }}</div>
            <span class="material-symbols-rounded icon fill font-container">account_circle</span>
        </div>
        <div style="margin: 0 0 0 5%;">
            <span class="material-symbols-rounded icon fill font-container"  (click)="openSidebarFavorite()" *ngIf = "islogged">favorite</span>
        </div>
        <div style="margin: 0 3% 0 5%;">
            <app-cart (displayCart)="displaySideBarCartFuntions($event)"></app-cart>
        </div>
    </div>
    <div class = "col-12 flex justify-content-center" *ngIf = "isMobile">
        <app-search-bar style="width: 100%;"></app-search-bar>
    </div>
</div>


<p-overlayPanel #menuOptions [style] = "{'padding': '5px'}" [style.width] = "isMobile ? '70vw' : '14vw'">
    <ng-template pTemplate>
        <div class="col-12 flex container-option-overlay" *ngFor="let item of menuOverlay" (click)="selectOptionMenu(item.click)" style="padding: 0 0 0 10px;">
            <div class="col-2 flex" style="align-items: center; justify-content: flex-end;">
                <span class="material-symbols-rounded icon fill font-container" style="font-size: 26px;">{{ item.icon }}</span>
            </div>
            <div class="col-10 flex" style="align-items: center;">
                <p class="m-0" style="font-weight: 500; font-size: 15px;">{{ item.text }}</p>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>

<p-sidebar [(visible)]="displaySideBarFavorites" position="right" [showCloseIcon]="false" class="sidebar-favorites">
    <ng-template pTemplate="header">
        <div class="col-12 flex header-sidebar">
            <div class="col-6">
                <h2 class="m-0 title">Favoritos</h2>
            </div>
            <div class="col-6 flex" style="justify-content: flex-end;">
                <span class="material-symbols-rounded fill icon-close font-container" (click)="displaySideBarFavorites = false">cancel</span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container *ngIf="Favorites; else skeletonFavorites">
            <ng-container *ngIf="Favorites && Favorites.length > 0; else nothing">
                <div class="col-12 flex p-0 mb-3 card-product-favorite" *ngFor="let item of Favorites; index as i">
                    <div class="col-4">
                        <div class="card-no-Available" *ngIf="!item.is_available">
                            <p>No disponible</p>
                        </div>
                        <div class="z-shadow-principal card-image-product flex justify-content-center align-items-center">
                            <img *ngIf="item.product.productAd.length > 0" src="{{adImgs[item.product.productAd[0]?.type]}}" alt="anuncio" class="ad-tag">
                            <img [src]="item.product.id_image[0]?.image_idimage" class="image-product"
                                  onerror="this.src='assets/img/shared/imagenotavailable.svg'">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card-info">
                            <label class="ellipsis"> {{ item.product.name }}</label>
                            <div>
                                <div *ngIf="item.product.productAd.length == 0">
                                    <p style="margin: 5px 0 0 0;">
                                        <label *ngIf="item.product.prize !== -1" class="item-number">
                                            $ {{ productsValues[i].productPrice | number }}
                                        </label>
                                        <label *ngIf="item.product.prize === -1" class="item-number">Cotizar</label>
                                    </p>
                                </div>
                                <div *ngIf="item.product.productAd[0]?.type == 0">
                                    <p style="margin: 0;">
                                        <label style="color: var(--verde-zaito-2);" class="text-ad">Promocionado </label>
                                    </p>
                                    <label *ngIf="item.product.prize !== -1" class="item-number">
                                        $ {{ productsValues[i].totalPrice | number }}
                                    </label>
                                    <label *ngIf="item.product.prize === -1" class="item-number">Cotizar</label>
                                </div>
                                <div *ngIf="item.product.productAd[0]?.type == 2">
                                    <p style="margin: 0;">
                                        <label style="color: var(--verde-zaito-2);" class="text-ad">Antes </label>
                                        <label class="text-ad text-antes">
                                            $ {{ productsValues[i].productPrice | number}}
                                        </label>
                                    </p>
                                    <div class="item-number">
                                        $ {{ productsValues[i].totalPrice | number }}
                                    </div>
                                </div>
                                <div *ngIf="item.product.productAd[0]?.type == 1">
                                    <p style="margin: 0;">
                                        <label style="color: var(--verde-zaito-2);" class="text-ad">
                                            -{{item.product.productAd[0]?.discount}}% 
                                        </label>
                                        <label class="text-ad text-antes">
                                            $ {{ productsValues[i].productPrice | number}}
                                        </label>
                                    </p>
                                    <div class="item-number">
                                        $ {{ productsValues[i].totalPrice | number }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 flex" style="flex-wrap: wrap;">
                        <div>
                            <p class="m-0 text-delete" (click)="deleteFavorite(item)">Eliminar</p>
                        </div>
                        <div class="container-shopping-cart cursor-pointer" (click) = "putInCar(item.product)">
                            <span class="material-symbols-rounded fill font-container">shopping_cart</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #nothing>
                <div class="empty-message-container" style="text-align: center; margin-top: 50%;">
                    <h6 data-testid="empty-message" style="color: var(--gris-zaito-4); font-size: 85%;">
                        Aún no tienes Favoritos...
                    </h6>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #skeletonFavorites>
            <app-skeleton-text [value]="2"></app-skeleton-text>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="footer"></ng-template>
</p-sidebar>

<!-- <p-toast></p-toast> -->