import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PackageDimensions } from 'src/app/interfaces/shop';
import { environment } from 'src/environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  url = environment.shipping_url;
  shipping_code = environment.shipping_code;
  shipping_code_liquid = environment.shipping_code_liquid;
  test = !environment.production;
  httpHeaders = new HttpHeaders({  "apikey": "a9185d3a-59cd-11ec-bf63-0242ac130002", "clave": "gS4iD2yX4gP0iI1s"});
  token = localStorage.getItem("token");

  backurl = environment.server_url;
  
  constructor(private http: HttpClient) { }

  private setToken(){
    this.token = localStorage.getItem("token");
    if (this.token){
      return new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }else{
      return new HttpHeaders({"Content-type": "application/json"})
    }
  }

  getShippingQuotation(origDaneCode: string, destDaneCode: string, worth: string, 
    height: number, width: number, length: number, weight: number, units: number, is_liquid: boolean, isParcel: boolean): Promise<any> {
      if(is_liquid){
        const dimensions: PackageDimensions = {
          "alto" : height, 
          "ancho": width, 
          "largo" : length, 
          "peso": weight, 
          "unidades" : 1
        }
        const body = {
          "origen": origDaneCode,
          // "destino": "05001000",
          "destino": destDaneCode,
          "valoracion": worth.toString(),
          "detalles": [dimensions],
          test : this.test
        }
        /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}) */
        return  lastValueFrom(this.http.post(this.url + 'cotizacionesLiquid?code=' + this.shipping_code_liquid, body, {headers: this.httpHeaders}));
      }else if (isParcel){
        const dimensions_aux: PackageDimensions[] = []
        const dimensions: PackageDimensions = {
          "alto" : height, 
          "ancho": width, 
          "largo" : length, 
          "peso": weight, 
          "unidades" : 1
        }
        dimensions_aux.push({ ...dimensions});
        const body = {
          "origen": origDaneCode,
          // "destino": "05001000",
          "destino": destDaneCode,
          "valoracion": worth,
          "detalles": dimensions_aux,
          test : this.test
        }
        return  lastValueFrom(this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.httpHeaders}));
      }
      else{
        const dimensions: PackageDimensions = {
          "alto" : height, 
          "ancho": width, 
          "largo" : length, 
          "peso": weight, 
          "unidades" : 1
        }
        const body = {
          "origen": origDaneCode,
          // "destino": "05001000",
          "destino": destDaneCode,
          "valoracion": worth.toString(),
          "detalles": [dimensions],
          test : this.test
        }
        /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}) */
        return  lastValueFrom(this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.httpHeaders}));
      }
  }

  promisedParseJSON(json: any) {
    return new Promise((resolve, reject) => {
        try {
            resolve(json)
        } catch (e) {
            reject(e)
        }
    })
  }

  getShippingPackageQuotation(origDaneCode: string, destDaneCode: string, worth: number, dimensions: PackageDimensions[], isLiquid: boolean): Promise<any> {
    let sumaDimension = {
      alto: 0,
      ancho: 0,
      largo: 0,
      peso: 0,
      unidades: 1
    }
    dimensions.forEach((dimension) => {
      sumaDimension.alto += dimension.alto * dimension.unidades;
      sumaDimension.ancho += dimension.ancho * dimension.unidades;
      sumaDimension.largo += dimension.largo * dimension.unidades;
      sumaDimension.peso += dimension.peso * dimension.unidades;
    });
    const body = {
      "origen": origDaneCode,
      "destino": destDaneCode,
      // "destino": "05001000",
      "valoracion": worth,
      "detalles": [sumaDimension],
      test : this.test
    }
    if(isLiquid){
      /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}) */
      return  lastValueFrom(this.http.post(this.url + 'cotizacionesLiquid?code=' + this.shipping_code_liquid, body, {headers: this.httpHeaders}));
    }else{
      /* return this.promisedParseJSON({Cotizador_cotizarResult: {flete_total: 17820, flete_fijo: 13200, flete_variable:4620, peso_liquidado: 30, otros_valores: 0, producto: 16, ubl: 16, volumen: 2, peso_real:2, dias_entrega:1 }}) */
      return lastValueFrom(this.http.post(this.url + "cotizaciones?code=" + this.shipping_code, body, {headers: this.httpHeaders}));
    }  
  }

  checkout(body: any){
    const headers = this.setToken();
    return lastValueFrom(this.http.post(this.backurl + 'payment/checkOut/', body, {headers}));
  }

  checkoutNoAccount(body: any){
    const headers = this.setToken();
    return lastValueFrom(this.http.post(this.backurl + 'payment/checkOutNA/', body, {headers}));
  }

  postGuia(body: any, is_liquid: boolean): Promise<any>{
    const headers = new HttpHeaders({
        "Content-type": "application/json",
         usuario: "zaito.ws", clave: "7395774e8f87a644ae2cb70e89c3260211d376bb5bf634d4721088cfb4fa9ceb" });   
    if (is_liquid) {
      /* return this.promisedParseJSON({id_remision: {"#text": "126156762"}, codigo_remision: {"#text": "91364500527"}, url_terceros:  {"#text": "http://guias.coordinadora.com/vmi/?guia=91364500527-4f585f0743133ea7fe2746bfaf359c43d8583a3e9476c9a092a3899a3b053b98"}}); */
      return this.http.post(this.url + "guidesLiquid?code=Q7esp-Sbne6GzRf7GYPJiZWyc_qIRaGuEtb2PYvggvxEAzFuoNSNsA==", body ,{ headers }).toPromise();
    }else {
/*       return this.promisedParseJSON({id_remision: {"#text": "126156762"}, codigo_remision: {"#text": "91364500527"}, url_terceros:  {"#text": "http://guias.coordinadora.com/vmi/?guia=91364500527-4f585f0743133ea7fe2746bfaf359c43d8583a3e9476c9a092a3899a3b053b98"}}); */
      return this.http.post(this.url + "guides?code=aRL7vjpJKDnF8dhkaqYTKs/IQOL9lG3Zz4uq94wAdF58e0D8hr8MBg==", body ,{ headers }).toPromise();
    }
  }

  postGuiaContra(body: any):Promise<any>{
    const headers = new HttpHeaders({
        "Content-type": "application/json",
         usuario: "zaito.ws", clave: "7395774e8f87a644ae2cb70e89c3260211d376bb5bf634d4721088cfb4fa9ceb" });
    /*  return this.promisedParseJSON({id_remision: {"#text": "126156762"}, codigo_remision: {"#text": "91364500527"}, url_terceros:  {"#text": "http://guias.coordinadora.com/vmi/?guia=91364500527-4f585f0743133ea7fe2746bfaf359c43d8583a3e9476c9a092a3899a3b053b98"}}); */
    return this.http.post(this.url + "guidesContra?code=A39tbYamiAe0AnUJhQNe0MkXIwIPqb9l-kDEOdd0n_CMAzFuwH38Hg==", body ,{ headers }).toPromise();
    
  }

  getLocationGroup(id: any): Observable<any>{
    const headers = this.setToken();
    return this.http.get(this.backurl + "core/locationGroup/" +  id + "/",{ headers });
  }

  createBill(id: any): Observable<any>{
    const headers = new HttpHeaders({
      "Content-type": "application/json",})
    const url = "https://sharedzaitofunctions.azurewebsites.net/api/CreateBilling?code=EDYyIuQLVqSaU3CApka5DDbvqEjtm4igCRlz4icN6nKGAzFu5zbERw=="
    return this.http.post(url, {sell : id}, { headers })
  }

}
