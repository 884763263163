<div class = "container">
    <div class = "grid mt-2">
        <div class = "col-12">
            <img src = "../../../assets/img/shared/footer/CoordinadoraLogo.png" style = "height: 2em;">
        </div>
    </div>
    <div class = "grid">
        <div class = "col-12 mt-2" style = "font-weight: 600;color:black">
            Medios de Pago Habilitados para:
        </div>
        <div class  = "col-11 ml-2" style = "color: black;">
            {{address}}
        </div>
        <div class  = "col-11 ml-2" style = "padding-top: 0px;">
            {{extra}}
        </div>
    </div>
    <div *ngIf = "loading">
        <p-skeleton height="10vh" width="100%" *ngIf = "isMobile;else skeletonWeb"></p-skeleton>
        <!-- <app-skeleton-text [value]="3" ></app-skeleton-text> -->
        <ng-template #skeletonWeb>
            <p-skeleton height="10vh" width="100%"></p-skeleton>
        </ng-template>
    </div>
    <div class = "grid" *ngIf = "!loading">
        <div class = "col-4 mt-2 flex justify-content-center" 
        style = "color:var(--azul-zaito-5);font-weight: 600;" *ngIf = "contraGroup">
            <i class="pi pi-money-bill mr-2" ></i> EFECTIVO
        </div>
        <div class = "col-1 flex align-items-center justify-content-center" style = "font-size: 1.8em;">
            /
        </div>
        <div class = "col-7 sm:col-5 md:col-5 lg:col-5 xl:col-5 mt-1 flex" 
        style = "color:var(--azul-zaito-4);font-weight: 600;" *ngIf = "contraGroup == 2">
            <img src = "../../../assets/img/shared/Medios-de-Pago-Coordinadora.png" style = "height: 1.5em;max-width: 100%;">
        </div>
    </div>
    <div class = "grid" *ngIf = "!loading && !loadingContra">
        <div class = "col-12"  style = "font-weight: 600;color:black">
            Datos del Comprador
        </div>
        <div class  = "col-11 ml-2" style = "color: black;text-transform: capitalize;">
            {{name + ' ' + last_name}}
        </div>
        <div class  = "col-11 ml-2" style = "padding-top: 0px;color: black">
            {{phone}}
        </div>
        <div class  = "col-11 ml-2" style = "padding-top: 10px;color: black;font-weight: 600">
            Documento de Identidad
        </div>
        <div class  = "col-11 ml-2" style = "padding-top: 10px;color: black;font-weight: 600">
            <div class = "grid">
                <div class = "col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                    <p-dropdown [options]="idTypes" optionLabel="value" placeholder="Tipo de documento" class="dropdown-select"  
                    [(ngModel)]="idType">
                    </p-dropdown>
                </div>
                <div class = "col-8">
                    <input [(ngModel)]="idNumber"  pInputText style="width: 100%;" placeholder="Ingresa documento">
                </div>
            </div>
        </div>
    </div>
    <div class = "grid mt-4" style = "position: absolute;width: 90%;bottom: 6%;" *ngIf = "contraGroup && !loading ;else nocontra">
        <div class = "col-12" *ngIf = "!loadingContra">
            <p-button class="button-Contraentrega button-AddCar" style = "padding: 0;" (click) = "pay()" [disabled]="!(idNumber?.length > 5) || !idType" >
                <div class = "col-8" style = "padding: 0;font-size: 0.8em;display: flex;color: var(--azul-zaito-1)">
                    PAGAR CON CONTRA ENTREGA
                </div>
                <div class = "col-3" style = "padding: 0;display: flex;justify-content: flex-end;color:black">
                    {{total | number}}
                </div>
            </p-button>
        </div>
    </div>
    <div class = "grid mt-4" *ngIf = "loadingContra">
        <div class = "col-12" style = "display: flex;justify-content: center;font-weight: 600;color: black;">
            Confirmando Compra... {{seconds}}
        </div>
        <div class = "col-12" style  ="display: flex;justify-content: center;">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
        <div class = "col-12">
            <p-button class="button-AddCar" (click)="interrupCountdown()">
                <span class = "cartbutton">Cancelar</span>
            </p-button>
        </div>
    </div>
    <ng-template #nocontra>
        <div class = "grid mt-4" style = "position: absolute;width: 90%;bottom: 6%;" *ngIf = "!loading">
            <div class = "col-12" style = "display: flex;justify-content: center;font-weight: 600;color: black;">
                * Población no disponible <br> para pagos contra entrega
            </div>
        </div>
    </ng-template>
</div>