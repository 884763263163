import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription, lastValueFrom } from 'rxjs';

import { Product } from 'src/app/interfaces/shop';
import { CheckoutValues, DeliveryPackageCheckout, PackageDimensions, RetreatPackageCheckout } from 'src/app/interfaces/shopCart';
import { BuysService } from 'src/app/services/buys/buys.service';
import { CheckoutService } from 'src/app/services/buys/checkout.service';
import { CouponsService } from 'src/app/services/coupons/coupons.service';
import { GarageService } from 'src/app/services/garage/garage.service';

import { AccountService } from 'src/app/services/shared/account/account.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { ShippingsService } from 'src/app/services/shop/shippings.service';
import { ShopCartService } from 'src/app/services/shop/shop-cart.service';
import { AddressService } from 'src/app/services/user/address/address.service';
import { ScheduleComponent } from '../schedule/schedule.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  numberProductsInCart: number = 0;
  numberProductsInCart2: string = "";
  islogged: boolean = false;
  displaySideBarCart: boolean = false;
  displaySideBarCheckout: boolean = false;

  // @ts-ignore
  dateCart: ShopCar = null;
  // @ts-ignore
  deliveryParcelCart: ShopCar = null;
  // @ts-ignore
  deliveryCommodityCart: ShopCar = null;
  // @ts-ignore
  deliveryLiquidCart: ShopCar = null;
  // @ts-ignore
  retreatCart: ShopCar = null;
  retreatPackages: RetreatPackageCheckout[] = [];
  deliveryPackagesParcelCart: DeliveryPackageCheckout[] = [];
  deliveryPackagesCommodityCart: DeliveryPackageCheckout[] = [];
  deliveryPackagesLiquidCart: DeliveryPackageCheckout[] = [];

  // productsCart:any = null;
  percentages: any;
  products: Product[] = [];
  // outdatedProducts: Product[] = [];
  updatedProducts: { productId: number, type: number }[] = [];
  productsArray: { productId: number, units: number, date: Date, type: number }[] = []
  userAddress: { address: string, description: string } = { address: "", description: "" };
  userCityCodeDane = "";
  observaciones = "";
  productsIds = "";
  locationId = 0;
  worshipId = 1;
  workforce = 0;
  total = 0;
  totalItems = 0;
  // unverifiedProducts:any;
  productsLoaded = 3;
  // maxTrans = 7999999
  enableCheckout = true;
  loaded = false;
  // creatingTrace = false;
  isCompatibleAddress = false;
  hasShippingProducts = false;
  loadingDeliveryInfo = false;
  deliveryParcelPriceAdded = false;
  deliveryCommodityPriceAdded = false;
  deliveryLiquidPriceAdded = false;
  productsChecked = false;
  needGeneralReload = true;
  idsProducts = [];
  failLiquid = false;
  availability = new Map<string, boolean>();

  sumTotalDeliveryPrice: number = 0;
  sumTotalSubTotal: number = 0;
  sumTotalDiscount: number = 0;
  sumTotalInstallPrice: number = 0;

  @Output() displayCart = new EventEmitter<boolean>(false);

  subsNumProductsInCart: Subscription;
  dateSub!: Subscription;
  deliveryParcelSub!: Subscription;
  deliveryCommoditySub!: Subscription;
  deliveryLiquidSub!: Subscription;
  retreatSub!: Subscription;

  displayRedirect = false;
  saleNumber: number;

  showCouponDialog=false;
  couponCodeDiscount: string;
  couponDiscount: any = undefined;
  couponCodeDelivery: string;
  couponDelivery: any = undefined;
  couponCode: string;

  isMobile = false;

  type:number = 1;
  shippingDays: any = 1;

  noVehiclesToDateModal = false;

  constructor(
    public router: Router,
    private accountInfo: AccountService,
    private shopCartService: ShopCartService,
    private shippingsService: ShippingsService,
    private buysService: BuysService,
    private checkoutService: CheckoutService,
    private addressService: AddressService,
    private couponService: CouponsService,
    private messageService: MessageService,
    private generalConfigurationService: GeneralConfigurationService,
    private garageService: GarageService,
    public dialogService: DialogService,
  ) { }

  async ngOnInit() {
    this.isMobile = this.generalConfigurationService.isMobile;
    this.islogged = await this.accountInfo.isLogged();
    this.getSubscriptions();
  }

  ngOnDestroy() {
    if (this.subsNumProductsInCart) {
      this.subsNumProductsInCart.unsubscribe();
    }
    if (this.dateSub) {
      this.dateSub.unsubscribe();
    }
    if (this.deliveryCommoditySub) {
      this.deliveryCommoditySub.unsubscribe();
    }
    if (this.deliveryParcelSub) {
      this.deliveryParcelSub.unsubscribe();
    }
    if (this.deliveryLiquidSub) {
      this.deliveryLiquidSub.unsubscribe();
    }
    if (this.retreatSub) {
      this.retreatSub.unsubscribe();
    }
  }

  getSubscriptions() {
    this.subsNumProductsInCart = this.shopCartService.$numProductsInCart.subscribe(data => {
      this.numberProductsInCart = data;
      this.numberProductsInCart2 = this.numberProductsInCart.toString();
    });
  }

  openSidebarCart() {
    this.displayCart.emit(true);
    this.displaySideBarCart = true;
  }

  initSideBarCart() {
    this.initializeVariables();
    this.loaded = false;
    this.deliveryCommoditySub = this.shopCartService.deliveryCommodityCar.subscribe(
      (deliveryCommodityCar) => {
        this.deliveryCommodityCart = deliveryCommodityCar;
        this.productsLoaded--;
        if ((this.productsLoaded <= 0 && this.needGeneralReload) || this.type == 0) {
          this.getProducts();
        } else {
          this.needGeneralReload = true;
          this.loaded = true;
        }
      });
    this.deliveryLiquidSub = this.shopCartService.deliveryLiquidCar.subscribe(
      (deliveryLiquidCar) => {
        this.deliveryLiquidCart = deliveryLiquidCar;
        this.productsLoaded--;
        if ((this.productsLoaded <= 0 && this.needGeneralReload) || this.type == 0) {
          this.getProducts();
        } else {
          this.needGeneralReload = true;
          this.loaded = true;
        }
      });
    this.deliveryParcelSub = this.shopCartService.deliveryParcelCar.subscribe(
      (deliveryParcelCar) => {
        this.deliveryParcelCart = deliveryParcelCar;
        this.productsLoaded--;
        if ((this.productsLoaded <= 0 && this.needGeneralReload) || this.type == 0) {
          this.getProducts();
        } else {
          this.needGeneralReload = true;
          this.loaded = true;
        }
      });
  }

  hideSideBarCart(e: Event) {
    this.displayCart.emit(false);
  }

  initializeVariables() {
    this.sumTotalSubTotal = 0;
    this.sumTotalDeliveryPrice = 0;
    this.sumTotalInstallPrice = 0;
    this.sumTotalDiscount = 0;
    this.productsLoaded = 3;
  }

  private getProducts(): void {
    this.loaded = false;
    this.totalItems = 0;
    this.productsIds = "";
    this.hasShippingProducts = false;
    this.total = 0;
    if (this.dateCart && this.dateCart.sells.length > 0) {
      this.dateCart.sells[0].sellLines.forEach((line: any) => {
        this.totalItems++;
        this.productsIds = this.productsIds + line.product + ",";
      });
      this.worshipId = this.dateCart?.sells[0].workshop;
    } else {
      if (this.deliveryParcelCart?.sells.length) {
        this.hasShippingProducts = true;
        this.deliveryParcelCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.deliveryParcelCart?.sells[0].workshop;
      }
      if (this.deliveryCommodityCart?.sells.length) {
        this.hasShippingProducts = true;
        this.deliveryCommodityCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.deliveryCommodityCart?.sells[0].workshop;
      }
      if (this.deliveryLiquidCart?.sells.length) {
        this.hasShippingProducts = true;
        this.deliveryLiquidCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.deliveryLiquidCart.sells[0].workshop;
      }
      if (this.retreatCart?.sells.length) {
        this.retreatCart.sells.forEach((sell: any) => {
          sell.sellLines.forEach((line: any) => {
            this.totalItems++;
            this.productsIds = this.productsIds + line.product + ",";
          });
        });
        this.worshipId = this.retreatCart.sells[0].workshop;
      }
    }
    if (this.productsIds !== "") {
      this.setProductsArray();
      this.shopCartService.loadProducts(this.productsIds.substring(0, this.productsIds.length - 1)).then(async (products: any) => {
        this.products = products;
        await this.setPackageInformation("allTypes");
        this.loaded = true;
        this.needGeneralReload = true;
        if (!this.productsChecked) {
          await this.checkChanged();
        }
      }).catch(error => { console.error(error); });
    } else {
      this.loaded = true;
    }
  }

  private setProductsArray(): void {
    this.productsArray = [];
    this.dateCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.dateCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.retreatCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.retreatCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.deliveryCommodityCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.deliveryCommodityCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.deliveryParcelCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.deliveryParcelCart.type
        }
        this.productsArray.push(data)
      });
    });
    this.deliveryLiquidCart?.sells.forEach((sell: any) => {
      sell.sellLines.forEach((sellLine: any) => {
        const data: any = {
          productId: sellLine.product,
          units: sellLine.units,
          date: sellLine.date,
          type: this.deliveryLiquidCart.type
        }
        this.productsArray.push(data)
      });
    });
  }

  private async setPackageInformation(sellType: number | "allTypes"): Promise<void> {
    const setRetreatPackage = sellType === 0 || sellType === "allTypes";
    const setDeliveryPackage = sellType === 1 || sellType === "allTypes";
    this.retreatPackages = setRetreatPackage ? [] : this.retreatPackages;
    this.deliveryPackagesParcelCart = setDeliveryPackage ? [] : this.deliveryPackagesParcelCart;
    this.deliveryPackagesCommodityCart = setDeliveryPackage ? [] : this.deliveryPackagesCommodityCart;
    try {
      let currentAddress: any = null;
      const isDeliveryParcelCart = this.deliveryParcelCart.sells.length > 0;
      const isDeliveryCommodityCart = this.deliveryCommodityCart.sells.length > 0;
      const isDeliveryLiquidCart = this.deliveryLiquidCart.sells.length > 0;
      if (isDeliveryParcelCart || isDeliveryCommodityCart || isDeliveryLiquidCart) {
        this.loadingDeliveryInfo = true;
        currentAddress = await this.setUserAddress();
      }
      if (isDeliveryParcelCart) {
        this.deliveryPackagesParcelCart = await this.setPackageDeliveryInformation(this.deliveryParcelCart, currentAddress, false, true);
        this.deliveryParcelPriceAdded = false;// Para que solo sume el envío una vez
      }
      if (isDeliveryCommodityCart) {
        this.deliveryPackagesCommodityCart = await this.setPackageDeliveryInformation(this.deliveryCommodityCart, currentAddress, false, false);
        this.deliveryCommodityPriceAdded = false;// Para que solo sume el envío una vez
      }
      if (isDeliveryLiquidCart) {
        this.deliveryPackagesLiquidCart = await this.setPackageDeliveryInformation(this.deliveryLiquidCart, currentAddress, true, false);
        this.deliveryLiquidPriceAdded = false;// Para que solo sume el envío una vez
      }
      this.loadingDeliveryInfo = false;
    } catch (error) { }
  }

  private async setAllyAddress(allyId: number): Promise<string> {
    const data: any = this.products.find((p: any) => p.workship_idworkship.idworkship === allyId)
    const city: any = data.workship_idworkship.city as Location;
    return city.name;
  }

  private getProductInfo(allyId: number, productId: number, units: number, variant: number): CheckoutValues {
    const product = this.products.filter((p) => p.idproduct === productId);
    const productAux: any = product.find((p: any) => p.workship_idworkship.idworkship === allyId);
    const tax = productAux.tax ? productAux.tax : 0;
    const productPrice = productAux.prize * (1 + tax / 100);
    let variantName = '';
    if(variant){
      const variantProduct =  productAux.variants.filter((data: any) => {
        return data.id == variant
      })
      variantName = variantProduct[0].cloth_size;
    }
    let discount = 0;
    if (productAux.productAd.length !== 0) {
      if (productAux.productAd[0].type === 1) {
        discount = productPrice * (productAux.productAd[0].discount / 100);
      } else if (productAux.productAd[0].type === 2) {
        discount = productAux.productAd[0].offer;
      }
    }else if (this.couponDiscount){
      discount = productPrice * (this.couponDiscount.campaing.promotion_value / 100);
    }
    // @ts-ignore
    let dimensions: PackageDimensions = null;
    if (productAux.dimensions !== null) {
      dimensions = {
        alto: productAux.dimensions.height,
        ancho: productAux.dimensions.width,
        largo: productAux.dimensions.length,
        peso: productAux.dimensions.weight,
        unidades: units
      };
    } else {
      dimensions = {
        alto: 0,
        ancho: 0,
        largo: 0,
        peso: 0,
        unidades: units
      };
    }
    const data: CheckoutValues = {
      name: productAux.name,
      price: productPrice,
      discount,
      installPrice: productAux.install_prize * (1+ (productAux.install_tax/ 100)),
      installTax: productAux.install_tax,
      dimensions,
      idproduct: productId,
      variant,
      variantName
    };
    return data;
  }

  private getSubtotal(checkoutValues: CheckoutValues[]): number {
    let subTotal = 0;
    checkoutValues.forEach((value: any) => {
      subTotal = subTotal + ((value.price - value.discount) + (this.type == 2 ? value.installPrice : 0))  * value.dimensions.unidades;
    });
    return subTotal;
  }

  private async setUserAddress(): Promise<any> {
    try {
      const resp = await this.addressService.getLocation();
      if (resp.length !== 0) {
        let currentAddress = resp.find((data: any) => data.current === true);
        if (resp.length === 1) {
          currentAddress = resp[0];
          currentAddress.current = true;
        }
        const destDaneCode: string = currentAddress.location.dane_code;
        this.userAddress = {
          address: currentAddress.address,
          description: currentAddress.description
        };
        this.userCityCodeDane = currentAddress.location.dane_code;
        this.observaciones = currentAddress.description;
        this.locationId = currentAddress.location.id;
        return currentAddress;
      } else {
        // this.openUserDirections();
        return null;
      }
    } catch (error) {
      // this.toastService.toastErrorConnection("Error de conexión")
    }
  }

  async setPackageDeliveryInformation(deliveryCart: any, currentAddress: any, isLiquid: boolean, isParcel: boolean): Promise<any[]> {
    let destDaneCode = "";
    const myArray: DeliveryPackageCheckout[] = [];
    if (deliveryCart && currentAddress) {
      destDaneCode = currentAddress.location.dane_code;
      for (let index = 0; index < deliveryCart.sells.length; index++) {
        const sell = deliveryCart.sells[index];
        const deliveryCheckout: CheckoutValues[] = [];
        const deliveryCheckoutToShow: CheckoutValues[] = [];
        this.idsProducts = [];
        sell.sellLines.forEach((sellLine: any) => {
          const aux: CheckoutValues = this.getProductInfo(sell.workshop, sellLine.product, sellLine.units, sellLine.variant);
          deliveryCheckout.push(aux);
          // @ts-ignore
          this.idsProducts.push(sellLine.product)
          for (let i = 0; i < sellLine.units; i++) {
            const newData: CheckoutValues = {
              name: aux.name,
              price: aux.price,
              discount: aux.discount,
              installPrice: aux.installPrice,
              installTax: aux.installTax,
              variant: aux.variant ? aux.variant : undefined,
              variantName: aux.variantName ? aux.variantName : ''
            };
            deliveryCheckoutToShow.push(newData);
          }
        });
        const origDaneCode = this.getShopOrigDaneCode(sell.workshop);
        const subTotal = this.getSubtotal(deliveryCheckout);
        let deliveryInfo = await this.getDeliveryInfo(deliveryCheckout, destDaneCode, origDaneCode, subTotal, this.idsProducts, isLiquid, isParcel);
        /* let percentages = this.buysService.getPercentages(this.product.idproduct, destDaneCode) */
        if (deliveryInfo === null) {
          deliveryInfo = [0, 0, 0];
        }
        const data: DeliveryPackageCheckout = {
          sellIndex: index,
          origDaneCode,
          // @ts-ignore
          deliveryPrice: this.type == 1 ? deliveryInfo[0] : 0,
          // @ts-ignore
          shippingDays: this.type == 1 ? deliveryInfo[1] : 0,
          // @ts-ignore
          subTotal: subTotal + (this.type == 1 ? deliveryInfo[0] : 0),
          package: deliveryCheckout,
          packageToShow: deliveryCheckoutToShow,
          // @ts-ignore
          realDeliverPrice: this.type == 1 ? deliveryInfo[2]: 0,
        };
        this.shippingDays = data.shippingDays;
        myArray.push(data);
      }
    }else if(deliveryCart){
      for (let index = 0; index < deliveryCart.sells.length; index++) {
        const sell = deliveryCart.sells[index];
        const deliveryCheckout: CheckoutValues[] = [];
        const deliveryCheckoutToShow: CheckoutValues[] = [];
        this.idsProducts = [];
        sell.sellLines.forEach((sellLine: any) => {
          const aux: CheckoutValues = this.getProductInfo(sell.workshop, sellLine.product, sellLine.units, sellLine.variant);
          deliveryCheckout.push(aux);
          // @ts-ignore
          this.idsProducts.push(sellLine.product)
          for (let i = 0; i < sellLine.units; i++) {
            const newData: CheckoutValues = {
              name: aux.name,
              price: aux.price,
              discount: aux.discount,
              installPrice: aux.installPrice,
              installTax: aux.installTax,
              variant: aux.variant ? aux.variant : undefined,
              variantName: aux.variantName ? aux.variantName : ''
            };
            deliveryCheckoutToShow.push(newData);
          }
        });
        const subTotal = this.getSubtotal(deliveryCheckout);
        let deliveryInfo: any = null;
        /* let percentages = this.buysService.getPercentages(this.product.idproduct, destDaneCode) */
        if (deliveryInfo === null) {
          deliveryInfo = [0, 0, 0];
        }
        const data: DeliveryPackageCheckout = {
          sellIndex: index,
          origDaneCode: "0",
          // @ts-ignore
          deliveryPrice: this.type == 1 ? deliveryInfo[0] : 0,
          // @ts-ignore
          shippingDays: this.type == 1 ? deliveryInfo[1] : 0,
          // @ts-ignore
          subTotal: subTotal + (this.type == 1 ? deliveryInfo[0] : 0),
          package: deliveryCheckout,
          packageToShow: deliveryCheckoutToShow,
          // @ts-ignore
          realDeliverPrice: this.type == 1 ? deliveryInfo[2]: 0,
        };
        this.shippingDays = data.shippingDays;
        myArray.push(data);
      }
    }
    return myArray
  }

  private getShopOrigDaneCode(allyId: number): string {
    const product = this.products.filter((p: any) => p.workship_idworkship.idworkship === allyId);
    // tslint:disable-next-line: no-string-literal
    // @ts-ignore
    const daneCode: string = product[0].workship_idworkship.city["dane_code"];
    return daneCode;
  }

  private redondearAlPiso(numero: number): number {
    const factor = 100; // establece el factor de redondeo, en este caso 100 para los últimos dos dígitos
    return Math.floor(numero / factor) * factor; // divide el número por el factor, aplica Math.floor y luego multiplica por el factor
  }

  private async getDeliveryInfo(checkoutValues: CheckoutValues[], destDaneCode: string,
    origDaneCode: string, subTotal: number, idsProdcuts: number[], isLiquid: boolean, isParcel: boolean): Promise<number[] | void> {
    try {
      const worth = subTotal.toString();
      let dimensions: PackageDimensions[] = [];
      let maxVolume = 0
      checkoutValues.forEach((c: any) => {
        const volume = c.dimensions.alto * c.dimensions.ancho * c.dimensions.largo
        if (volume > maxVolume){
          maxVolume = volume
          dimensions = [c.dimensions]
        }
      });
      const promises: Promise<any>[] = [];
      const is_liquid = isLiquid ? true : false;
      promises.push(this.shippingsService.getShippingPackageQuotation(origDaneCode, destDaneCode, worth, dimensions, is_liquid, isParcel));
      if(this.couponDelivery){
        promises.push(this.buysService.getPercentage(4));
      }else{
        promises.push(this.buysService.getPercentageWithPrice(idsProdcuts, subTotal, destDaneCode));
      }
      return await Promise.all(promises).then(async (values) => {
        const data = values[0];
        const quoteInfo = data.Cotizador_cotizarResult;
        this.percentages = values[1];
        if (quoteInfo) {
          const realShippingPrice = parseInt(quoteInfo.flete_total, 10);
          let shippingPrice = 0;
          if(values[1].fixed_delivery_price){
            shippingPrice = values[1].fixed_delivery_price;
          }else{
            shippingPrice = this.redondearAlPiso(Math.ceil(parseInt(quoteInfo.flete_total) * (values[1].user_shipping / 100)));
          }
          /* shippingPrice = this.redondearAlPiso(Math.ceil(parseInt(quoteInfo.flete_total) * (values[1].user_shipping / 100))); */
          const shippingDays = parseInt(quoteInfo.dias_entrega, 10) + 1;
          this.isCompatibleAddress = true;
          return [shippingPrice, shippingDays, realShippingPrice];
        } else {
          this.isCompatibleAddress = false;
          this.loadingDeliveryInfo = false;
          this.failLiquid = is_liquid ? true : false;
          return [0, 0, 0];
        }
      }).catch(error => {
        this.isCompatibleAddress = false;
        this.loadingDeliveryInfo = false;
        this.failLiquid = is_liquid ? true : false;
        console.error(error)
        // this.toastService.toastErrorConnection("Error de conexión");
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async checkChanged(): Promise<boolean> {
    let tittle = "";
    let message = "*";
    const productsChanged = this.products.filter((p: any) => {
      const typeChanged = this.checkProductType(p);
      if (p.update_date !== null) {
        const cartInfo = this.getCartInfo(p.idproduct);
        const cartDate = new Date(cartInfo.date);
        const updatedDate = new Date(p.update_date);
        const updated = updatedDate > cartDate;
        this.changeCartDate(p.idproduct, updatedDate);
        if (updated) {
          this.updatedProducts.push({ productId: p.idproduct, type: cartInfo.type })
        }
        return updated || typeChanged;
      } else {
        return typeChanged
      }
    });
    this.productsChecked = true;
    if (productsChanged.length > 0) {
      tittle = "Cambios en Producto y/o Servicio";
      productsChanged.forEach((product, i) => {
        const final = i + 1 === productsChanged.length ? " " : ", ";
        message = message + product.name + final;
      });
      if (productsChanged.length > 1) {
        message = message + "han sido modificados por el Vendedor, "
          + "te invitamos a verificarlos de nuevo antes de proceder con la compra.";
      } else {
        message = message + "ha sido modificado por el Vendedor, "
          + "te invitamos a verificarlo de nuevo antes de proceder con la compra.";
      }

      // const alertAcepted = await this.cartAlertsService.showAlert(tittle, message);
      // if (alertAcepted && this.updatedProducts.length > 0) {
      // //   this.shopCarService.updateDateInCart(this.updatedProducts);
      // }
      // // return alertAcepted;
      return true;
    } else {
      return false;
    }
  }

  private checkProductType(product: any): boolean {
    const idproduct = product.idproduct;
    const productAux: any = this.productsArray.find(p => p.productId === idproduct);
    const retreatMismatch = productAux.type === 0 && product.get_in_workship === false;
    const deliveryMismatch = productAux.type === 1 && product.delivery === false;
    const dateMismatch = productAux.type === 2 && product.need_date === false;
    return retreatMismatch || deliveryMismatch || dateMismatch;
  }

  private getCartInfo(idproduct: number): { date: Date, type: number } {
    const productAux = this.productsArray.find(p => p.productId === idproduct);
    // @ts-ignore
    return { date: productAux.date, type: productAux.type };
  }

  private changeCartDate(idproduct: number, date: Date): void {
    const productAux = this.productsArray.find(p => p.productId === idproduct);
    // @ts-ignore
    productAux.date = date;
  }

  getProduct(id: number): Product {
    // @ts-ignore
    return this.products.find((p) => p.idproduct === id);
  }

  sumTotal(prodValue: any[]): void {
    let delivery = false;
    this.deliveryPackagesParcelCart.forEach((data) => {
      let pakages = data.package;
        pakages.forEach((pakage: any) => {
        if (pakage.idproduct === prodValue[1] && pakage.variant === prodValue[5]) {
          let delyveryPrice = 0;
          if(this.couponDelivery || this.couponDiscount){
            if(this.couponDiscount){
              delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
              this.sumTotalDiscount +=  (pakage.price * (this.couponDiscount.campaing.promotion_value / 100)) * pakage.dimensions.unidades
              const offer = this.couponDiscount.campaing.promotion_value / 100;
              this.total += (prodValue[0] - (prodValue[0]  * offer)) + delyveryPrice;
            }else{
              delyveryPrice = 0;
              this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
              this.total += prodValue[0] + delyveryPrice;
            }
          }else{
            this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
            delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
            this.total += prodValue[0] + delyveryPrice;
          }
          if(this.type == 2){
            this.total += (pakage.installPrice* pakage.dimensions.unidades)
          }
          // Para que solo sume el envío una vez
          this.sumTotalDeliveryPrice += delyveryPrice;
          this.sumTotalInstallPrice += (pakage.installPrice * pakage.dimensions.unidades);
          this.sumTotalSubTotal += pakage.price * pakage.dimensions.unidades;
          this.deliveryParcelPriceAdded = true;
          delivery = true;
        }
      });
    });
    this.deliveryPackagesCommodityCart.forEach((data) => {
      let pakages = data.package;
      pakages.forEach((pakage: any) => {
        if (pakage.idproduct === prodValue[1] && pakage.variant === prodValue[5]) {
          let delyveryPrice = 0;
          if(this.couponDelivery || this.couponDiscount){
            if(this.couponDiscount){
              delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
              this.sumTotalDiscount +=  (pakage.price * (this.couponDiscount.campaing.promotion_value / 100)) * pakage.dimensions.unidades
              const offer = this.couponDiscount.campaing.promotion_value / 100;
              this.total += (prodValue[0] - (prodValue[0]  * offer)) + delyveryPrice;
            }else{
              delyveryPrice = 0;
              this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
              this.total += prodValue[0] + delyveryPrice;
            }
          }else{
            this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
            delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
            this.total += prodValue[0] + delyveryPrice;
          }
          // Para que solo sume el envío una vez
          this.sumTotalDeliveryPrice += delyveryPrice;
          this.sumTotalSubTotal += pakage.price * pakage.dimensions.unidades;
          this.deliveryParcelPriceAdded = true;
          delivery = true;
        }
      });
    });
    
    /* this.deliveryPackagesLiquidCart.forEach((data) => {
      let pakages = data.package;
      pakages.forEach((pakage: any) => {
        if (pakage.idproduct === prodValue[1] && pakage.variant === prodValue[5]) {
          let delyveryPrice = 0;
          if(this.couponDelivery || this.couponDiscount){
            if(this.couponDiscount){
              delyveryPrice = this.deliveryParcelPriceAdded ? 0 : (this.type == 1 ? data.deliveryPrice : 0);
              this.sumTotalDiscount +=  (pakage.price * (this.couponDiscount.campaing.promotion_value / 100)) * pakage.dimensions.unidades
              this.total += (prodValue[0]  * (this.couponDiscount.campaing.promotion_value / 100)) + delyveryPrice;
            }else{
              delyveryPrice = 0;
              this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
              this.total += prodValue[0] + delyveryPrice;
            }
          }else{
            this.sumTotalDiscount += pakage.discount * pakage.dimensions.unidades;
            delyveryPrice = this.deliveryParcelPriceAdded ? 0 :(this.type == 1 ? data.deliveryPrice : 0);
            this.total += prodValue[0] + delyveryPrice;
          }
          // Para que solo sume el envío una vez
          this.sumTotalDeliveryPrice += delyveryPrice;
          this.sumTotalSubTotal += pakage.price * pakage.dimensions.unidades;
          this.deliveryParcelPriceAdded = true;
          delivery = true;
        }
      });
    }); */
    /* if (!delivery) {
      if (this.dateCart?.sells.length > 0) {
        this.products.forEach((product: any) => {
          if (product.idproduct === prodValue[1] ) {
            this.sumTotalSubTotal += prodValue[0];
            this.total += prodValue[0] + product.install_prize * (1 + product.install_tax / 100);
            this.workforce += product.install_prize * (1 + product.install_tax / 100);
          }
        })
      } else {
        this.sumTotalSubTotal += prodValue[4];
        this.sumTotalDiscount += prodValue[2] > 0 ? prodValue[2] : prodValue[3];
        this.total += prodValue[0];
      }
    } */
  }

  removeItemInCart(item: { product: number, type: number, price: number, is_liquid: boolean }, workshop: number, index: number, weight?: any): void {
    const weigth = weight;
    const mapKey = this.setAvailabilityMapKey(item.type, item.product, index);
    this.total = this.total - item.price;
    this.totalItems--;
    this.availability.delete(mapKey);
    this.workforce = 0;
    this.sumTotalSubTotal = this.sumTotalSubTotal - item.price;
    this.sumTotalDeliveryPrice = 0;
    this.sumTotalInstallPrice = 0;
    this.needGeneralReload = true;
    this.getAvailability();
    this.shopCartService.removeItemInCar(item.type, workshop, index, weigth, item.is_liquid);
    this.sumTotalSubTotal = 0;
    this.setPackageInformation(item.type);
  }

  private setAvailabilityMapKey(type: number, productId: number, index: number): string {
    return type === 2 ? `${type}${productId}/${index}` : `${type}${productId}`;
  }

  private setNewAvailabilityMap(): void {
    const keys = this.availability.keys();
    const values = this.availability.values();
    let auxKeyArray: string[] = [];
    // @ts-ignore
    let auxValuesArray: any[] = [];
    let loop = false
    while (!loop) {
      const nextKey = keys.next();
      const nextValue = values.next();
      if (!nextKey.done) {
        auxKeyArray.push(nextKey.value);
        auxValuesArray.push(nextValue.value);
      }
      // @ts-ignore
      loop = nextKey.done
    }
    this.availability.clear();
    auxKeyArray.forEach((key, index) => {
      const newKey = key.split("/")[0] + "/" + index;
      // @ts-ignore
      this.availability.set(newKey, auxValuesArray[index]);
    });
  }

  getAvailability(): void {
    this.enableCheckout = true;
    this.availability.forEach((avalaible) => {
      /* console.log(avalaible) */
      this.enableCheckout = this.enableCheckout && avalaible;
    });
  }

  updateTotal(update: { oldPrice: number, newPrice: number, sellType: number },
    line: { product: number, units: number, date?: Date }): void {
    this.updateUnitsInProductsArray(line);
    this.setPackageInformation(update.sellType);
    this.initializeVariables();
    this.getProducts();
 /* else {
      this.total = this.total - update.oldPrice;
      this.total += update.newPrice;
    } */
    this.shopCartService.saveCar();
  }

  private updateUnitsInProductsArray(line: { product: number, units: number, date?: Date }): void {
    const index = this.productsArray.findIndex(p => p.productId === line.product);
    const auxDate = this.productsArray[index].date;
    const auxType = this.productsArray[index].type;
    this.productsArray[index] = { productId: line.product, units: line.units, date: auxDate, type: auxType };
  }

  setAvailability(type: number, productId: number, available: boolean, index: number): void {
    const mapKey = this.setAvailabilityMapKey(type, productId, index);
    this.availability.set(mapKey, available);
    if (this.availability.size === this.totalItems) {
      this.getAvailability();
    }
  }

  async initSideBarCheckout(e: Event) { }

  async setInfomationCheckout() {
    this.checkoutService.retreatCart = this.retreatCart;
    this.checkoutService.deliveryParcelCart = this.deliveryParcelCart;
    this.checkoutService.deliveryCommodityCart = this.deliveryCommodityCart;
    this.checkoutService.deliveryLiquidCart = this.deliveryLiquidCart;
    this.checkoutService.deliveryPackagesLiquidCart = this.deliveryPackagesLiquidCart;
    this.checkoutService.dateCart = this.dateCart;
    this.checkoutService.products = this.products;
    this.checkoutService.retreatPackages = this.retreatPackages;
    this.checkoutService.deliveryPackagesParcelCart = this.deliveryPackagesParcelCart;
    this.checkoutService.deliveryPackagesCommodityCart = this.deliveryPackagesCommodityCart;
    this.checkoutService.userAddress = this.userAddress;
    this.checkoutService.userCityCodeDane = this.userCityCodeDane;
    this.checkoutService.observaciones = this.observaciones;
    this.checkoutService.locationId = this.locationId;
    this.checkoutService.couponDelivery = this.couponDelivery;
    this.checkoutService.couponDiscount = this.couponDiscount;
    this.checkoutService.type = this.type;
  }

  async goToCheckout() {
    if(this.islogged){
      if(this.type == 2){
        let garage = await this.garageService.getState()
        const actualAmountVehicles = garage.length
        if (actualAmountVehicles > 0){
          await this.setInfomationCheckout();
          this.openSchedule();
        }else{
          this.noVehiclesToDateModal = true;
        }
      }else{
        await this.setInfomationCheckout();
        this.displaySideBarCheckout = true;
      }
    }else{
      this.router.navigate(["/checkout"]);
    }
  }

  openSchedule(){
    let height = "";
    let width = "35%"
    if (window.innerWidth < 1400){
      height = '90vh'
      width = "50%"
    }
    const productsAux: any[] = [];
    let discounts= new Map<number, number>();
    const variants: any[] = []
    this.deliveryPackagesParcelCart.forEach((data) => {
      data.packageToShow.forEach((packaget) => {
        const product = this.products.filter((p) => p.name === packaget.name);
        productsAux.push(product[0]);
        variants.push(packaget.variant);
        discounts.set(product[0].idproduct as number, packaget.discount);
      })
    })
    this.deliveryPackagesCommodityCart.forEach((data) => {
      data.packageToShow.forEach((packaget) => {
        const product = this.products.filter((p) => p.name === packaget.name);
        productsAux.push(product[0]);
        variants.push(packaget.variant);
        discounts.set(product[0].idproduct as number, packaget.discount);
      })
    })
    const ref = this.dialogService.open(ScheduleComponent, {
      closable: true,
      dismissableMask: true,
      showHeader: false,
      width: this.isMobile ? '100vw' : width,
      height: this.isMobile ? '90vh' : height,
      contentStyle: {'overflow-x': 'hidden'},
      data: {products: productsAux, discounts, coupon : this.couponDiscount, variants}
    });
    ref.onClose.subscribe((data: any) => {
      if (data.data) {
        localStorage.removeItem("sells");
        this.shopCartService.clearCart();
        setTimeout(() => {
          this.router.navigate(["/history"]);
        }, 1000);
      }
    });
  }

  get isDisabled(){
    const a = (this.totalItems == 0 || !this.enableCheckout || !this.isCompatibleAddress && this.hasShippingProducts || this.loadingDeliveryInfo) && this.type == 1 ;
    const b = !this.enableCheckout && this.type == 2;
    return (a || b) && this.islogged;
  }

  goToHome() {
    this.router.navigate(['/home'])
  }

  emitCreateOrChangeAddress() {
    if (this.displaySideBarCart) {
      this.initSideBarCart();
    }
  }

  goToFollow(){
    if (this.islogged){
      this.router.navigate(["/history"]);
    }else{
      this.displayRedirect = true;
    }
  }

  goToFollowNA(){
    this.router.navigate(["/history"], {queryParams: {sells: this.saleNumber}});
  }

  apply(){
    lastValueFrom(this.couponService.checkCoupon(this.couponCode.toUpperCase())).then((data: any) => {
      if (data.campaing.promotion_type === 1){
        if(this.couponDelivery){
          this.messageService.add({severity: "error", summary: "Error", detail: 'Ya tiene un cupón de envio aplicado', life: 4000});
        }else{
          this.couponDelivery = data;
          this.sumTotalDeliveryPrice = 0;
          this.sumTotalInstallPrice = 0;
          this.setPackageInformation("allTypes");
          this.initializeVariables();
          this.getProducts();
          this.showCouponDialog = false;
        }
      }else{
        if(this.couponDiscount || this.sumTotalDiscount > 0){
          this.messageService.add({severity: "error", summary: "Error", detail: 'Hay productos en la compra con descuento solo se permiten cupones de Envíos gratis', life: 4000});
        }else{
          this.couponDiscount = data;
          this.sumTotalSubTotal = 0;
          this.sumTotalDiscount = 0;
          this.sumTotalDeliveryPrice = 0;
          this.sumTotalInstallPrice = 0;
          this.deliveryCommodityCart = this.shopCartService.deliveryCommodityCar.value;
          this.deliveryLiquidCart = this.shopCartService.deliveryLiquidCar.value
          this.deliveryParcelCart= this.shopCartService.deliveryParcelCar.value
          this.getProducts();
          this.showCouponDialog = false;
        }
      }
    }).catch((error) => {
      this.messageService.add({severity: "error", summary: "Error", detail: error.error.Error, life: 4000});
    })
  }

  changeType(type){
    this.sumTotalDeliveryPrice = 0;
    this.initializeVariables();
    this.getProducts();
    this.availability = new Map<string, boolean>();
    this.setNewAvailabilityMap();
    this.getAvailability();
  }

}
