import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { DeliveryService } from 'src/app/services/shop/delivery.service';

@Component({
  selector: 'app-contraentrega',
  templateUrl: './contraentrega.component.html',
  styleUrls: ['./contraentrega.component.scss']
})
export class ContraentregaComponent {

  address;
  extra;
  total;
  contraGroup;
  loading = true
  isMobile = false;
  name = ""
  last_name = ""
  number;
  phone;

  idTypes = [ {value: 'CC'}, {value: 'CE'}];
  idType;
  idNumber;

  loadingContra;

  seconds;


  countdownInterval: any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public delivery: DeliveryService,
              public generalConfigurationService: GeneralConfigurationService) {
    this.address = this.config.data.address
    this.extra = this.config.data.extra
    this.total = this.config.data.total
    let location = this.config.data.location
    this.isMobile = this.generalConfigurationService.isMobile;
    this.name = this.config.data.name;
    this.phone = this.config.data.phone;
    this.last_name = this.config.data.last_name;
    this.idType = this.idTypes[0];
    if (location){
      this.getLocationData(location);
    }else{
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    if(this.seconds > 0 && this.countdownInterval){
      clearInterval(this.countdownInterval);
    }
  }

  async getLocationData(location){
    let locationData = await lastValueFrom(this.delivery.getLocationGroup(location))
    this.contraGroup = locationData.group_contra;
    this.loading = false;
  }

  pay(){
    if(this.idNumber?.length > 5 && this.idType){
      this.seconds = 10;
      this.loadingContra = true;
      this.countdownInterval = setInterval(() => {
        this.seconds--;
        if (this.seconds < 0) {
          clearInterval(this.countdownInterval);
          this.payEmit()
        }
      }, 1000);
    }
  }

  payEmit() {
    let type = ";"
    if(this.idType.value === 'CC'){
      type = "Cédula de ciudadanía";
    }else{
      type = "Cédula de extranjería";
    }
    this.loadingContra = false;
    this.ref.close({idType: type, idNumber: this.idNumber}); 
  }

  interrupCountdown(){
    clearInterval(this.countdownInterval);
    this.loadingContra = false;
    this.ref.close(); 
  }
}
