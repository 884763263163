import { Component, Input, OnInit, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CarzService } from 'src/app/services/carz/carz.service';

@Component({
  selector: 'app-chat-carz',
  templateUrl: './chat-carz.component.html',
  styleUrls: ['./chat-carz.component.scss']
})
export class ChatCarzComponent {

  firstTimeDay: any = [];
  @Input() messages!: any;
  countMessages!: number;
  firstTimeLoading = true;
  newMessages: any[] = [];
  images: any[] = [];
  @Output() emitOpenGalleryChat = new EventEmitter<boolean>();

  constructor(private changeDetector: ChangeDetectorRef,
              private carzService: CarzService,
              private router: Router){
  }

  async ngOnInit(){
    await this.carzService.userSawMessages();
  }

  ngAfterViewChecked(){
    this.changeDetector.detectChanges();
  }


  ngOnChanges(changes: SimpleChanges) {
    if(this.firstTimeLoading){      
      this.newMessages.pop()
      if (this.messages.length !== 0){
        this.messages.forEach((msg:any, conta:number)=>{
          //Urls
          let textChanged = this.urlify(msg.text);
          const hasLink = textChanged.length > 1 ? true : false;
          this.newMessages.push({
            text:textChanged,
            img: msg.img ?? "",
            is_user: true,
            product: 0,
            dateAvailable: true,
            hasLink : hasLink 
          });

          // //Images
          // if(msg.img){
          //   this.images.push({
          //     "previewImageSrc": msg.img,
          //     "thumbnailImageSrc": msg.img,
          //     "alt": "img_user" + conta,
          //     "title": "img_user" + conta
          //   });
          // }

          //Dates
          const day = this.secondsToDay(msg.date);
          const result = this.firstTimeDay.find((dayPushed:any) => dayPushed.day == day);  
          if(result==undefined){
            this.firstTimeDay.push({day: day, firstDay:true});     
          }else{
            this.firstTimeDay.push({day: day, firstDay:false});
          }
        });
      }
      this.firstTimeLoading = false;
    }else{
      
      const countMsgs = changes['messages'].currentValue.length - 1;
      const lastMessage = changes['messages'].currentValue[countMsgs];
      const countMsgsP = changes['messages'].previousValue.length - 1;
      const previousValue = changes['messages'].previousValue[countMsgsP];
      //ngOnchanges executed twice. This conditional fixed the error.
      if(previousValue?.date?.seconds != lastMessage?.date?.seconds){
        //Urls
        const textChanged = this.urlify(lastMessage.text);
        const hasLink = textChanged.length > 1 ? true : false;
        this.newMessages.push({
          text: textChanged,
          img: '',
          is_user: true,
          product: 0,
          dateAvailable: true,
          hasLink : hasLink 
        });

        // //Images
        // if(lastMessage.img){
        //   this.images.push({
        //     "previewImageSrc": lastMessage.img,
        //     "thumbnailImageSrc": lastMessage.img,
        //     "alt": "img_user" + conta,
        //     "title": "img_user" + conta
        //   });
        // }
        
        //Dates
        const day = this.secondsToDay(lastMessage.date);
        const result = this.firstTimeDay.find((dayPushed:any) => dayPushed.day == day);    
        if(result==undefined){
          this.firstTimeDay.push({day: day, firstDay:true});          
        }else{
          this.firstTimeDay.push({day: day, firstDay:false});
        }
      }
      
    }    
  }

  secondsToDay(d:any) {
    const date = new Date(d * 1000);
    return date.toLocaleString("en-US", {day:'numeric' });
  }

  secondsToHms(d:any) {
    const date = new Date(d * 1000);
    return date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  }

  getLastTimeAsDate(lastTime:any){
    return new Date(lastTime.toDate());
  }


  urlify(text:string) {
    // var urlRegex = /(https?:\/\/[^\s]+)/g;
    // return text.replace(urlRegex, function(url) {
    //   return '<a href="' + url + '">' + url + '</a>';
    // })

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    const array = text.split(urlRegex)
    return array;
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  emitEventToSupport(img:any){
    this.emitOpenGalleryChat.emit(img);
  }
  
  async seeProduct(idProduct: number){
    this.router.navigate(['/product'], {queryParams: {id : idProduct}});
  }

}
