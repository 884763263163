import { Component, ElementRef, ViewChild, Directive } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService, PrimeNGConfig} from 'primeng/api';
import { Menu } from 'primeng/menu';
import { BehaviorSubject, debounceTime, distinctUntilChanged, first, switchMap } from 'rxjs';
import { ShopService } from 'src/app/services/shop/shop.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})

export class SearchBarComponent {

  searchForm!: FormGroup;
  public productsFound: Array<any> = [];
  inputSearch: string = '';
  canShowMenu = true;
  @ViewChild('menu') menuElement: ElementRef; 
  @ViewChild('menu') menu: Menu; 

  items: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  menus: MenuItem[] = [];

  constructor( 
    private primengConfig: PrimeNGConfig, 
    private shopService: ShopService,
    private formBuilder: FormBuilder,
    private router: Router) 
    { this.buildForm(); }


  private buildForm() {
    this.searchForm = this.formBuilder.group({
      search: [""],
    });
  }

  ngOnInit() {
    this.searchForm.get('search')?.valueChanges
    .pipe(
      debounceTime(200),
      distinctUntilChanged(),
    )
    .subscribe((v) => {
      this.inputSearch = v;      
      if(v !== "" && v !== " ") 
      {        
        this.shopService.searchBar(v).subscribe((found)=>{
          this.productsFound = found;
          var m: MenuItem[] = [];          
          var firstTimeProduct = true;
          var firstTimeCategory = true;
          found.forEach((c:any) => {

            if(!c?.is_category){
              if(firstTimeProduct){
                const item: MenuItem = {
                  items: [{
                    label: c?.name,
                    icon: 'pi pi-search',
                    command: () => {
                        this.goToProduct(c?.id);
                    }
                  }]
                };
                m.push(item);
              }else{
                const item: MenuItem = {
                  label: c?.name,
                  icon: 'pi pi-search',
                  command: () => {
                    this.goToProduct(c?.id);
                  }
                };
                m[0]?.items?.push(item)
              }

              firstTimeProduct = false;
            }else if(c?.is_category){

              var pos = 1
              //Esto corrige que cuando solo sean categorías, se muestren todas.
              if(firstTimeProduct) pos = 0
              
              if(firstTimeCategory){                
                const item: any = {
                  label: 'Categorías Relacionadas',
                  items: [{
                    label: c?.name,
                    icon: 'pi pi-sitemap',
                    command: () => {
                      this.goToCategory(c?.id);
                    }
                  }]
                }
                m.push(item);
              }else{                
                const item: any = {
                  label: c?.name,
                  icon: 'pi pi-sitemap',
                  command: () => {
                    this.goToCategory(c?.id);
                  }
                }
                m[pos]?.items?.push(item);
              }
              firstTimeCategory = false;
            }
          });
          this.items.next(m);
        });
      }else{
        this.menus = [];
        this.inputSearch = '';
      }
    })

    this.items.subscribe({
      next: (data: MenuItem[]) => this.menus = data
    });

    this.primengConfig.ripple = true;
  }

  goToProduct(id:number){
    this.shopService.search = this.inputSearch;
    this.router.navigate(['/shop'], {queryParams: {search : this.inputSearch}});
    this.menu.hide()
  }

  goToCategory(id:number){
    this.router.navigate(['/shop'], {queryParams: {idCategory : id}});
    this.menu.hide()
  }

  prueba(menu:any){
    setTimeout(()=>{
      menu.hide()
    }, 200)
  }
}
