<app-header></app-header>

<div id="sign-in-button-register"></div>
<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container">  
        <div class="gridRegister" style="margin: 0;">
            <div class="columRegister">
                <div class="title-form">
                    <p>Datos de Perfil</p>
                </div>
                <div class="p-inputgroup" style="flex-direction: column;">
                    <div class="col-12" >
                        <p class="title-input">Nombre</p>
                        <span class="p-float-label">
                            <input formControlName="firstNameForm" id="float-input" type="text" pInputText>
                        </span>
                    </div>
                    <div class="col-12">
                        <p class="title-input">Apellidos</p>
                        <span class="p-float-label">
                            <input formControlName="lastNameForm" id="float-input" type="text" pInputText>
                        </span>
                    </div>
                    <div class="col-12">
                        <p class="title-input">Correo Electrónico</p>
                        <span class="p-float-label">
                            <input *ngIf="email" class="input-disabled" formControlName="emailForm" id="float-input" type="text" pInputText readonly>
                            <input *ngIf="!email" formControlName="emailForm" id="float-input" type="text" pInputText>
                        </span>
                    </div>

                    <div class="col-12">
                        <p class="title-input">Fecha de Nacimiento</p>
                        <span class="p-float-label">
                            <p-calendar id="birhForm" formControlName="birthForm" dateFormat="yy-mm-dd" [touchUI]="isMobile" 
                            [focusTrap] = "isMobile" [readonlyInput]="isMobile"></p-calendar>
                        </span>
                    </div>

                    <div class="col-12">
                        <p class="title-input">Género</p>
                        <span class="p-float-label">
                            <p-dropdown placeholder="seleccione..." [options]="genders" formControlName="genderSend" optionLabel="text" 
                            optionValue="value"  class="dropdown-select">
                            </p-dropdown>
                        </span>
                    </div>

                    <div class="separated-gray-line"></div>

                    <div class="title-form sub">
                        <p>Celular</p>
                    </div>

                    <div style="width: 93%; margin:auto;">
                        <app-phone-validation [isRegister]=true [isProfile]="true" 
                        style="display: flex; justify-content: center; margin-bottom: 4%; flex-direction: column;"
                        [recaptchaVerifier]="reCaptchaVerifier" [phoneCel]="phoneCel"
                        ></app-phone-validation>
                    </div>



                    <div class="col-12 mt-2">
                        <p-button pRipple label="¡Comenzar!" class="button-comenzar" [disabled]="form.invalid || !otpVerified || !changedForm"
                            (onClick)="form.invalid || !otpVerified ? null : onSubmit()">
                            <ng-template pTemplate="content">
                                <span class="p-button-label font-bold" style="width: 1.5rem">¡ Guardar Cambios !</span>
                            </ng-template>
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>


<app-support-carz></app-support-carz>