<div class ="container">
    <div class = "grid grid-nogutter">
        <div class = "col ml-5 mt-3" >
            <h2 style = "font-weight: 500;font-size: 20px;color:black;margin-top: 2%;"> Recomendados</h2>
        </div>
    </div>
    <div class = "grid grid-nogutter mt-5" >
        <div class = "col-10 col-offset-1" *ngIf = "!loading;else skeleton">
            <p-carousel [value]="products" [numVisible]="1" [showIndicators] = "true"  [showNavigators] ="false">
                <ng-template let-product pTemplate="item">
                    <div class = "grid grid-nogutter">
                        <div class = "col-6 md:col-2 lg:col-2 xl:col-2" *ngFor = "let item of product">
                            <app-product [product] = item ></app-product>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>     
        </div>
        <ng-template #skeleton>
            <div  class = "col-10 col-offset-1">
                <p-carousel [value]="loadingArray" [numVisible]="1" [numVisible]="1" [showIndicators] = "true" [showNavigators] ="false" >
                    <ng-template let-product pTemplate="item">
                        <div class = "grid">
                            <div class = "col-2" *ngFor = "let item of product">
                                <div class ="grid">
                                    <div class ="col-12">
                                        <p-skeleton  width="155px" height="155px"></p-skeleton>
                                    </div>
                                    <div class = "col-12">
                                        <p-skeleton borderRadius="16px" width= "115px"></p-skeleton>
                                    </div>
                                    <div class = "col-12">
                                        <p-skeleton borderRadius="16px" width= "75px"></p-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </ng-template>
    </div>

</div>
