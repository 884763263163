import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateModel } from 'src/app/interfaces/shop';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  baseurl = environment.server_url;

  httpHeaders = new HttpHeaders({ "Content-type": "application/json"});

  token = localStorage.getItem("token");

  constructor(private http: HttpClient) { }


  private setToken(){
    this.token = localStorage.getItem("token");
    if (this.token){
      this.httpHeaders = new HttpHeaders({"Content-type": "application/json", Authorization: "Token " + this.token});
    }
  }

  postSchedule(dateSend: Date, productIdSend: number ): Observable<any>{
    this.setToken()
    return this.http.post(this.baseurl + "date/get_free_hours/", { date: dateSend, product: productIdSend } ,
    { headers: this.httpHeaders })
  }


  createDate(date: Date, idProduct: number, variant: number, coupon ): Observable<DateModel> {
    this.setToken()
    const body  = { start_hour: date, product_idproduct: idProduct, variant: variant, coupon};
    return this.http.post<DateModel>(this.baseurl + "date/dates/", body,
    { headers: this.httpHeaders });
  }

  deleteDate( id: any ): Observable<any> {
    return this.http.delete<any>(this.baseurl + "payment/follow/" + id + "/", { headers: this.httpHeaders } );
  }

  confirmAttendance(id: number): Observable<any> {
    this.setToken()
    const body = {
      confirmed: true
    };
    return this.http.patch<any>(this.baseurl + "date/dates/"+ id + "/", body , { headers: this.httpHeaders });
  }

  cancelAppointment(id: number):  Observable<any> {
    this.setToken()
    let date = new Date();
    date.setHours(date.getHours() - 5);
    const actualDate = date.toISOString();
    const body = {
      final_hour: actualDate,
      cancell_reason: ''
    };
    return this.http.patch<any>(this.baseurl + "date/cancel_date/"+ id + "/", body , { headers: this.httpHeaders });
  }

}
