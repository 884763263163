import { Component, ElementRef, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { CouponsService } from 'src/app/services/coupons/coupons.service';
import { DatesService } from 'src/app/services/dates/dates.service';
import { GarageService } from 'src/app/services/garage/garage.service';
import { GoogleMapsService } from 'src/app/services/googlemaps/google-maps.service';
import { GeneralConfigurationService } from 'src/app/services/shared/configuration/general-configuration.service';
import { DeliveryService } from 'src/app/services/shop/delivery.service';
import { ShopCartService } from 'src/app/services/shop/shop-cart.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent {

  appointmentDate: Date;
  googleMapAPILoaded;

  map: google.maps.Map;
  marker: google.maps.Marker;
  googleGeocoderService: google.maps.Geocoder;
  googleAutocomplete: google.maps.places.AutocompleteService;
  googlePlaceService: google.maps.places.PlacesService;

  lat = 6.178907856448837;
  lng = -75.59343336410397;
  initLat = 6.178907856448837;
  initLng = -75.59343336410397;

  datesConfirmated: any[] = [];
  disponibilityStatus: (0 | 1 | -1)[];
  dateStatus: (0 | 1 | -1)[];

  mapLoaded = false;

  products: any = [];

  discounts: Map<number, number>;

  disabledDates: Date[] = [];

  dates: any[] = [];

  
  scheduling = false;

  productsIds = "";

  productsArray: any[] = [];

  actualizedProducts: any[] = [];

  updatedProducts: any[] = [];

  confirmationStatus;

  globalStatus;

  disableCancelButton = false;

  cancel = false;

  variants;

  coupon;

  @ViewChild('map2', { static: false }) mapElement2!: ElementRef;

  couponDiscount;

  showCouponDialog = false;

  couponCode;

  isMobile = false;

  constructor(private googlemaps: GoogleMapsService, public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig, private dateService: DatesService,
    public garageService: GarageService, public messageService: MessageService,
    private generalConfigurationService: GeneralConfigurationService,
    private couponService: CouponsService,
    public shopCartService: ShopCartService, private toast: ToastService, private delivery_service: DeliveryService) {

    this.products = this.config.data.products;
    this.discounts = this.config.data.discounts;
    this.variants = this.config.data.variants;
    this.coupon = this.config.data.coupon;
    
    this.isMobile = this.generalConfigurationService.isMobile
    this.products.forEach(element => {
      this.dates.push(null)
    });
  }

  ngOnInit(): void{
    this.appointmentDate = new Date();
    this.googlemaps.loadGoogleMapAPI().then(data => {
      if (data) {
        this.googleMapAPILoaded = data;
        setTimeout(() => this.loadMap(), 200);
      }
    });
    this.setInitialInfo();
  }

  private setInitialInfo(): void {
    this.disponibilityStatus = new Array(this.dates.length).fill(0);
    this.dateStatus = new Array(this.dates.length).fill(0);
    this.confirmationStatus = new Array(this.dates.length).fill(0);
    this.globalStatus = 0;
    this.datesConfirmated = [];
  }

  private async loadMap(): Promise<void> {
    try {
      this.mapLoaded = false;
      const latLng = await new google.maps.LatLng(this.initLat, this.initLng);
      const mapOptions = {
        center: latLng,
        zoom: 15,
        clickableIcons: false,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        disableDefaultUI: true,
        mapId: "631f1890778aa7fc",
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      this.map = await new google.maps.Map(this.mapElement2.nativeElement, mapOptions);
      const icon = {
        url: "assets/img/shared/UbicationPointer.png",
        scaledSize: new google.maps.Size(30, 30),
        Origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 30),
    };
    this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        animation: google.maps.Animation.DROP,
        draggable: false,
        icon,
    });
    this.marker.setMap(this.map);
    this.map.panTo(latLng);
      google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
        this.mapLoaded = true;
        this.googleMapAPILoaded = this.googlemaps.mapsLoaded;
        /* setTimeout(() => { this.addMarker() }, 300); */
    });
    } catch (error) {
      console.error(error);
    }
  }

  calSubTotal(){
    let subtot = 0
    this.products.forEach(element => {
      let tax = element.tax ? element.tax : 0 
      subtot += element.prize* (1 + tax / 100); 
    });
    return subtot;
  }

  calcInstallTotal(){
    let subtot = 0
    this.products.forEach(element => {
      let tax = element.install_tax ? element.install_tax : 0 
      subtot += element.install_prize* (1 + tax / 100); 
    });
    return subtot;
  }

  get totaldiscount(){
    let disc = 0
    this.products.forEach(element => {
      if(this.discounts.has(element.idproduct)){
        let aux = this.discounts.get(element.idproduct);
        disc += aux as number;
      }
    });
    return disc;
  }

  calTotal(){
    return this.calSubTotal() + this.calcInstallTotal() - this.totaldiscount;
  }

  addDate(data){
    this.dates[data.index] = data;
  }

  get canContinue(){
    let cont = 0;
    while (cont < this.dates.length){
      if (this.dates[cont] == null){
        return false;
      }
      cont+= 1;
    }
    return true;
  }

  async schedule(){
    if(this.canContinue){
      this.scheduling = true;
      const info = await lastValueFrom(this.garageService.getStateV2());
      const principalCar = await this.getPrincipalCar(info);
      const hasPlate = principalCar.carspecific_placa.placa_2;
      if(hasPlate && hasPlate != "") {
        const someHaveChanged = await this.checkProducts();
        if (someHaveChanged){
          this.scheduling = false;
          this.ref.close({data: false});
        }else{
          if(this.dates.length > 1){
            this.checkAndConfirmScheduleDates();
          }else{
            this.validateAndCreateDates();
          }
        }
      }else{
        this.toast.toastError.next('El vehiculo necesita de una placa para poder agendar citas');
      }
    }
  }

  checkAndConfirmScheduleDates(){
    const productsScheduleAux = this.dates.map((p) => {return {...p}});
    this.organiseDates(productsScheduleAux);
    const N = productsScheduleAux.length;
    for (let i = 0; i < (N - 1); i++) {
      const dateOne = new Date(productsScheduleAux[i].day);
      const minutes: number = productsScheduleAux[i].product.installTime - 1;
      dateOne.setMinutes(dateOne.getMinutes() + minutes);
      const dateTwo = new Date(productsScheduleAux[i + 1].day);
      const equalDate = productsScheduleAux[i].day === productsScheduleAux[i + 1].day;
      const crossDate = dateTwo <= dateOne;
      if (equalDate || crossDate) {
        this.scheduling = false;
        this.toast.toastError.next('Los horarios de las citas no pueden cruzarse entre sí');
        break;
      }
      if (i === (N - 2)) {
        this.validateAndCreateDates();
      }
    }
  }

  private organiseDates(productsSchedule) {
    const produtsSchedule = productsSchedule;
    const N = produtsSchedule.length;
    const datesSchedule = new Array(N).fill(0);
    produtsSchedule.forEach((element, i) => {
      const date = element.day;
      const date2 = new Date(date).getTime();
      datesSchedule[i] = date2;
    });
    for (let i = 0; i < N; i++) {
      for (let j = 0; j < (N - i - 1); j++) {
        if (datesSchedule[j] > datesSchedule[j + 1]) {
          const aux = datesSchedule[j];
          datesSchedule[j] = datesSchedule[j + 1];
          datesSchedule[j + 1] = aux;
          const auxSc = produtsSchedule[j];
          produtsSchedule[j] = produtsSchedule[j + 1];
          produtsSchedule[j + 1] = auxSc;
        }
      }
    }
  }

  async validateAndCreateDates(){
    for(let i = 0; i < this.dates.length; i++){
      const element = this.dates[i];
      const productId = element.product.idproduct;
      const date = element.day
      const variant = element.variant;
      this.disponibilityStatus[i] = 1;
      let dateConfirmed: any = null;
      if(!this.cancel){
        try {
          dateConfirmed = await lastValueFrom(this.dateService.createDate(date,productId, variant,this.coupon?.id ));
          let sell = dateConfirmed.sell
          lastValueFrom(this.delivery_service.createBill(sell)).catch((error) => {
            console.log(error);
          })
          this.dateStatus[i] = 1;
          this.datesConfirmated.push(dateConfirmed);
        } catch (error) {
          this.disableCancelButton = true;
          this.disponibilityStatus[i] = -1;
          this.dateStatus[i] = -1;
          this.confirmationStatus.fill(-1);
          await this.deleteDatesConfirmated(3500);
          break
        }
      }else{
        this.disableCancelButton = true;
        this.disponibilityStatus[i] = -1;
        this.dateStatus[i] = -1;
        this.confirmationStatus.fill(-1);
        await this.deleteDatesConfirmated(3500);
        break
      }
    }
    const noDisponibility = this.disponibilityStatus.some( value => value === -1 );
    const noDateStatus = this.dateStatus.some( value => value === -1 );
    const hasDisponibility = this.disponibilityStatus.some( value => value === 1 );
    const hasDateStatus = this.dateStatus.some( value => value === 1 );
    setTimeout(() => {
      if (hasDisponibility && hasDateStatus && !this.cancel){
        this.shopCartService.clearCart();
        this.toast.toastSuccess.next("La cita ha sido agendada con éxito!")
        this.closeModal(1, true)
      }
    }, 5000);
    
  }

  cancelConfirmated(): void {
    this.disableCancelButton = true;
    this.confirmationStatus.fill(-1);
    this.cancel = true;
    this.deleteDatesConfirmated(1000);
  }

  private async deleteDatesConfirmated(time: number): Promise<void> {
    this.disponibilityStatus.fill(-1);
    this.confirmationStatus.fill(-1);
    this.dateStatus.fill(-1);
    this.globalStatus = -1;
    if (this.datesConfirmated.length > 0) {
      for (let i = 0; i < this.datesConfirmated.length; i++) {
        try {
          const element = this.datesConfirmated[i];
          await lastValueFrom(this.dateService.deleteDate(element.sellLine));
        } catch (error) {
          console.error(error);
        }
      }
    }
    this.toast.toastError.next("No se pudieron agendar las citas, por favor intentar nuevamente")
    this.closeModal(time, false);
  }

  closeModal(time: number, success: boolean): void {
    setTimeout(() => {
      this.ref.close({data:success});
    }, time);
  }

  getPrincipalCar(info) {
    const data = info.find(i => i.is_principal == true);
    return data;
  }

  private setProductsIds() {
    this.productsIds = "";
    this.products.forEach(p => {this.productsIds = this.productsIds + p.idproduct + ","});
  }

  private async checkProducts(): Promise<boolean> {
    this.setProductsIds();
    this.setProducsArray();
    this.actualizedProducts = await this.shopCartService.loadProducts(this.productsIds.substring(0, this.productsIds.length - 1));
    const productsChanged = await this.checkChanged();
    return  productsChanged;
  }

  checkChangedAux(product, updateProduct): boolean{
    if(!updateProduct.need_date){
      return true;
    }
    if(!updateProduct.is_available){
      return true;
    }
    const installTime = product.installTime === updateProduct.installTime;
    const installPrice = product.install_prize === updateProduct.install_prize;
    const installTax = product.install_tax === updateProduct.install_tax;
    const name = product.name === updateProduct.name;
    const price = product.prize === updateProduct.prize;
    const tax = product.tax === updateProduct.tax;
    return !(installTime && installPrice && installTax && name && price && tax);
  }

  private async checkChanged(): Promise<boolean> {
    let tittle = "";
    let message = "*";
    let productsChanged: any[] = []
    productsChanged = this.actualizedProducts.filter(p => {
      const actualProduct = this.products.filter(pr => {
        return pr.idproduct == p.idproduct
      })[0]
      const updated = this.checkChangedAux(actualProduct,p)
      if (updated){
        this.updatedProducts.push({productId: p.idproduct, type: 2})
      }
      return updated
    });
    if (productsChanged.length !== 0) {
      tittle = "Cambios en Producto y/o Servicio";
      productsChanged.forEach((product, i) => {
        const final = i + 1 === productsChanged.length ? " " : ", ";
        message = message + product.name + final;
      });
      if (productsChanged.length > 1) {
        message = message + "han sido modificados por el Vendedor, "
        + "te invitamos a verificarlos de nuevo antes de proceder con la compra.";
      } else {
        message = message + "ha sido modificado por el Vendedor, "
        + "te invitamos a verificarlo de nuevo antes de proceder con la compra.";
      }
      this.toast.toastError.next(message);
      return true
    } else {
      return false;
    } 
  }

  private setProducsArray() {
    this.dates.forEach((date) => {
      const data = {
        productId: date.product.productId,
        units: 1,
        date: date.day,
        type: 2
      }
      this.productsArray.push(date);
    })
  }

  apply(){
    if(this.couponCode && this.couponCode !== ""){
      lastValueFrom(this.couponService.checkCoupon(this.couponCode.toUpperCase())).then((data: any) => {
        /* this.coupon = data; */
        if (data.campaing.promotion_type === 1){
            this.messageService.add({severity: "error", summary: "Error", detail: 'No se puede aplicar cupones de envios para citas', life: 4000});
        }else{
          if(this.couponDiscount || this.totaldiscount > 0){
            this.messageService.add({severity: "error", summary: "Error", detail: 'Hay productos en la compra con descuento solo se permiten cupones de Envíos gratis', life: 4000});
          }else{
            this.couponDiscount = data;
            this.products.forEach(element => {
              if(data.campaing.promotion_type == 2){

              }else{

              }
              this.discounts.set(element.idproduct,data.campaing.promotion_value)
            });
            // @ts-ignore
            this.showCouponDialog = false;
          }
        }
      }).catch((error) => {
        this.messageService.add({severity: "error", summary: "Error", detail: error.error.Error, life: 4000});
      })
    }
  }


}
